/* eslint-disable no-empty-pattern */
import { useState, useEffect } from "react";
import { Alert, Button, Card, CustomTable, Input } from "../../Library/Module";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import { CMSByType, CategoryBySubCategory, GetCMS, CMSByAuthor, CMSByCategory, CMSByCategoryName, GetCategory } from "../../servies/services";
import NoDataFound from "../../Library/NoData/NoData";
import Loader from "../../Library/Loader/Loader";
import { plural, replaceWithDash } from "../../Library/Utility/Utility";


const tableConfig = {
    search: {
        name: "id",
        isAsc: false,
    },
    sortDefault: {
        name: "id",
        isAsc: false,
    },
    tableHeader: [
        {
            name: "_id",
            display: "Id",
            sort: true,
            sortType: "number",
        },
        {
            name: "title",
            display: "Title",
            sort: true,
        },
        {
            name: "type",
            display: "Type",
            sort: true,
        },

        {
            name: "category",
            display: "Category",
            sort: true,
        },


        {
            name: "status",
            display: "Status",
            sort: true,
        },

    ],
};

const ViewPosts = () => {
    const navigate = useNavigate();
    let { CMStype, CMSCategory, AllPages, byType, category, title } = useParams();
    const [isError, setIsError]: any = useState(false);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [isValid, setIsValid]: any = useState(true);
    const [tableData, setTableData] = useState<any>([]);
    const [tableCatData, setTableCatData] = useState<any>([]);

    const [showCells, setShowCells] = useState(null);
    const [sortState, setSortState] = useState(tableConfig.sortDefault);
    const [isLoading, setIsLoading] = useState(false);
    const [isCatLoading, setIsCatLoading] = useState(false);

    const [reloadAPI, setReloadAPI] = useState(false);
    console.log("CMSCategory", CMSCategory)

    const showCellCallback = (data: any) => {
        setShowCells(data);
    };

    useEffect(() => {
        if (CMStype && CMStype.length > 0) {
            setIsLoading(false);
            setTableData([]);
            const getData = async () => {
                const apidata = await CMSByType(CMStype);
                const APIResponse = apidata?.data?.data;
                setTableData(APIResponse);
                setIsLoading(true);
                setReloadAPI(false);
            };
            getData();
        }
    }, [CMStype, reloadAPI]);

    useEffect(() => {
        if (CMSCategory && CMSCategory.length > 0) {
            setIsLoading(false);
            setTableData([]);
            const getData = async () => {
                const apidata = await CategoryBySubCategory(CMSCategory);
                const APIResponse = apidata?.data?.data;
                setTableData(APIResponse);
                setIsLoading(true);
                setReloadAPI(false);
            };
            getData();
        }
    }, [CMSCategory, reloadAPI]);


    useEffect(() => {
        if (byType === 'category') {
            const getData = async () => {
                setIsLoading(false);
                setTableData([]);
                const apidata = await CMSByCategoryName(category);
                const APIResponse = apidata?.data?.data;
                console.log("APIResponse", APIResponse)
                setTableData(APIResponse);
                setIsLoading(true);
                setReloadAPI(false);
            };
            getData();
        } else if (byType === 'author') {
            const getData = async () => {
                setIsLoading(false);
                setTableData([]);
                const apidata = await CMSByAuthor(category);
                const APIResponse = apidata?.data?.data;
                console.log("APIResponse", APIResponse)
                setTableData(APIResponse);
                setIsLoading(true);
                setReloadAPI(false);
            };
            getData();
        } else {
            const getData = async () => {
                setIsLoading(false);
                setTableData([]);
                const apidata = await GetCMS();
                const APIResponse = apidata?.data?.data;
                console.log("APIResponse", APIResponse)
                setTableData(APIResponse);
                setIsLoading(true);
                setReloadAPI(false);
            };
            getData();
        }


    }, [reloadAPI, byType, category]);


    // 

    useEffect(() => {
        setIsCatLoading(false);
        setTableData([]);
        const getData = async () => {
            const apidata = await GetCategory();
            const APIResponse = apidata?.data?.data;
            setTableCatData(APIResponse);
            setIsCatLoading(true);
            setReloadAPI(false);
        };
        getData();

    }, []);

    // GetCMS 

    const breadcrumbList = [
        {
            title: "Dashboard",
            link: "/dashboard",
        },
        {
            title: "Pets Management",
            link: "/dashboard",
        },
        {
            title: "View Pets",
            link: "/dashboard",
        },
    ];
    const reloadData = (data: any) => {
        console.log("reloadData", data);
        setReloadAPI(true);
    };

    return (
        <Wrapper pageTitle="CMS Management" breadcrumbList={breadcrumbList}>
            <Card>
                <div className="">
                    {!isValid && (
                        <Alert
                            AlertStyleType="danger"
                            AlertText={`Please enter required fields`}
                        ></Alert>
                    )}
                    <div className="row postManagement">
                        <div className="col-md-9">
                            <h3>Posts {byType == null ? '' : byType && <span className="changeCase"> {`by ${title ? decodeURI(title) : replaceWithDash(category)} `}</span>}</h3>

                            {!isLoading && <Loader loaderType="FullLoader"></Loader>}
                            {isLoading && tableData.length === 0 && <NoDataFound></NoDataFound>}
                            {tableData?.map((item: any) => {
                                return (
                                    <div className="listPost">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h4><NavLink to={`/posts-management/view-all-posts/${item?._id}`}>{item?.title}</NavLink></h4>
                                                <p className="meta">Date {item?.publish_date}  || Category - <NavLink className={'changeCase'} to={`/posts-management/by/category/${item?.category}`}>{replaceWithDash(item?.category)}</NavLink>  || Author - <NavLink to={`/posts-management/by/author/${item?.created_by}/${item?.created_by_name}`}>{item?.created_by_name}</NavLink>

                                                    <div className="btn-primary-div">
                                                        <span className="material-symbols-outlined">
                                                            thumb_up
                                                        </span>
                                                        {item?.likes ?? 0}
                                                    </div>
                                                    ||
                                                    <div className="btn-primary-div">
                                                        <span className="material-symbols-outlined">
                                                            comment
                                                        </span>
                                                        {item?.comments ?? 0}
                                                    </div>
                                                </p>
                                                <div dangerouslySetInnerHTML={{ __html: item?.short_description }}></div>
                                            </div>
                                            <div className="col-md-12">
                                                <Button onClick={() => navigate(`/posts-management/view-all-posts/${item?._id}`)} buttonStyleOutline buttonStyleType={"primary"}>Read More</Button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                        <div className="col-md-3">
                            <div className="postsSearch">
                                <Input placeholder="Search blog" inputName="search"></Input>
                                <div className="widget">
                                    <h4>Category</h4>
                                    <ul>
                                        {isCatLoading && tableCatData?.map((item: any) => {
                                            return (
                                                <li> <NavLink to={`/posts-management/by/category/${item?.slug}`}>{item?.title}</NavLink></li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </Wrapper>
    );
};
export default ViewPosts;

// /* eslint-disable require-jsdoc */
// import React, { Component } from "react";
// // import { withSnackbar } from 'notistack';


// import { confirmAlert } from 'react-confirm-alert'; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

// import 'react-responsive-modal/styles.css';
// import { Modal } from 'react-responsive-modal';
// import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/blur.css';
// import { GetMedia, DeleteMedia, MediaById } from "../../servies/services";
// import { Button } from "../../Library/Module";

// class ViewCMSCategory extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             files: [],
//             fields: {},
//             description: null,
//             cms_type: [],
//             cms_category: [],
//             action_type: null,
//             loading: false,
//             cms_type_inline: null,
//             token: localStorage.getItem('admin_token'),
//             organization_id: localStorage.getItem('organization_id'),
//             admin_name: localStorage.getItem('admin_name'),
//             tableData: [],
//             open: false,
//             details: null
//         };
//         this.delete = this.delete.bind(this);
//     }

//     async componentDidMount() {

//         const type = this.props.match.params.title
//         this.setState({ cms_type_inline: type })
//         this.getAllCMS();

//     }


//     componentWillReceiveProps(nextProps) {
//         console.log("nextProps", nextProps.match.params.title)
//         const type = nextProps.match.params.title;
//         this.setState({ cms_type_inline: type })
//         this.getAllCMS()
//     }

//     delete(id) {
//         console.log("id", id)

//         confirmAlert({
//             customUI: ({ onClose }) => {
//                 return (
//                     <div className='custom-ui'>
//                         <h1>Are you sure?</h1>
//                         <p>You want to delete this file?</p>
//                         <Button variant="outlined" color="primary" onClick={onClose}>No</Button>
//                         <Button variant="contained" color="secondary" onClick={() => {
//                             this.handleClickDelete(id);
//                             onClose();
//                         }}
//                         >
//                             Yes, Delete it!
//                         </Button>
//                     </div>
//                 );
//             }
//         });
//     }


//     onOpenModal = async (id) => {

//         this.GetMediaDetailsById(id)
//     }

//     onCloseModal = async (id) => {
//         this.setState({ open: false })
//     }



//     GetMediaDetailsById = async (id) => {
//         const APIResponse = await MediaById(id);
//         console.log("MediaById", APIResponse);

//         if (APIResponse !== null) {
//             this.setState({ open: true })
//             this.setState({ details: APIResponse.data.data, loading: true })
//         }

//     }

//     handleClickDelete = async (id) => {
//         const APIResponse = await DeleteMedia(id);
//         console.log("DeleteMedia", APIResponse);
//         if (APIResponse !== null) {
//             this.getAllCMS();
//         }

//     }


//     getAllCMS = async () => {
//         this.setState({ loading: false })
//         const APIResponse = await GetMedia();
//         console.log("GetCustomType", APIResponse);
//         if (APIResponse !== null) {
//             const filteredDates = APIResponse.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
//             console.log("filteredDates", filteredDates)
//             this.setState({ tableData: filteredDates, loading: true })
//         }
//     }

//     render() {
//         const pagetitle = "Media"
//         return (
//             <React.Fragment>

//                 <div className="container-fluid text-left">
//                     <div className="row">
//                         <div className="col-md-12">
//                             <div className="customForm customTable">

//                                 <Modal open={this.state.open} onClose={this.onCloseModal} center>
//                                     {this.state.details !== null ?
//                                         <React.Fragment>
//                                             <h5> {this.state.details.title} </h5>
//                                             {/* {JSON.stringify(this.state.details)} */}
//                                             <div className="popup_gallery">
//                                                 <div className="row">
//                                                     <div className="col-sm-7">
//                                                         <LazyLoadImage
//                                                             alt={this.state.details.title}
//                                                             effect="blur"
//                                                             src={"https://tlhintranet.com/nodeapi/" + this.state.details.path}
//                                                             title={this.state.details.title} />
//                                                     </div>
//                                                     <div className="col-sm-5">
//                                                         <p> <label>Created at</label> {this.state.details.created_on} </p>
//                                                         <p> <label>Created by</label> {this.state.details.created_by} </p>
//                                                         {"https://tlhintranet.com/nodeapi/" + this.state.details.path}
//                                                         {/* <TextField name="path" disabled={this.state.action === 'view' ? true : ''} size="small" value={"https://tlhintranet.com/nodeapi/" + this.state.details.path} onChange={this.handleChange} id="outlined-basic" label="path" variant="outlined" /> */}
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </React.Fragment>
//                                         : ''
//                                     }
//                                 </Modal>

//                                 <div className="row" id="image_gallery">
//                                     {this.state.loading === false ? 'Loading' :
//                                         <React.Fragment>
//                                             {this.state.tableData.map((item) => {
//                                                 return (
//                                                     <React.Fragment>
//                                                         <div className="col-md-2 mb-3 text-center">
//                                                             <div className="image_gallery_item" onClick={() => this.onOpenModal(item._id)}>
//                                                                 <LazyLoadImage
//                                                                     alt={item.title}
//                                                                     effect="blur"
//                                                                     src={"https://tlhintranet.com/nodeapi/" + item.path}
//                                                                     title={item.title} />
//                                                             </div>
//                                                         </div>
//                                                     </React.Fragment>
//                                                 );
//                                             })
//                                             }
//                                         </React.Fragment>
//                                     }
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//             </React.Fragment>
//         );
//     }
// }

// export default ViewCMSCategory;


/* eslint-disable no-empty-pattern */
import { useState, useEffect } from "react";
import axios from "axios";
import {
  Alert,
  Card,
  Input,
  Button,
  Select,
  CustomTable,
  SideDrawer,
} from "../../Library/Module";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import { AddCityService, DeleteMedia, GetMedia } from "../../servies/services";
import MediaRow from './MediaRow'
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { objectToQueryString } from "../../Library/Utility/Utility";

type formDataProps = {
  title: string;
  status: string;
  image?: string;
};

const tableConfig = {
  search: {
    name: "id",
    isAsc: false,
  },
  sortDefault: {
    name: "created_on",
    isAsc: true,
  },
  tableHeader: [

    {
      name: "title",
      display: "Title",
      sort: true,
    },
    {
      name: "created_on",
      display: "Uploaded Date",
      sort: true,
    },
    {
      name: "created_on",
      display: "Action",
      sort: false,
    },
  ],
};

type actionTypeState = {
  id?: any;
  type?: any;
  data?: any;
  mainType?: any
};

const AddCity = () => {
  const navigate = useNavigate();
  const [isError, setIsError]: any = useState(false);
  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [isValid, setIsValid]: any = useState(true);
  const [tableData, setTableData] = useState<any>([]);
  const [showCells, setShowCells] = useState(null);
  const [sortState, setSortState] = useState(tableConfig.sortDefault);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadAPI, setReloadAPI] = useState(false);
  let { myMedia } = useParams();
  const [actionType, setActionType] = useState<actionTypeState>({});

  // @ts-ignore
  const profile_details = JSON.parse(localStorage.getItem("profile_details")) || null;

  const showCellCallback = (data: any) => {
    setShowCells(data);
  };

  useEffect(() => {
    console.log("myMedia", myMedia)
    setIsLoading(false);
    setTableData([]);
    const getData = async () => {
      const query = {
        ...(myMedia === 'my-media' ? { email_id: profile_details?.email_id, } : {}),
        type: "firm_document"
      }
      let apidata = await GetMedia(objectToQueryString(query));
      const APIResponse = apidata?.data?.data;
      setTableData(APIResponse);
      setIsLoading(true);
      setReloadAPI(false);
    };
    getData();

  }, [myMedia, profile_details?.email_id, reloadAPI]);

  const breadcrumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "City Management",
      link: "/dashboard",
    },
    {
      title: "View City",
      link: "/dashboard",
    },
  ];
  const reloadData = (data: any) => {
    console.log("reloadData", data);
    setReloadAPI(true);
  };

  const DeleteCity = (rowData: any) => {
    confirmAlert({
      title: `Are you sure you want to delete this ${rowData}?`,
      message: `This Item will be deleted immediately. You can't undo this action.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteCityAPI(rowData),
        },
        {
          label: "No",
          onClick: () => "",
        },
      ],
    });
  };

  const DeleteCityAPI = async (rowData: any) => {
    const APIResponse = await DeleteMedia(rowData._id, rowData.path);
    if (APIResponse?.data?.isSuccess === true) {
      toast.success(APIResponse?.data?.messages);
      reloadData(true);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const callbackFresh = (data: any) => {
    console.log('data', data)
    setActionType(data)
  }

  const CloseDrawer = () => {
    setActionType({});
  };

  return (
    <Wrapper pageTitle="Dashboard" breadcrumbList={breadcrumbList}>
      <Card>
        <div className="">
          {!isValid && (
            <Alert
              AlertStyleType="danger"
              AlertText={`Please enter required fields`}
            ></Alert>
          )}
          <div className="row">
            <CustomTable
              header={tableConfig.tableHeader}
              sortDefault={tableConfig.sortDefault}
              data={tableData}
              tableTitle={"Firm Documents"}
              tableSubTitle='You can manage the Firm Documents in this module.'
              dropdown={""}
              search_bar={false}
              showCellCallback={showCellCallback}
              sortState={sortState}
              isLoading={isLoading}
            >
              {({ rowData, index }: { rowData: any; index: any }) => (
                <MediaRow
                  rowData={rowData}
                  index={index}
                  showCells={showCells}
                  reloadData={reloadData}
                  callbackId={callbackFresh}
                ></MediaRow>
              )}
            </CustomTable>

            {(actionType?.type === "add" || actionType?.type === "edit") && (
              <SideDrawer
                size={'850px'}
                pagetitle={`Document Details :  ${actionType?.data.title}`}
                action={CloseDrawer}
              >
                <div className="p-1">
                  <iframe src={`https://tlhintranet.com/nodeapi/${actionType?.data.path}`} title={actionType?.data.title}></iframe>
                </div>
              </SideDrawer>
            )}
          </div>
        </div>
      </Card>
    </Wrapper >
  );
};
export default AddCity;

/* eslint-disable no-empty-pattern */
import { useState } from "react";
import axios from "axios";
import { Alert, Card, Input, Button } from "../../Library/Module";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";
type formDataProps = {
  email_id: string;
  password: string;
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isError, setIsError]: any = useState(false);
  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [isValid, setIsValid]: any = useState(true);
  const [formData, setFormData] = useState<formDataProps>();

  const onChangeSingleCallback = (data: any) => {
    setFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
    setIsFormSubmitted(false);
  };

  const Submit = async (event: any) => {
    setIsFormSubmitted(true);
    if (formData?.email_id !== undefined && formData?.email_id.length !== 0) {
      setIsValid(true);
      await axios
        .get(`https://tlhintranet.com/nodeapi/api/v1/user/resetpassword?email_id=${formData?.email_id}`)
        .then((response) => {
          console.log("response", response);
          toast.success(response.data.messages);
        })
        .catch(function (error) {
          toast.error("Something went wrong, please try again");
          setIsError(true);
          console.log(error);
        });
    } else {
      setIsValid(false);
      toast.error("Please enter required fields");
    }
  };

  const ForgotPassword = () => {
    navigate("/");
  };

  return (
    <div className="loginWrapper">
      <div className="loginBoxBg">
        <div className="login_admin">
          <Card>
            <div className="logoPlaceholder">
              <img src={Logo} alt="" title="" />
            </div>
            <h3>Forgot Your Password</h3>
            <p className="text-center mb-4">
              Enter Email ID to continue to Tatva Legal Intranet
            </p>
            <div className="container-fluid">

              {!isValid && (
                <Alert
                  AlertStyleType="danger"
                  AlertText={`Please enter required fields`}
                ></Alert>
              )}
              <div className="row">
                <Input
                  inputName="email_id"
                  inputType="text"
                  labelName={"Email ID"}
                  placeholder="Enter email id"
                  required={true}
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                />
              </div>
              <div className="row align-content-end justify-content-end">
                <div className="col-sm-6 text-left">
                  <NavLink to="/">Login</NavLink>
                </div>
                <div className="col-sm-6 text-right">
                  <Button
                    onClick={() => Submit("")}
                    buttonText={"Forgot Password"}
                    buttonStyleType={"primary"}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;

import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { createBrowserHistory } from "history";
import { cacheAdapterEnhancer } from 'axios-extensions';
// import axiosRetry from 'axios-retry';

const user_details = localStorage.getItem('user_details');
const user_details_parse = JSON.parse(user_details)
const token = user_details_parse?.token
const historyConfig = {
    basename: 'tatva-admin/'
};

export const history = createBrowserHistory(historyConfig);

export let site_data = {
    "site_name": "CMS",
    "site_title": "",
    "welcome_back": "Welcome Back !",
    "login_note": "Sign in to continue to Admin Panel"

    //  {site_data.site_name} 
}

export const API = axios.create({
    baseURL: `/`,
    headers: { 'Cache-Control': 'no-cache' },
    // disable the default cache and set the cache flag
    adapter: cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false, cacheFlag: 'useCache' })

});



// axiosRetry(axios, { retries: 3 });




export const setUpAxios = async () => {

    // return (
    //     axios.interceptors.response.use(undefined, (err) => {
    //         const { config, message } = err;
    //         if (!config || !config.retry) {
    //             return Promise.reject(err);
    //         }
    //         // retry while Network timeout or Network Error
    //         if (!(message.includes("timeout") || message.includes("Network Error"))) {
    //             return Promise.reject(err);
    //         }
    //         config.retry -= 1;
    //         const delayRetryRequest = new Promise((resolve) => {
    //             setTimeout(() => {
    //                 console.log("retry the request", config.url);
    //                 resolve();
    //             }, config.retryDelay || 1000);
    //         });
    //         return delayRetryRequest.then(() => axios(config));
    //     })
    // )

    return (
        API.interceptors.response.use(response => {
            console.log("response", response)
            return response;
        }, error => {
            console.log("error", JSON.stringify(error));
            console.log("message aixos", error.response, error.message)
            if (!error.response) {
                alert("Server is not running")
                console.log("Interceptor - Server is not running");

            } else if (error.response.status === 401) {

                console.log("Interceptor - 401 - Unauthorized: Token Invalid, please login again");

            } else if (error.response.status === 400) {

                console.log("Interceptor - 400" + error.response.data.messages);
                return error.response;

            } else if (error.response.status === 404) {
                alert("API not Found")
                console.log("API not Found");
                return null;

            } else {

                const { config, message } = error;
                if (!config || !config.retry) {
                    return Promise.reject(error);
                }

                // retry while Network timeout or Network Error
                // if (!(message.includes("timeout") || message.includes("Network Error"))) {
                //     return Promise.reject(error);
                // }
                config.retry -= 1;
                const delayRetryRequest = new Promise((resolve) => {
                    setTimeout(() => {
                        console.log("retry the request", config.url);
                        resolve();
                    }, config.retryDelay || 1000);
                });
                return delayRetryRequest.then(() => axios(config));
            }
        })
    )

}


// when request, can set retry times and retry delay time
// axios.get(url, {retry: 3, retryDelay: 3000});


/***** Login ******/

export const authenticate = async (data) => {
    const response = await API.post(process.env.react_app_base_url + '/api/v1/user/login', data)
    return response;
};


// Custom Post Type
export const CreateCustomType = async (data) => {
    const response = await API.post(process.env.react_app_base_url + '/api/v1/cms-type', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CustomTypeById = async (id, useCache) => {
    console.log("service CustomTypeById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-type/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CustomTypeBySlug = async (slug, useCache) => {
    console.log("service CustomTypeById", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-type/by-slug/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetCustomPostType = async (data, useCache) => {

    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-type', {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateCustomPostType = async (data) => {
    console.log("service UpdateCustomPostType", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/cms-type', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteCustomPostType = async (id) => {
    console.log("service DeleteCustomPostType", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/cms-type/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


// Category
export const CreateCategory = async (data) => {
    console.log("service CreateCategory", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/cms-category', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CategoryById = async (id, useCache) => {
    console.log("service CategoryById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-category/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CategoryBySlug = async (slug, useCache) => {
    console.log("service CategoryBySlug", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-category/by-slug/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetCategory = async (data, useCache) => {
    console.log("service GetCategory", data)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-category', {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateCategory = async (data) => {
    console.log("service UpdateCategory", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/cms-category', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteCategory = async (id) => {
    console.log("service DeleteCategory", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/cms-category/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CategoryByCMSType = async (slug, useCache) => {
    console.log("service CategoryByCMSType", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-category/by-cms-type/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


// Fields
export const CreateField = async (data) => {
    console.log("service CreateField", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/cms-fields', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const FieldById = async (id, useCache) => {
    console.log("service FieldById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-fields/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const FieldBySlug = async (slug, useCache) => {
    console.log("service FieldBySlug", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-fields/by-slug/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetField = async (data, useCache) => {
    console.log("service GetField", data)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-fields', {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateField = async (data) => {
    console.log("service UpdateField", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/cms-fields', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteField = async (id) => {
    console.log("service DeleteField", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/cms-fields/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


export const FieldsByCMSType = async (slug, useCache) => {
    console.log("service CategoryByCMSType", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-fields/by-type/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


// Media
export const CreateMedia = async (data) => {
    console.log("service CreateField", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/media', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const MediaById = async (id, useCache) => {
    console.log("service FieldById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/media/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const MediaBySlug = async (slug, useCache) => {
    console.log("service FieldBySlug", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/media/by-slug/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetMedia = async (data, useCache) => {
    console.log("service GetField", data)
    let url = data ? process.env.react_app_base_url + `/api/v1/media?${data}` : process.env.react_app_base_url + `/api/v1/media`
    const response = await API.get(url, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateMedia = async (data) => {
    console.log("service UpdateField", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/media', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteMedia = async (id, path) => {
    console.log("service DeleteField", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/media/' + id + '/' + path, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


// CMS
export const CreateCMS = async (data) => {
    console.log("service CreateCMS", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/cms', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CMSById = async (id, useCache) => {
    console.log("service FieldById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};
export const CMSByAuthor = async (id, useCache) => {
    console.log("service FieldById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms/by-author/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CMSByCategory = async (id, useCache) => {
    console.log("service FieldById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms/by-category/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};
export const CMSByCategoryName = async (id, useCache) => {
    console.log("service FieldById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms/by-category-name/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

// 
export const CMSBySlug = async (slug, useCache) => {
    console.log("service CMSBySlug", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms/by-slug/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CMSByType = async (slug, useCache) => {
    console.log("service CMSBySlug", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms/by-type/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetCMS = async (data, useCache) => {
    console.log("service GetCMS", data)
    let url = data ? process.env.react_app_base_url + `/api/v1/cms?${data}` : process.env.react_app_base_url + `/api/v1/cms`
    const response = await API.get(url, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateCMS = async (data) => {
    console.log("service UpdateField", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/cms', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteCMS = async (id) => {
    console.log("service DeleteField", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/cms/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


// User Type
export const CreateUserType = async (data) => {
    const response = await API.post(process.env.react_app_base_url + '/api/v1/user-type', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UserTypeById = async (id, useCache) => {
    console.log("service UserTypeById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/user-type/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UserTypeBySlug = async (slug, useCache) => {
    console.log("service UserTypeBySlug", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/user-type/by-slug/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetUserType = async (data, useCache) => {
    console.log("service GetUserType", data)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/user-type', {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateUserType = async (data) => {
    console.log("service UpdateUserType", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/user-type', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteUserType = async (id) => {
    console.log("service DeleteUserType", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/user-type/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

// User
export const RegisterUser = async (data) => {
    const response = await API.post(process.env.react_app_base_url + '/api/v1/user/register', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const RegisterUserUpdate = async (data) => {
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/user/register', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UserById = async (id, useCache) => {
    console.log("service UsersById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/user/users/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


export const GetUsers = async (data, useCache) => {
    console.log("service GetUserType", data)
    let url = data ? process.env.react_app_base_url + `/api/v1/user/users?${data}` : process.env.react_app_base_url + `/api/v1/user/users`
    const response = await API.get(url, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateUser = async (data) => {
    console.log("service UpdateUser", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/user-profile', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


export const CreateProfile = async (data) => {
    console.log("service CreateProfile", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/user-profile', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UserProfile = async (data) => {
    console.log("service UserProfile", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/user-profile', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetUserDetailsById = async (id, useCache) => {
    console.log("service GetUserDetailsById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/user-profile/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteUserInfo = async (data) => {
    console.log("service DeleteUserInfo", data)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/user-profile/' + data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteUser = async (data) => {
    console.log("service DeleteUser", data)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/user/delete-user/' + data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

// submission


export const GetSubmission = async (id, useCache) => {
    console.log("service CustomTypeById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/submission', {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetSubmissionsByQuery = async (id, useCache) => {
    console.log("service CustomTypeById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/submission?' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


// Sub Category
export const CreateSubCategory = async (data) => {
    console.log("service CreateCategory", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/cms-sub-category', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CategorySubById = async (id, useCache) => {
    console.log("service CategoryById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-sub-category/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CategorySubBySlug = async (slug, useCache) => {
    console.log("service CategoryBySlug", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-sub-category/by-slug/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetSubCategory = async (data, useCache) => {
    console.log("service GetSubCategory", data)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-sub-category', {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateSubCategory = async (data) => {
    console.log("service UpdateCategory", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/cms-sub-category', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteSubCategory = async (id) => {
    console.log("service DeleteCategory", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/cms-sub-category/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const CategorySubByCMSType = async (slug, useCache) => {
    console.log("service CategoryByCMSType", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-sub-category/by-cms-type/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


export const CategoryBySubCategory = async (slug, useCache) => {
    console.log("CategoryBySubCategory", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-sub-category/by-cms-sub-catgory/' + slug, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const SubCategoryByCategorySlug = async (slug, useCache) => {
    console.log("CategoryBySubCategory", slug)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/cms-sub-category/by-cms-sub-catgory/' + slug, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

// City Management

export const AddCityService = async (data) => {
    console.log("AddCityService", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/city', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    console.log("AddCityService response", response)
    return response;
};

export const DeleteCityService = async (id) => {
    console.log("service DeleteCustomPostType", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/city/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetCityService = async () => {
    const response = await API.get(process.env.react_app_base_url + '/api/v1/city', {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetCityDetaildByIdService = async (id) => {
    console.log("service GetCityDetaildByIdService", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/city/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateCityDetaildByIdService = async (data) => {
    console.log("service UpdateCityDetaildByIdService", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/city', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


// Area Management
export const AddAreaService = async (data) => {
    console.log("AddCityService", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/area', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetAreaService = async () => {
    const response = await API.get(process.env.react_app_base_url + '/api/v1/area', {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateAreaDetaildByIdService = async (data) => {
    console.log("service UpdateCityDetaildByIdService", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/area', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetAreaDetaildByIdService = async (id) => {
    console.log("service GetCityDetaildByIdService", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/area/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

// by-city

export const GetCityAreaService = async (city) => {
    const response = await API.get(process.env.react_app_base_url + '/api/v1/area/by-city/' + city, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteCityAreaService = async (id) => {
    console.log("service DeleteCustomPostType", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/area/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};



// Pets
export const AddPetsService = async (data) => {
    console.log("AddCityService", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/pets', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    console.log("AddCityService response", response)
    return response;
};

export const DeletePetsService = async (id) => {
    console.log("service DeleteCustomPostType", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/pets/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetPetsService = async () => {
    const response = await API.get(process.env.react_app_base_url + '/api/v1/pets', {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetPetsDetaildByIdService = async (id) => {
    console.log("service GetCityDetaildByIdService", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/pets/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdatePetsDetaildByIdService = async (data) => {
    console.log("service UpdateCityDetaildByIdService", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/pets', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


// Vendor
export const AddVendorService = async (data) => {
    console.log("AddCityService", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/vendor', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    console.log("AddCityService response", response)
    return response;
};

export const GetVendorService = async () => {
    const response = await API.get(process.env.react_app_base_url + '/api/v1/vendor', {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetVendorsByQuery = async (id, useCache) => {
    console.log("service CustomTypeById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/vendor?' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteVendorService = async (id) => {
    console.log("service DeleteCustomPostType", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/vendor/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetVendorServiceById = async (id) => {
    console.log("service GetCityDetaildByIdService", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/vendor/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateVendorServiceById = async (data) => {
    console.log("service UpdateCityDetaildByIdService", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/vendor', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const AssignManager = async (data) => {
    console.log("service UpdateCityDetaildByIdService", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/leaves/assign-managers', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetAssignedManagerByUserEmail = async (user_email_id) => {
    console.log("service GetCityDetaildByIdService", user_email_id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/leaves/assign-managers/' + user_email_id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteAssignedManagerByUserEmail = async (id) => {
    console.log("service DeleteCustomPostType", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/leaves/assign-managers/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

// Appointment Management

export const BookAppointment = async (data) => {
    const response = await API.post(process.env.react_app_base_url + '/api/v1/appointment-booking', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetMasterBookAppointment = async (data, useCache) => {
    console.log("service CustomTypeById", data)
    let url = data ? process.env.react_app_base_url + `/api/v1/appointment-booking/master?${data}` : process.env.react_app_base_url + `/api/v1/appointment-booking/master`
    const response = await API.get(url, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetMasterBookAppointmentByuser = async (id, useCache) => {
    console.log("service CustomTypeById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/appointment-booking/master/by-user/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetSingleBookAppointment = async (id, useCache) => {
    console.log("id GetSingleBookAppointment", id)
    let url = id ? process.env.react_app_base_url + `/api/v1/appointment-booking/entries?${id}` : process.env.react_app_base_url + `/api/v1/appointment-booking/entries`
    console.log("GetSingleBookAppointment", url)
    const response = await API.get(url, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetSingleBookAppointmentByuser = async (id, useCache) => {
    console.log("service CustomTypeById", id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/appointment-booking/entries/by-user/' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteBookAppointment = async (id) => {
    console.log("service DeleteBookAppointment", id)

    const response = await API.delete(process.env.react_app_base_url + '/api/v1/appointment-booking/master/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateBookAppointment = async (data) => {
    console.log("service UpdateCustomPostType", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/appointment-booking/master', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

// 

export const GetComment = async (data, useCache) => {
    console.log("service CustomTypeById", data)
    let url = data ? process.env.react_app_base_url + `/api/v1/comments?${data}` : process.env.react_app_base_url + `/api/v1/comments`
    const response = await API.get(url, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteComment = async (id, post_id) => {
    console.log("service DeleteCustomPostType", id, post_id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/comments/' + id + '/' + post_id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};
// Likes
export const GetLikes = async (data, useCache) => {
    console.log("service CustomTypeById", data)
    let url = data ? process.env.react_app_base_url + `/api/v1/likes?${data}` : process.env.react_app_base_url + `/api/v1/likes`
    const response = await API.get(url, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteLikes = async (id, rowData) => {
    console.log("service DeleteCustomPostType", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/likes/' + id + '/' + rowData, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateLikes = async (data) => {
    console.log("service UpdateCustomPostType", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/likes', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const ApplyLeave = async (data) => {
    const response = await API.post(process.env.react_app_base_url + '/api/v1/leave-master', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const EditLeave = async (parent_id, data) => {
    console.log("service EditLeave", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/leave-master', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const getDaysLeave = async (params, useCache) => {
    console.log("id GetSingleBookAppointment", params)
    let url = params ? process.env.react_app_base_url + `/api/v1/leave-days?${params}` : process.env.react_app_base_url + `/api/v1/leave-days`
    console.log("GetSingleBookAppointment", url)
    const response = await API.get(url, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const getMasterLeave = async (params, useCache) => {
    console.log("id getMasterLeave", params)
    let url = params ? process.env.react_app_base_url + `/api/v1/leave-master?${params}` : process.env.react_app_base_url + `/api/v1/leave-master`
    console.log("getMasterLeave", url)
    const response = await API.get(url, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

// 

export const getMasterManagerLeave = async (params, useCache) => {
    console.log("id getMasterLeave", params)
    let url = params ? process.env.react_app_base_url + `/api/v1/leave-master/managers-leave?${params}` : process.env.react_app_base_url + `/api/v1/leave-master`
    console.log("getMasterLeave", url)
    const response = await API.get(url, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateMasterStatus = async (data) => {
    console.log("service UpdateCategory", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/leave-master/update-master', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const getTransactionLeave = async (id, useCache) => {
    console.log("id GetSingleBookAppointment", id)
    let url = id ? process.env.react_app_base_url + `/api/v1/leave-transaction?${id}` : process.env.react_app_base_url + `/api/v1/leave-transaction`
    console.log("GetSingleBookAppointment", url)
    const response = await API.get(url, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

// Transaction 
export const UpdateManagerStatus = async (data) => {
    console.log("service UpdateCategory", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/leave-transaction', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


export const DeleteLeaveMaster = async (id) => {
    console.log("service DeleteCustomPostType", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/leave-master/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

// Rooms
export const GetConfRoom = async (data, useCache) => {
    console.log("service CustomTypeById", data)
    let url = data ? process.env.react_app_base_url + `/api/v1/conference-room?${data}` : process.env.react_app_base_url + `/api/v1/conference-room`
    const response = await API.get(url, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const AddConfRoom = async (data) => {
    console.log("service CreateCategory", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/conference-room', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateConfRoom = async (data) => {
    console.log("service UpdateCustomPostType", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/conference-room', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteConfRoom = async (id) => {
    console.log("service DeleteCategory", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/conference-room/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

// Events
export const GetEvents = async (data, useCache) => {
    console.log("service CustomTypeById", data)
    let url = data ? process.env.react_app_base_url + `/api/v1/events?${data}` : process.env.react_app_base_url + `/api/v1/events`
    const response = await API.get(url, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const AddEvents = async (data) => {
    console.log("service CreateCategory", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/events', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateEvents = async (data) => {
    console.log("service UpdateCustomPostType", data)
    const response = await API.patch(process.env.react_app_base_url + '/api/v1/events', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteEvents = async (id) => {
    console.log("service DeleteCategory", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/events/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

// Emails

export const GetEmails = async (data, useCache) => {
    console.log("service CustomTypeById", data)
    let url = data ? process.env.react_app_base_url + `/api/v1/emails?${data}` : process.env.react_app_base_url + `/api/v1/emails`
    const response = await API.get(url, {
        retry: 5, retryDelay: 500,
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteEmails = async (id, post_id) => {
    console.log("service DeleteCustomPostType", id, post_id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/emails/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const SendEmails = async (data) => {
    console.log("service CreateCategory", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/emails', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetFakeAPI = async (data, useCache) => {
    console.log("service CustomTypeById", data)
    let url = data ? `https://tlhintranet.com/nodeapi/api/v1/emails/fakeapi?${data}` : `https://tlhintranet.com/nodeapi/api/v1/emails/fakeapi`
    const response = await API.get(url, {
        retry: 5, retryDelay: 500,
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

// New 

export const AddSetting = async (data) => {
    const response = await API.post('https://tlhintranet.com/nodeapi/api/v1/settings', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const SettingById = async (id, useCache) => {
    console.log("service CustomTypeById", id)
    const response = await API.get('https://tlhintranet.com/nodeapi/api/v1/settings?' + id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const GetSetting = async (data) => {
    const response = await API.get('https://tlhintranet.com/nodeapi/api/v1/settings?' + data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const UpdateSetting = async (data) => {
    console.log("service UpdateCustomPostType", data)
    const response = await API.patch('https://tlhintranet.com/nodeapi/api/v1/settings', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const DeleteSetting = async (id) => {
    console.log("service DeleteCustomPostType", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/settings/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

// LOP Managers
export const GetLOPAssignedManagerByUserEmail = async (user_email_id) => {
    console.log("service GetCityDetaildByIdService", user_email_id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/leaves/lop/assign-managers/' + user_email_id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const LOPAssignManager = async (data) => {
    console.log("service UpdateCityDetaildByIdService", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/leaves/lop/assign-managers', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};


export const DeleteLOPAssignedManagerByUserEmail = async (id) => {
    console.log("service DeleteCustomPostType", id)
    const response = await API.delete(process.env.react_app_base_url + '/api/v1/leaves/lop/assign-managers/' + id, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

// assign-managers/isLopAssigned


export const IsLOPAssignedManagerByUserEmail = async (user_email_id) => {
    console.log("service GetCityDetaildByIdService", user_email_id)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/leaves/lop/assign-managers/isLopAssigned/' + user_email_id, {

        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const getNewsFeeds = async (params) => {
    console.log("service GetCityDetaildByIdService", params)
    const response = await API.get(process.env.react_app_base_url + '/api/v1/news-feeds?' + params, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};
import react, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { Button, Alert } from "../../Library/Module";
import { DeleteCategory, DeleteConfRoom, DeleteEvents } from "../../servies/services";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { GetDay, replaceWithDash } from "../../Library/Utility/Utility";

interface AlertProps {
    rowData?: any;
    index?: any;
    showCells?: any;
    reloadData?: any;
}

const TableRow = ({
    rowData,
    index,
    showCells,
    reloadData,
}: AlertProps): JSX.Element => {

    const navigate = useNavigate();
    // @ts-ignore
    const profile_details = JSON.parse(localStorage.getItem("profile_details")) || null;
    const isAdmin = profile_details?.user_type === 'admin'
    const editPage = () => {
        navigate(`/events-management/edit-rooms/edit/${rowData._id}`);
    };

    const viewPage = () => {
        navigate(`/events-management/edit-rooms/view/${rowData._id}`);
    };

    const DeleteCity = () => {
        confirmAlert({
            title: `Are you sure you want to delete this ${rowData.title}?`,
            message: `This Item will be deleted immediately. You can't undo this action.`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => DeleteCityAPI(),
                },
                {
                    label: "No",
                    onClick: () => "",
                },
            ],
        });
    };

    const DeleteCityAPI = async () => {
        const APIResponse = await DeleteEvents(rowData._id);
        if (APIResponse?.data?.isSuccess === true) {
            toast.success(APIResponse?.data?.messages);
            reloadData(true);
        } else {
            toast.error("Something went wrong, please try again");
        }
    };

    return (
        <tr key={index}>
            <td>{rowData.title}</td>
            <td >{rowData.type}</td>
            <td >{moment(rowData.date).format('DD/MM/YYYY')} </td>
            <td> {GetDay(moment(rowData.date).day())}</td>
            <td>{rowData.status === '1' ? 'Active' : 'Inactive'}</td>
            <td style={{ width: "250px" }}>
                {isAdmin ?
                    <>

                        <Button
                            onClick={() => DeleteCity()}
                            buttonStyleOutline
                            buttonStyleType="danger"
                        >
                            <span className="material-symbols-outlined">
                                delete
                            </span>
                        </Button>

                        <Button
                            onClick={() => editPage()}
                            buttonStyleOutline
                            buttonStyleType="primary"
                        >
                            <span className="material-symbols-outlined">
                                edit
                            </span>
                        </Button>

                        <Button
                            onClick={() => viewPage()}
                            buttonStyleOutline
                            buttonStyleType="primary"
                        >
                            <span className="material-symbols-outlined">
                                visibility
                            </span>
                        </Button>
                    </>
                    : '-'}
            </td>

        </tr>
    );
};

export default TableRow;

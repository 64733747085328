/* eslint-disable no-empty-pattern */
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Alert, Card, Input, Button } from "../../Library/Module";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";
import { GetComment } from "../../servies/services";
import moment from "moment";
import { objectToQueryString } from "../../Library/Utility/Utility";

type formDataProps = {
    comments?: string;

};
type InputProps = {
    postId: string
    postName: string
    commentCount: any

}
const Comments = ({
    postId,
    postName,
    commentCount,
}: InputProps): JSX.Element => {
    const navigate = useNavigate();
    const [isError, setIsError]: any = useState(false);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [isValid, setIsValid]: any = useState(true);
    const [formData, setFormData] = useState<formDataProps>();
    const [totalComments, setTotalComments] = useState<any>();

    // @ts-ignore
    const profile_details = JSON.parse(localStorage.getItem("profile_details"));

    const [tableCatData, setTableCatData] = useState<any>([]);
    const [isCatLoading, setIsCatLoading] = useState(false);
    const [reloadAPI, setReloadAPI] = useState(false);

    const queryParams = new URLSearchParams(window.location.search);
    const email_token = queryParams.get("email_token");

    const [token, setToken] = useState();

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    const Submit = async (event: any) => {
        setIsFormSubmitted(true);
        if (
            formData?.comments !== undefined &&
            formData?.comments.length !== 0
        ) {
            setIsValid(true);
            const postData = {
                "user_id": profile_details?._id,
                "user_name": profile_details?.first_name + ' ' + profile_details?.last_name,
                "user_email": profile_details?.email_id,
                "user_comments": formData?.comments,
                "post_id": postId,
                "post_name": postName,
                "organization_id": "1",
                "status": "1",
                "total_comments": Number(totalComments)
            };
            await axios
                .post(`https://tlhintranet.com/nodeapi/api/v1/comments`, postData)
                .then((response) => {
                    console.log("response", response);
                    toast.success("Comment has been added succesfully");
                    getData();
                    setFormData({})
                })
                .catch(function (error) {
                    toast.error("Something went wrong, please try again");
                    setIsError(true);
                    console.log("error.response", error.response);
                });
        } else {
            setIsValid(false);
            toast.error("Please enter required fields");
        }
    };



    const getData = useCallback(async () => {
        const params = {
            "post_id": postId,
        }
        let apidata = await GetComment(objectToQueryString(params));
        const APIResponse = apidata?.data?.data;
        setTableCatData(APIResponse);
        commentCount(APIResponse.length)
        setTotalComments(APIResponse.length)
    }, [postId]);

    useEffect(() => {
        getData();
    }, [postId]);


    return (
        <>
            <div className="mb-3">
                <Card>
                    <h3>Add Comment</h3>
                    <div className="container-fluid">
                        <div className="row">
                            <Input
                                inputName="comments"
                                inputType="textarea"
                                labelName={"Comment"}
                                placeholder="Comment"
                                required={true}
                                onChangeSingleCallback={onChangeSingleCallback}
                                col="9"
                                isFormSubmitted={isFormSubmitted}
                                value={formData?.comments}
                            />
                        </div>
                        <div className="row">
                            <div className="col-sm-9 text-right">
                                <Button
                                    onClick={() => Submit("")}
                                    buttonText={"Add Comment"}
                                    buttonStyleType={"primary"}
                                />
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
            <Card>
                <div className="comments-list" id="comments">
                    <h3>Comments</h3>
                    <div className="list">
                        {tableCatData &&
                            tableCatData.map((item: any) => {
                                return (
                                    <div className="item">
                                        <p><strong>{item.user_name}</strong> <span>{moment(item.created_on, "YYYYMMDD").fromNow()}</span></p>
                                        <div className="comment-box">
                                            {item.user_comments}
                                        </div>
                                    </div>
                                )
                            })
                        }

                        {tableCatData.length === 0 && <p>No Comments </p>}

                    </div>


                </div>
            </Card>
        </>
    );
};
export default Comments;

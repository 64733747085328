import react, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { Button, Alert, Tag } from "../../Library/Module";
import { DeleteBookAppointment, DeleteCityService } from "../../servies/services";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";




import { getColorCode, GetTimeFrame } from "../../Library/Utility/Utility";

interface AlertProps {
  rowData?: any;
  index?: any;
  showCells?: any;
  reloadData?: any;
  callbackId?: any;
}

const ConferenceRoomRow = ({
  rowData,
  index,
  showCells,
  reloadData,
  callbackId
}: AlertProps): JSX.Element => {
  const navigate = useNavigate();

  const editPage = () => {
    navigate(`/conference-rooms-management/view-conference-rooms/edit-booking/edit/${rowData.parent_id}`);
  };

  const viewPage = () => {
    navigate(`/conference-rooms-management/view-conference-rooms/edit-booking/view/${rowData.parent_id}`);
  };

  const DeleteCity = () => {
    confirmAlert({
      title: `Are you sure you want to delete this ${rowData.title}?`,
      message: `This Item will be deleted immediately. You can't undo this action.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteCityAPI(),
        },
        {
          label: "No",
          onClick: () => "",
        },
      ],
    });
  };

  const DeleteCityAPI = async () => {
    const APIResponse = await DeleteBookAppointment(rowData.parent_id);
    if (APIResponse?.data?.isSuccess === true) {
      toast.success(APIResponse?.data?.messages);
      reloadData(true);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const showSchedule = () => {
    callbackId(rowData)
  }
  const getcolorCodes = useMemo(() => {
    return getColorCode(rowData.meeting_room_name)
  }, [rowData.meeting_room_name])
  return (
    <>
      <tr key={index}>
        {showCells && showCells?._id && <td>{index + 1}</td>}
        {showCells && showCells?.user_name && <td>{rowData.user_name}</td>}
        {showCells && showCells?.meeting_room_name && <td>
          <Tag color={getcolorCodes?.colorCode} title={rowData.meeting_room_name}></Tag>
        </td>}
        {showCells && showCells?.start_date && <td>{moment(rowData.start_date).format("DD/MM/YYYY")} {GetTimeFrame(rowData?.start_time)}</td>}
        {showCells && showCells?.end_date && <td>{moment(rowData.end_date).format("DD/MM/YYYY")}  {GetTimeFrame(rowData?.end_time)}</td>}
        {showCells && showCells?.meeting_type && <td>{rowData.meeting_type}</td>}
        {showCells && showCells?.end_date && <td>{moment(rowData?.created_on).format("DD/MM/YYYY")}</td>}
        {showCells && showCells?._id && (
          <td style={{ width: "300px" }}>
            <Button
              onClick={() => DeleteCity()}
              buttonStyleOutline
              buttonStyleType="danger"
            >
              <span className="material-symbols-outlined">
                delete
              </span>
            </Button>
            <Button
              onClick={() => editPage()}
              buttonStyleOutline
              buttonStyleType="primary"
              disabled={rowData.meeting_type === 'Recurring Meeting'}
            >
              <span className="material-symbols-outlined">
                edit
              </span>
            </Button>

            <Button
              onClick={() => showSchedule()}
              buttonStyleOutline
              buttonStyleType="primary"
            >
              <span className="material-symbols-outlined">
                visibility
              </span>
            </Button>
          </td>
        )}
      </tr>
    </>
  );
};

export default ConferenceRoomRow;

import react from "react";
import moment from "moment";
import { Button } from "../../Library/Module";
import { DeleteLeaveMaster } from "../../servies/services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

interface LeaveRowProps {
  rowData?: any;
  index?: any;
  showCells?: any;
  reloadData?: any;
  callbackId?: any;
}

const LeaveRow = ({
  rowData,
  index,
  showCells,
  reloadData,
  callbackId
}: LeaveRowProps): JSX.Element => {
  const navigate = useNavigate();
  // @ts-ignore
  const profile_details = JSON.parse(localStorage.getItem("profile_details")) || null;

  const editPage = () => {
    navigate(`/leaves-management/edit-leaves/edit/${rowData.parent_id}?email_id=${rowData.email_id}`);
  };

  const Delete = () => {
    confirmAlert({
      title: `Are you sure you want to delete this ${rowData?.leave_reason} Leave?`,
      message: `This Item will be deleted immediately. You can't undo this action.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteAPI(),
        },
        {
          label: "No",
          onClick: () => "",
        },
      ],
    });
  };

  const DeleteAPI = async () => {
    const APIResponse = await DeleteLeaveMaster(rowData.parent_id);
    if (APIResponse?.data?.isSuccess === true) {
      toast.success(APIResponse?.data?.messages);
      reloadData(true);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const showSchedule = () => {
    let dataOrigin = {
      type: "add",
      id: 'id',
      mainType: 'schedule',
      data: rowData
    }
    callbackId(dataOrigin)
  }

  const showManagerStatus = () => {
    let dataOrigin = {
      type: "add",
      id: 'id',
      mainType: 'manager',
      data: rowData
    }
    callbackId(dataOrigin)
  }

  return (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>{rowData.user_name}</td>
      <td>{rowData.leave_reason}</td>
      <td>{moment(rowData.start_date).format("DD/MM/YYYY")} {rowData.start_time}</td>
      <td>{moment(rowData.end_date).format("DD/MM/YYYY")} {rowData.start_time}</td>
      <td>{rowData.leave_type}</td>
      <td>{rowData.status}</td>
      <td>{rowData.days}</td>

      <td>{rowData.month}</td>
      <td>{rowData.year}</td>
      <td>{rowData.halfYear}</td>

      <td>{moment(rowData.created_on).format("DD/MM/YYYY")} </td>
      <td style={{ width: "250px", minWidth:'250px' }}>
        <div >
          {profile_details?.user_type === 'leave-approver' ?
            <>
              <Button onClick={() => showManagerStatus()} buttonStyleOutline buttonStyleType="primary">
                <span className="material-symbols-outlined">
                  calendar_month
                </span>
              </Button></> :
            <>
              <Button disabled={profile_details?.user_type !== 'admin' && rowData.status !== 'Pending'} onClick={() => Delete()} buttonStyleOutline buttonStyleType="danger">
                <span className="material-symbols-outlined">
                  delete
                </span>
              </Button>
              <Button disabled={rowData.status !== 'Pending'} onClick={() => editPage()} buttonStyleOutline buttonStyleType="primary">
                <span className="material-symbols-outlined">
                  edit
                </span>
              </Button>
              <Button onClick={() => showSchedule()} buttonStyleOutline buttonStyleType="primary">
                <span className="material-symbols-outlined">
                  visibility
                </span>
              </Button>
              <Button onClick={() => showManagerStatus()} buttonStyleOutline buttonStyleType="primary">
                <span className="material-symbols-outlined">
                  calendar_month
                </span>
              </Button>
            </>
          }
        </div>
      </td>
    </tr>
  );
};

export default LeaveRow;

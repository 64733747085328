import react, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { Button, Alert } from "../../Library/Module";
import { DeleteCityAreaService, DeleteComment, DeleteLikes } from "../../servies/services";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import CustomTooltip from "../../Library/Tooltip/Tippy";
import { trimString } from "../../Library/Utility/Utility";


interface AlertProps {
  rowData?: any;
  index?: any;
  showCells?: any;
  reloadData?: any;
}

const ConferenceRow = ({
  rowData,
  index,
  showCells,
  reloadData,
}: AlertProps): JSX.Element => {
  const DeleteCity = () => {
    confirmAlert({
      title: `Are you sure you want to delete this?`,
      message: `This Item will be deleted immediately. You can't undo this action.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteCityAPI(),
        },
        {
          label: "No",
          onClick: () => "",
        },
      ],
    });
  };

  const DeleteCityAPI = async () => {
    const APIResponse = await DeleteLikes(rowData._id, rowData?.post_id);
    if (APIResponse?.data?.isSuccess === true) {
      toast.success(APIResponse?.data?.messages);
      reloadData(true);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };
  return (
    <tr key={index}>
      <td><CustomTooltip
        title={"title"}
        position={"bottom"}
        disabled={false}
        content={rowData.post_name}>
        {trimString(rowData.post_name, 50)}
      </CustomTooltip> </td>
      <td>{rowData.user_name}</td>
      <td>{moment(rowData.created_on).format('DD/MM/YYYY')}</td>
      <td style={{ width: "250px" }}>
        <Button
          buttonStyleOutline
          buttonStyleType="danger"
          onClick={() => DeleteCity()}
        >
          <span className="material-symbols-outlined">
                            delete
                        </span>
        </Button>
      </td>
    </tr>
  );
};

export default ConferenceRow;

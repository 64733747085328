/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import { Alert, Card, Input, Button, Select } from "../../Library/Module";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import { RegisterUser, UserById, RegisterUserUpdate, AssignManager, } from "../../servies/services";
import MediaPopup from "../Media/MediaPopup";
import axios from "axios";

type formDataProps = {
  confirm_password: string;
  password?: string;

};

const statusOption = [
  { text: "Active", id: 1, value: "1" },
  { text: "Inactive", id: 2, value: "0" },
];

const userOption = [
  { text: "Admin", id: 1, value: "admin" },
  { text: "Leave Approver", id: 2, value: "leave-approver" },
  { text: "Professional", id: 2, value: "professional" },
];

const teamOptions = [
  { text: "Founder & Managing Partner", id: 1, value: "Founder & Managing Partner" },
  { text: "Partners", id: 2, value: "Partners" },
  { text: "Of-Counsel", id: 2, value: "Of-Counsel" },
  { text: "Managers", id: 2, value: "Managers" },
  { text: "Senior Associates", id: 2, value: "Senior Associates" },
  { text: "Associates", id: 2, value: "Associates" },
];

const ChangePassword = () => {

  let { id, type } = useParams();
  const [isError, setIsError]: any = useState(false);
  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [isLoading, setIsLoading]: any = useState(true);

  const [isValid, setIsValid]: any = useState(true);
  const [formData, setFormData] = useState<formDataProps>();
  const [formDataManager, setFormDataManager] = useState<any>();


  const [serverError, setServerError]: any = useState(
    "Something went wrong, please try again"
  );
  // @ts-ignore
  const profile_details = JSON.parse(localStorage.getItem("profile_details")) || null;

  const onChangeSingleCallback = (data: any) => {
    // console.log("data onChangeSingleCallback", data)
    setFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
    setIsFormSubmitted(false);
  };

  const Submit = async (event: any) => {
    setIsFormSubmitted(true);
    if (
      formData?.confirm_password !== undefined &&
      formData?.confirm_password.length !== 0 &&
      formData?.password !== undefined &&
      formData?.password.length !== 0
    ) {
      setIsValid(true);
      const postData = {
        id: id,
        password: formData?.password,
      };
      await axios
        .post(`https://tlhintranet.com/nodeapi/api/v1/user/user-change-password`, postData)
        .then((response) => {
          console.log("response", response);
          toast.success(response.data.messages);

        })
        .catch(function (error) {
          toast.error("Something went wrong, please try again");
          setIsError(true);
          console.log("error.response", error.response);
        });
    } else {
      setIsValid(false);
      toast.error("Please enter required fields");
    }
  };



  return (
    <Wrapper pageTitle="Dashboard">
      <Card title="Change Password">
        <p className="text-left mb-4">
          You can Change Password in this module.
        </p>
        <div className="">
          {isError && (
            <Alert AlertStyleType="danger" AlertText={serverError}></Alert>
          )}
          {!isValid && (
            <Alert
              AlertStyleType="danger"
              AlertText={`Please enter required fields`}
            ></Alert>
          )}
          {isLoading && (
            <div className="row">
              <div className="col-md-8">
                <Input
                  inputName="password"
                  inputType="password"
                  labelName={"Password"}
                  placeholder="Enter Password"
                  required={true}
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}

                  value={formData?.password}

                />

                <Input
                  inputName="confirm_password"
                  inputType="password"
                  required={true}
                  labelName={"Confirm Password"}
                  placeholder="Enter Confirm Password"
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                  value={formData?.confirm_password}

                />

                <div className="col-sm-12 text-right">
                  <Button
                    onClick={() => Submit("")}
                    buttonText={
                      "Change Password"
                    }
                    buttonStyleType={"primary"}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="row align-content-end justify-content-end">

                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
    </Wrapper>
  );
};
export default ChangePassword;

/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import { Alert, Card, Input, Button, Select, DatePickerComp, Checkbox } from "../../Library/Module";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import {
    CreateCategory, CategoryById, UpdateCategory, GetCustomPostType, GetEvents, AddConfRoom, UpdateConfRoom, AddEvents, UpdateEvents, SendEmails
} from "../../servies/services";
import MediaPopup from "../Media/MediaPopup";
import { objectToQueryString } from "../../Library/Utility/Utility";
import Checkboxes from "../../Library/Checkbox/Checkboxes";

type formDataProps = {
    email: string;
    type: string;
    _id?: string
};

const customPostType = [
    { text: "Users", id: 1, value: "Users" },
    { text: "Leave Calender", id: 1, value: "LeaveCalender" },
    { text: "Leave Table", id: 1, value: "LeaveTable" },
    { text: "Holidays", id: 1, value: "Holiday" },
    { text: "Discussion Forum", id: 1, value: "DiscussionForum" },
    { text: "Discussion Forum Category", id: 1, value: "DiscussionForumCategory" },
    { text: "Conference Booking", id: 1, value: "Conference Booking" },
    { text: "Conference Rooms", id: 1, value: "Conference Rooms" },
    { text: "Media", id: 1, value: "Media" },
    { text: "Likes", id: 1, value: "Likes" },
    { text: "Comments", id: 1, value: "Comments" }
]

const AllDownload = [
    { text: "All Download", id: 1, value: "All Download" }
]

const CreateEmails = () => {
    const navigate = useNavigate();
    let { id, type } = useParams();

    const [isError, setIsError]: any = useState(false);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [isLoading, setIsLoading]: any = useState(true);

    const [isValid, setIsValid]: any = useState(true);
    const [formData, setFormData] = useState<formDataProps>();
    const [submitType, setSubmitType] = useState(type);


    const [serverError, setServerError]: any = useState(
        "Something went wrong, please try again"
    );

    // @ts-ignore
    const profile_details = JSON.parse(localStorage.getItem("profile_details"));

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    const Submit = async (event: any) => {
        setIsFormSubmitted(true);
        if (
            formData?.email !== undefined &&
            formData?.email.length !== 0

        ) {
            setIsValid(true);
            let postData = {
                email: formData?.email,

                id: id
            };
            if (submitType === "add") {
                delete postData.id;
                const APIResponse = await SendEmails(postData);
                if (APIResponse?.data?.isSuccess === true) {
                    toast.success(APIResponse?.data?.messages);
                    redirectToParent();
                } else {
                    if (APIResponse?.data?.validataion_error) {
                        toast.error(APIResponse?.data?.messages);
                        setServerError(APIResponse?.data?.messages);
                        setIsError(true);
                    } else {
                        toast.error("Something went wrong, please try again");
                        setIsError(true);
                    }
                }
            } else {
                postData.id = formData?._id;
                console.log("postData", postData);
                const APIResponse = await SendEmails(postData);
                if (APIResponse?.data?.isSuccess === true) {
                    toast.success(APIResponse?.data?.messages);
                    redirectToParent();
                } else {
                    if (APIResponse?.data?.validataion_error) {
                        toast.error(APIResponse?.data?.messages);
                        setServerError(APIResponse?.data?.messages);
                        setIsError(true);
                    } else {
                        toast.error("Something went wrong, please try again");
                        setIsError(true);
                    }
                }
            }
        } else {
            setIsValid(false);
            toast.error("Please enter required fields");
        }
    };

    const redirectToParent = () => {
        navigate("/emails-management/view-emails");
    };


    return (
        <Wrapper pageTitle="Backup Management">
            <Card title="Backup Management">
                <p className="text-left mb-4">
                    You can manage the Backup Management in this module.
                </p>
                <div className="">
                    {isError && (
                        <Alert AlertStyleType="danger" AlertText={serverError}></Alert>
                    )}
                    {!isValid && (
                        <Alert
                            AlertStyleType="danger"
                            AlertText={`Please enter required fields`}
                        ></Alert>
                    )}


                    <div className="row">
                        <div className="col-md-8">
                            {AllDownload?.map((item) => {
                                return <Checkboxes
                                    inputName={item?.text}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    options={[item]}
                                >
                                </Checkboxes>
                            })}
                            <hr />
                            {customPostType?.map((item) => {
                                return <Checkboxes
                                    inputName={item?.text}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    options={[item]}
                                >
                                </Checkboxes>
                            })}

                            <div className="row align-content-end justify-content-end">
                                <div className="col-sm-6 text-right">
                                    <Button
                                        onClick={() => redirectToParent()}
                                        buttonText={"Cancel"}
                                        buttonStyleType={"primary"}
                                    />
                                    <Button
                                        onClick={() => Submit("")}
                                        disabled={submitType === "view"}
                                        buttonText={
                                            submitType === "add" || submitType === "view"
                                                ? "Send Email"
                                                : "Send Email"
                                        }
                                        buttonStyleType={"primary"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">

                        </div>
                    </div>

                </div>
            </Card>
        </Wrapper>
    );
};
export default CreateEmails;

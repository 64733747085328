/* eslint-disable no-empty-pattern */
import { useState, useEffect } from "react";
import { Alert, Card, CustomTable } from "../../Library/Module";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import { CMSByType, CategoryBySubCategory, GetCMS, CMSByAuthor } from "../../servies/services";
import PetsRow from "./Row";
import { objectToQueryString } from "../../Library/Utility/Utility";



const ViewCMS = () => {
  const navigate = useNavigate();
  let { CMStype, CMSCategory, AllPages, posts } = useParams();
  const [isError, setIsError]: any = useState(false);
  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [isValid, setIsValid]: any = useState(true);
  const [tableData, setTableData] = useState<any>([]);
  const [showCells, setShowCells] = useState(null);
  const [sortState, setSortState] = useState({
    name: "id",
    isAsc: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [reloadAPI, setReloadAPI] = useState(false);
  // @ts-ignore
  const profile_details = JSON.parse(localStorage.getItem("profile_details")) || null;

  const tableConfig = {
    search: {
      name: "id",
      isAsc: false,
    },
    sortDefault: {
      name: "id",
      isAsc: false,
    },
    tableHeader: [
      {
        name: "title",
        display: "Title",
        sort: true,
      },
      {
        name: "category",
        display: "Category",
        sort: true,
      },
      {
        name: "status",
        display: "Status",
        sort: true,
      },
      ...(profile_details?.user_type === 'admin' ? [{
        name: "created_by_name",
        display: "Author",
        sort: true,
      }] : []),
      {
        name: "Action",
        display: "Action",
        sort: false,
      },
    ],
  };

  console.log("posts asdasdasd", posts)

  const showCellCallback = (data: any) => {
    setShowCells(data);
  };

  useEffect(() => {
    if (CMStype && CMStype.length > 0) {
      setIsLoading(false);
      setTableData([]);
      const getData = async () => {
        const apidata = await CMSByType(CMStype);
        const APIResponse = apidata?.data?.data;
        setTableData(APIResponse);
        setIsLoading(true);
        setReloadAPI(false);
      };
      getData();
    }
  }, [CMStype, reloadAPI]);

  useEffect(() => {
    if (CMSCategory && CMSCategory.length > 0) {
      setIsLoading(false);
      setTableData([]);
      const getData = async () => {
        const apidata = await CategoryBySubCategory(CMSCategory);
        const APIResponse = apidata?.data?.data;
        setTableData(APIResponse);
        setIsLoading(true);
        setReloadAPI(false);
      };
      getData();
    }
  }, [CMSCategory, reloadAPI]);


  useEffect(() => {
    if (posts && posts.length > 0) {
      setIsLoading(false);
      setTableData([]);
      if (posts === 'my-posts') {
        const getData = async () => {
          const params = {
            "created_by": profile_details?.email_id, 
            "type": 'blog' 
          }
          let apidata = await GetCMS(objectToQueryString(params));
          const APIResponse = apidata?.data?.data;
          setTableData(APIResponse);
          setIsLoading(true);
          setReloadAPI(false);
        };
        getData();
      } else {
        const getData = async () => {
          let apidata = await GetCMS();
          const APIResponse = apidata?.data?.data;
          setTableData(APIResponse);
          setIsLoading(true);
          setReloadAPI(false);
        };
        getData();
      }
    }
  }, [posts, profile_details?.email_id, reloadAPI]);

  // GetCMS 

  const breadcrumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Pets Management",
      link: "/dashboard",
    },
    {
      title: "View Pets",
      link: "/dashboard",
    },
  ];

  const reloadData = (data: any) => {
    console.log("reloadData", data);
    setReloadAPI(true);
  };

  return (
    <Wrapper pageTitle="CMS Management" breadcrumbList={breadcrumbList}>
      <Card>
        <div className="">
          {!isValid && (
            <Alert
              AlertStyleType="danger"
              AlertText={`Please enter required fields`}
            ></Alert>
          )}
          <div className="row">
            <div className="col-md-12">
              <CustomTable
                header={tableConfig.tableHeader}
                sortDefault={tableConfig.sortDefault}
                data={tableData}
                tableTitle={"My Posts"}
                tableSubTitle={'You can manage the Post data in this module.'}
                dropdown={""}
                search_bar={false}
                showCellCallback={showCellCallback}
                sortState={sortState}
                isLoading={isLoading}
              >
                {({ rowData, index }: { rowData: any; index: any }) => (
                  <PetsRow
                    rowData={rowData}
                    index={index}
                    showCells={showCells}
                    reloadData={reloadData}
                  ></PetsRow>
                )}
              </CustomTable>
            </div>
          </div>
        </div>
      </Card>
    </Wrapper>
  );
};

export default ViewCMS;

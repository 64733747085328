import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "../Library/Module";

import { toast } from "react-toastify";
import Logo from "../images/logo.png";
import CustomTooltip from "../Library/Tooltip/Tippy";
import { getUserRole } from "../Library/Utility/Utility";


const Header = () => {
  const [customPostTypeCat, setCustomPostTypeCat] = useState([])
  const navigate = useNavigate()
  // @ts-ignore
  const profile_details = JSON.parse(localStorage.getItem("profile_details"));
  const logout = () => {
    if (profile_details?.user_type === "admin") {
      navigate("/");
    } else {
      navigate("/");
    }
    toast.info("logout successfully");
    localStorage.removeItem("profile_details");
    localStorage.removeItem("user_details");

  };

  const profileUpdate = () => {
    navigate(`/user-management/edit-user/edit/${profile_details?._id}`);
  }

  const changeProfilePassword = () => {
    navigate(`/user-management/change-password/edit/${profile_details?._id}`);
  }

  console.log("profile_details", profile_details)

  useEffect(() => {
    if (!profile_details) {
      // toast.info("logout successfully");
      localStorage.clear();
      navigate("/");

    }
  }, [navigate, profile_details])

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-info">
      <div className="container-fluid">
        <div className="brand">
          <NavLink to={"/dashboard"}><img src={Logo} title="" alt="" /></NavLink>
        </div>
        <div className="" id="navbarSupportedContent">
          <ul className="navbar-nav float-left">
            <li className="nav-item" data-user-type={profile_details?.user_type}>
              <NavLink to={"/dashboard"}>
                <span className="material-symbols-outlined">
                  dashboard
                </span>
                Dashboard</NavLink>
            </li>
          </ul>
          {/* 
            {customPostTypeCat?.map((item: any) => {
              return (
                <li className="nav-item">
                  <NavLink to={`/view-cms/cms-category/${item?.slug}`}>{item?.title}</NavLink>
                </li>
              )
            })}
            <li className="nav-item">
              <NavLink to={"/create-cms/add"}>Add Pages</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={"/view-cms/all-pages/all-pages"}>Pages</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={"/vendor-management"}>Vendor Management</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={"/vendor-management/add-vendor/add"}>Quick Add Vendor</NavLink>
            </li>
          </ul> */}
          {/* {profile_details?.first_name === 'Balaji' && <div className="user_info_text float-left">Welcome {profile_details?.first_name} /  {profile_details?.user_type}</div>} */}
          <div className=" float-left">
            {/* <ul>
              <li>
                <Button onClick={() => profileUpdate()} buttonStyleType="link">
                  Update Profile
                </Button>
                <ul>
                  <li><Button onClick={() => profileUpdate()} buttonStyleType="link">
                   
                  </Button></li>
                </ul>
              </li>
            </ul> */}

            <ul className="has-dropdown">
              <li className="white p-2">
                <CustomTooltip
                  title={"title"}
                  position={"bottom"}
                  content={`User Type - ${getUserRole(profile_details?.user_type)}`}>
                  {profile_details?.first_name ? profile_details?.first_name : 'A'} {profile_details?.last_name ? profile_details?.last_name : 'B'}
                </CustomTooltip>

              </li>
              <li>

                <div className="user_info">

                  {profile_details?.first_name ? profile_details?.first_name?.slice(0, 1) : 'A'}
                  {profile_details?.last_name ? profile_details?.last_name?.slice(0, 1) : 'B'}
                </div>
                <ul className="dropdown">
                  <li><Button onClick={() => changeProfilePassword()} buttonStyleType="link">
                    Change Password
                  </Button></li>
                  <li><Button onClick={() => profileUpdate()} buttonStyleType="link">
                    Update Profile
                  </Button></li>
                </ul>
              </li>
            </ul>

          </div>
          <ul className="navbar-nav float-left">
            <li className="nav-item mt-1">
              <Button onClick={() => logout()} buttonStyleType="danger">
                Logout
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Header;

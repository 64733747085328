/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import { Alert, Card, Input, Button, Select, DatePickerComp } from "../../Library/Module";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import {
    CreateCategory, CategoryById, UpdateCategory, GetCustomPostType, GetEvents, AddConfRoom, UpdateConfRoom, AddEvents, UpdateEvents, SendEmails
} from "../../servies/services";
import MediaPopup from "../Media/MediaPopup";
import { objectToQueryString } from "../../Library/Utility/Utility";

type formDataProps = {
    email: string;
    subject: string;
    _id?: string
};

const CreateEmails = () => {
    const navigate = useNavigate();
    let { id, type } = useParams();

    const [isError, setIsError]: any = useState(false);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [isLoading, setIsLoading]: any = useState(true);

    const [isValid, setIsValid]: any = useState(true);
    const [formData, setFormData] = useState<formDataProps>();
    const [submitType, setSubmitType] = useState(type);
    const [customPostType, setCustomPostType] = useState([]);

    const [serverError, setServerError]: any = useState(
        "Something went wrong, please try again"
    );

    // @ts-ignore
    const profile_details = JSON.parse(localStorage.getItem("profile_details"));

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    const Submit = async (event: any) => {
        setIsFormSubmitted(true);
        if (
            formData?.email !== undefined &&
            formData?.email.length !== 0 &&
            formData?.subject !== undefined &&
            formData?.subject.length !== 0
        ) {
            setIsValid(true);
            let postData = {
                email: formData?.email,
                subject: formData?.subject,
                id: id
            };
            if (submitType === "add") {
                delete postData.id;
                const APIResponse = await SendEmails(postData);
                if (APIResponse?.data?.isSuccess === true) {
                    toast.success(APIResponse?.data?.messages);
                    redirectToParent();
                } else {
                    if (APIResponse?.data?.validataion_error) {
                        toast.error(APIResponse?.data?.messages);
                        setServerError(APIResponse?.data?.messages);
                        setIsError(true);
                    } else {
                        toast.error("Something went wrong, please try again");
                        setIsError(true);
                    }
                }
            } else {
                postData.id = formData?._id;
                console.log("postData", postData);
                const APIResponse = await SendEmails(postData);
                if (APIResponse?.data?.isSuccess === true) {
                    toast.success(APIResponse?.data?.messages);
                    redirectToParent();
                } else {
                    if (APIResponse?.data?.validataion_error) {
                        toast.error(APIResponse?.data?.messages);
                        setServerError(APIResponse?.data?.messages);
                        setIsError(true);
                    } else {
                        toast.error("Something went wrong, please try again");
                        setIsError(true);
                    }
                }
            }
        } else {
            setIsValid(false);
            toast.error("Please enter required fields");
        }
    };

    const redirectToParent = () => {
        navigate("/emails-management/view-emails");
    };

    const breadcrumbList = [
        {
            title: "Dashboard",
            link: "/dashboard",
        },
        {
            title: "City Management",
            link: "/pets-management/view-pets",
        },
        {
            title: "Add City",
            link: "/pets-management/view-pets",
        },
    ];

    return (
        <Wrapper pageTitle="CMS Management" breadcrumbList={breadcrumbList}>
            <Card title="Send Email">
                <p className="text-left mb-4">
                    You can manage the Send Email in this module.
                </p>
                <div className="">
                    {isError && (
                        <Alert AlertStyleType="danger" AlertText={serverError}></Alert>
                    )}
                    {!isValid && (
                        <Alert
                            AlertStyleType="danger"
                            AlertText={`Please enter required fields`}
                        ></Alert>
                    )}

                    {isLoading && (
                        <div className="row">
                            <div className="col-md-8">
                                <Input
                                    inputName="email"
                                    inputType="email"
                                    labelName={"Email"}
                                    placeholder="Enter email"
                                    required={true}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    isFormSubmitted={isFormSubmitted}
                                    disabled={submitType === "view"}
                                    value={
                                        type === "add" && formData?.email.length === 0
                                            ? ""
                                            : formData?.email
                                    }
                                />
                                <Input
                                    inputName="subject"
                                    inputType="text"
                                    labelName={"Subject"}
                                    placeholder="Enter subject"
                                    required={true}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    isFormSubmitted={isFormSubmitted}
                                    disabled={submitType === "view"}
                                    value={
                                        type === "add" && formData?.subject.length === 0
                                            ? ""
                                            : formData?.subject
                                    }
                                />

                                <div className="row align-content-end justify-content-end">
                                    <div className="col-sm-6 text-right">
                                        <Button
                                            onClick={() => redirectToParent()}
                                            buttonText={"Cancel"}
                                            buttonStyleType={"primary"}
                                        />
                                        <Button
                                            onClick={() => Submit("")}
                                            disabled={submitType === "view"}
                                            buttonText={
                                                submitType === "add" || submitType === "view"
                                                    ? "Send Email"
                                                    : "Send Email"
                                            }
                                            buttonStyleType={"primary"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">

                            </div>
                        </div>
                    )}
                </div>
            </Card>
        </Wrapper>
    );
};
export default CreateEmails;

/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import { Alert, Card, Input, Button, Select, DatePickerComp } from "../../Library/Module";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import {
    CreateCategory, CategoryById, UpdateCategory, GetCustomPostType, GetEvents, AddConfRoom, UpdateConfRoom, AddEvents, UpdateEvents
} from "../../servies/services";
import MediaPopup from "../Media/MediaPopup";
import { objectToQueryString } from "../../Library/Utility/Utility";

type formDataProps = {
    title: string;
    status: string;
    image?: string;
    description?: string;
    _id?: string;
    document_title?: string;
    meta_tag?: string;
    meta_description?: string;
    type?: string
    id?: string
    date?: string

};

const statusOption = [
    { text: "Active", id: 1, value: "1" },
    { text: "Inactive", id: 2, value: "2" },
];

const meetingRoomName = [
    { text: "Birthday", id: 1, value: "Birthday" },
    { text: "Holiday", id: 2, value: "Holiday" }
];

const AddCity = () => {
    const navigate = useNavigate();
    let { id, type } = useParams();

    const [isError, setIsError]: any = useState(false);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [isLoading, setIsLoading]: any = useState(false);

    const [isValid, setIsValid]: any = useState(true);
    const [formData, setFormData] = useState<formDataProps>();
    const [submitType, setSubmitType] = useState(type);
    const [customPostType, setCustomPostType] = useState([]);

    const [serverError, setServerError]: any = useState(
        "Something went wrong, please try again"
    );

    // @ts-ignore
    const profile_details = JSON.parse(localStorage.getItem("profile_details"));

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    useEffect(() => {
        setIsLoading(false);
        if (type === "edit" || type === "view") {
            // setSubmitType("edit");
            const getData = async () => {
                const query = {
                    _id: id
                }
                const APIResponse = await GetEvents(objectToQueryString(query));
                if (APIResponse?.data?.isSuccess === true) {
                    setFormData(APIResponse?.data?.data?.[0]);
                    setIsLoading(true);
                } else {
                    toast.error("Something went wrong, please try again");
                    setIsError(true);
                    setIsLoading(true);
                }
            };
            getData();
        } else {
            // setSubmitType("edit");
            setFormData({
                title: "",
                status: "",
                image: "",
                description: "",
                _id: "",
            });
            setIsLoading(true);
        }
    }, [type]);

    const Submit = async (event: any) => {
        setIsFormSubmitted(true);
        if (
            formData?.title !== undefined &&
            formData?.title.length !== 0 &&
            formData?.status !== undefined &&
            formData?.status.length !== 0
        ) {
            setIsValid(true);
            let postData = {
                title: formData?.title,
                description: formData?.description,
                type: "Holiday",
                date: formData?.date,
                status: formData?.status,
                created_by: profile_details?.email_id,
                id: id
            };
            if (submitType === "add") {
                delete postData.id;
                const APIResponse = await AddEvents(postData);
                if (APIResponse?.data?.isSuccess === true) {
                    toast.success(APIResponse?.data?.messages);
                    redirectToParent();
                } else {
                    if (APIResponse?.data?.validataion_error) {
                        toast.error(APIResponse?.data?.messages);
                        setServerError(APIResponse?.data?.messages);
                        setIsError(true);
                    } else {
                        toast.error("Something went wrong, please try again");
                        setIsError(true);
                    }
                }
            } else {
                postData.id = formData?._id;
                console.log("postData", postData);
                const APIResponse = await UpdateEvents(postData);
                if (APIResponse?.data?.isSuccess === true) {
                    toast.success(APIResponse?.data?.messages);
                    redirectToParent();
                } else {
                    if (APIResponse?.data?.validataion_error) {
                        toast.error(APIResponse?.data?.messages);
                        setServerError(APIResponse?.data?.messages);
                        setIsError(true);
                    } else {
                        toast.error("Something went wrong, please try again");
                        setIsError(true);
                    }
                }
            }
        } else {
            setIsValid(false);
            toast.error("Please enter required fields");
        }
    };

    const redirectToParent = () => {
        navigate("/events-management/view-rooms");
    };

    const breadcrumbList = [
        {
            title: "Dashboard",
            link: "/dashboard",
        },
        {
            title: "City Management",
            link: "/pets-management/view-pets",
        },
        {
            title: "Add City",
            link: "/pets-management/view-pets",
        },
    ];

    return (
        <Wrapper pageTitle="CMS Management" breadcrumbList={breadcrumbList}>
            <Card title="Add Holiday">
                <p className="text-left mb-4">
                    You can manage the Holiday in this module.
                </p>
                <div className="">
                    {isError && (
                        <Alert AlertStyleType="danger" AlertText={serverError}></Alert>
                    )}
                    {!isValid && (
                        <Alert
                            AlertStyleType="danger"
                            AlertText={`Please enter required fields`}
                        ></Alert>
                    )}

                    {isLoading && (
                        <div className="row">
                           

                            <div className="col-md-8">
                                <Input
                                    inputName="title"
                                    inputType="text"
                                    labelName={"Title"}
                                    placeholder="Enter title"
                                    required={true}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    isFormSubmitted={isFormSubmitted}
                                    disabled={submitType === "view"}
                                    value={
                                        type === "add" && formData?.title.length === 0
                                            ? ""
                                            : formData?.title
                                    }
                                />

                                <DatePickerComp
                                    inputName="date"
                                    labelName={"Date"}
                                    placeholder="Select Date"
                                    required={true}
                                    disabled={type === "view"}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"></DatePickerComp>

                                {/* <Select
                                    inputName="type"
                                    labelName="Event type"
                                    options={meetingRoomName}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    selectedItem={meetingRoomName.find(
                                        (item) => item.value === formData?.type
                                    )}
                                    required={true}
                                    placeholder="Select event type"
                                    search_option={false}
                                    disabled={submitType === "view"}
                                    isLoading={true}
                                ></Select> */}

                                <Input
                                    inputName="description"
                                    inputType="text"
                                    labelName={"Description"}
                                    placeholder="Description"
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    isFormSubmitted={isFormSubmitted}
                                    value={formData?.description}
                                    disabled={submitType === "view"}
                                />

                                <Select
                                    inputName="status"
                                    labelName="Status"
                                    options={statusOption}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    selectedItem={statusOption.find(
                                        (item) => item.value === formData?.status
                                    )}
                                    required={true}
                                    placeholder="Select status"
                                    search_option={false}
                                    disabled={submitType === "view"}
                                    isLoading={true}
                                ></Select>
                                <div className="row align-content-end justify-content-end">
                                    <div className="col-sm-6 text-right">
                                        <Button
                                            onClick={() => redirectToParent()}
                                            buttonText={"Cancel"}
                                            buttonStyleType={"primary"}
                                        />
                                        <Button
                                            onClick={() => Submit("")}
                                            disabled={submitType === "view"}
                                            buttonText={
                                                submitType === "add" || submitType === "view"
                                                    ? "Submit"
                                                    : "Update"
                                            }
                                            buttonStyleType={"primary"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">

                            </div>
                        </div>
                    )}
                </div>
            </Card>
        </Wrapper>
    );
};
export default AddCity;

/* eslint-disable no-empty-pattern */
import { useState, useEffect } from "react";
import { Alert, Card, CustomTable } from "../../../Library/Module";
import { useNavigate } from "react-router-dom";
import Wrapper from "../../Wrapper";
import { GetCustomPostType } from "../../../servies/services";
import PetsRow from "./Row";

const tableConfig = {
  search: {
    name: "id",
    isAsc: false,
  },
  sortDefault: {
    name: "id",
    isAsc: false,
  },
  tableHeader: [
    {
      name: "_id",
      display: "Id",
      sort: true,
      sortType: "number",
    },
    {
      name: "title",
      display: "Title",
      sort: true,
    },
    {
      name: "status",
      display: "Status",
      sort: true,
    }
  ],
};

const AddCity = () => {
  const navigate = useNavigate();
  const [isError, setIsError]: any = useState(false);
  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [isValid, setIsValid]: any = useState(true);
  const [tableData, setTableData] = useState<any>([]);
  const [showCells, setShowCells] = useState(null);
  const [sortState, setSortState] = useState(tableConfig.sortDefault);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadAPI, setReloadAPI] = useState(false);

  const showCellCallback = (data: any) => {
    setShowCells(data);
  };

  useEffect(() => {
    setIsLoading(false);
    setTableData([]);
    const getData = async () => {
      const apidata = await GetCustomPostType();
      const APIResponse = apidata?.data?.data;
      setTableData(APIResponse);
      setIsLoading(true);
      setReloadAPI(false);
    };
    getData();
  }, [reloadAPI]);

  const breadcrumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Custom Post Type Management",
      link: "/custom-post-type/view-post-type",
    },
    {
      title: "View Custom Post Type",
      link: "/custom-post-type/view-post-type",
    },
  ];
  const reloadData = (data: any) => {
    console.log("reloadData", data);
    setReloadAPI(true);
  };

  return (
    <Wrapper pageTitle="CMS Management" breadcrumbList={breadcrumbList}>
      <Card>
        <div className="">
          {!isValid && (
            <Alert
              AlertStyleType="danger"
              AlertText={`Please enter required fields`}
            ></Alert>
          )}
          <div className="row">
            <div className="col-md-12">
              <CustomTable
                header={tableConfig.tableHeader}
                sortDefault={tableConfig.sortDefault}
                data={tableData}
                tableTitle={"Custom Post Type Management"}
                tableSubTitle='You can manage the pets data in this module.'
                dropdown={""}
                search_bar={false}
                showCellCallback={showCellCallback}
                sortState={sortState}
                isLoading={isLoading}
              >
                {({ rowData, index }: { rowData: any; index: any }) => (
                  <PetsRow
                    rowData={rowData}
                    index={index}
                    showCells={showCells}
                    reloadData={reloadData}
                  ></PetsRow>
                )}
              </CustomTable>
            </div>
          </div>
        </div>
      </Card>
    </Wrapper>
  );
};
export default AddCity;

/* eslint-disable no-empty-pattern */
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Alert, Card, Input, Button } from "../../Library/Module";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";
import { GetComment, GetLikes } from "../../servies/services";
import moment from "moment";
import { objectToQueryString, plural } from "../../Library/Utility/Utility";


type formDataProps = {
    comments?: string;

};
type InputProps = {
    postId: string
    postName: string
    commentCount: any
}
const Comments = ({
    postId,
    postName,
    commentCount
}: InputProps): JSX.Element => {
    const navigate = useNavigate();
    const [isError, setIsError]: any = useState(false);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [isValid, setIsValid]: any = useState(true);
    const [formData, setFormData] = useState<formDataProps>();
    const [totalLikes, setTotalLikes] = useState<any>();

    // @ts-ignore
    const profile_details = JSON.parse(localStorage.getItem("profile_details"));

    const [tableCatData, setTableCatData] = useState<any>([]);
    const [tableCatDataUser, setTableCatDataUser] = useState<any>([]);


    const [isCatLoading, setIsCatLoading] = useState(false);
    const [reloadAPI, setReloadAPI] = useState(false);

    const queryParams = new URLSearchParams(window.location.search);
    const email_token = queryParams.get("email_token");

    const [token, setToken] = useState();

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    const Submit = async (liked: any) => {
        console.log("liked", liked)
        if (liked === "Post") {
            setIsFormSubmitted(true);
            setIsValid(true);
            const postData = {
                "user_id": profile_details?._id,
                "user_name": profile_details?.first_name + ' ' + profile_details?.last_name,
                "user_email": profile_details?.email_id,
                "like": "Yes",
                "post_id": postId,
                "post_name": postName,
                "organization_id": "1",
                "status": "1",
                "total_likes": totalLikes

            };
            await axios
                .post(`https://tlhintranet.com/nodeapi/api/v1/likes`, postData)
                .then((response) => {
                    console.log("response", response);
                    // toast.success("Comment has been added succesfully");
                    getDataTotalCount();
                    getDataByUserId()
                    setFormData({})
                })
                .catch(function (error) {
                    toast.error("Something went wrong, please try again");
                    setIsError(true);
                    console.log("error.response", error.response);
                });
        } else {
            setIsFormSubmitted(true);
            setIsValid(true);
            const postData = {
                "user_id": profile_details?._id,
                "user_name": profile_details?.first_name + ' ' + profile_details?.last_name,
                "user_email": profile_details?.email_id,
                "like": liked,
                "post_id": postId,
                "post_name": postName,
                "organization_id": "1",
                "status": "1",
                "id": tableCatDataUser[0]?._id,
                "total_likes": totalLikes
            };
            await axios
                .patch(`https://tlhintranet.com/nodeapi/api/v1/likes`, postData)
                .then((response) => {
                    console.log("response", response);
                    // toast.success("Comment has been added succesfully");
                    getDataTotalCount();
                    getDataByUserId()
                    setFormData({})
                })
                .catch(function (error) {
                    toast.error("Something went wrong, please try again");
                    setIsError(true);
                    console.log("error.response", error.response);
                });
        }
    };

    const getDataTotalCount = useCallback(async () => {
        const params = {
            "post_id": postId,
            "like": 'Yes',
        }
        let apidata = await GetLikes(objectToQueryString(params));
        const APIResponse = apidata?.data?.data;
        setTableCatData(APIResponse);
        setTotalLikes(APIResponse?.length)

    }, [postId]);

    const getDataByUserId = useCallback(async () => {
        const params = {
            "post_id": postId,
            "user_id": profile_details?._id
        }
        let apidata = await GetLikes(objectToQueryString(params));
        const APIResponse = apidata?.data?.data;
        setTableCatDataUser(APIResponse);
    }, [postId, profile_details?._id]);

    useEffect(() => {
        getDataTotalCount();
    }, [postId]);

    useEffect(() => {
        getDataByUserId();
    }, [postId]);

    return (
        <>
            <span className="outlineSpan" onClick={() => Submit(tableCatDataUser?.length === 0 ? 'Post' : tableCatDataUser[0]?.like === 'Yes' ? "No" : 'Yes')}>
                {tableCatDataUser?.length === 0 ? 'Like' : tableCatDataUser[0]?.like === 'Yes' ? <span className="material-symbols-outlined">
                    thumb_up
                </span> : <span className="material-symbols-outlined">
                    thumb_up
                </span>}
                {`${tableCatData.length} ${tableCatDataUser?.length === 0 ? 'Like' : tableCatDataUser[0]?.like === 'Yes' ? plural('Like', tableCatData.length ?? 0) : plural('Like', tableCatData.length ?? 0)}`}
            </span>
            <span className="outlineSpan">
                {commentCount} {plural('Comment', commentCount ?? 0)}
            </span>
        </>
    );
};
export default Comments;

import React from "react";
import "react-responsive-modal/styles.css";
import { GetMedia, CreateMedia } from "../../servies/services";
import "react-lazy-load-image-component/src/effects/blur.css";

import PropTypes from "prop-types";
//  FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { Alert, Card, Input, Button, Select } from "../../Library/Module";
import { toast } from "react-toastify";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div p={3}>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

class MediaPopup extends React.PureComponent {
  state = {
    tableData: [],
    open: false,
    files: [],
    loading: false,
    profile_details: JSON.parse(localStorage.getItem("profile_details")),
    theme: {
      direction: "x-reverse",
    },
    value: 0,
  };




  handleInit() {
    console.log("FilePond instance has initialised", this.pond);
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  onOpenModal = () => {
    this.setState({ open: true });
    this.getAllCMS();
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  getAllCMS = async () => {
    this.setState({ loading: true });
    const APIResponse = await GetMedia();
    console.log("GetCustomType MediaPopup", APIResponse);
    if (APIResponse !== null) {
      const filteredDates = APIResponse.data.data.sort(
        (a, b) => new Date(b.created_on) - new Date(a.created_on)
      );
      console.log("filteredDates", filteredDates);
      this.setState({ tableData: filteredDates, loading: true });
      // this.setState({ tableData: APIResponse.data.data, loading: true })
    }
  };

  selectFile = async (path) => {
    // alert(path)
    console.log("path", path);
    this.props.onSelectChange(path);
    this.setState({ open: false });
  };

  add = async () => {
    const formData = new FormData();
    formData.append("email_id", this.state.profile_details?.email_id);
    formData.append("type", this.props.type);

    this.pond
      .getFiles()
      .map((fileItem) => fileItem.file)
      .forEach((file) => {
        formData.append("file", file, file.name);

      });

    console.log(JSON.stringify(formData));
    const APIResponse = await CreateMedia(formData);
    console.log("CreateMedia", APIResponse);
    if (APIResponse !== null) {
      if (APIResponse.data.isSuccess !== false) {
        const message = APIResponse.data.messages;

        toast.info(message);

        this.setState({ value: 0, files: [] });
        this.props.clickHandler(true)
      } else {
        const message = APIResponse.data.messages;
        toast.error(message);
      }
    } else {
      let message = "something went wrong, please try again";
      toast.error(message);
    }
  };

  render() {
    const { open } = this.state;
    return (
      <>
        <div className="row">
          <div className="col-md-12 mb-3">
            <FilePond
              allowMultiple={true}
              ref={(ref) => (this.pond = ref)}
              oninit={() => this.handleInit()}
              files={this.state.files}
              onupdatefiles={(fileItems) => {
                this.setState({
                  files: fileItems.map((fileItem) => fileItem.file),
                });
              }}
              name={"file"}
              labelIdle='<b>Drag your documents here or <span class="filepond--label-action">Browse</span></b>'
            />
          </div>

          <div className="clearfix"></div>
          <div className="col-md-12 mb-3">
            <Button
              buttonStyleType={"primary"}
              onClick={this.add}
            >
              Submit
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default MediaPopup;

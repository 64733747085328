import react, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { Button, Alert } from "../../Library/Module";
import { DeleteCityAreaService, DeleteComment, DeleteEmails } from "../../servies/services";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import CustomTooltip from "../../Library/Tooltip/Tippy";
import { trimString } from "../../Library/Utility/Utility";

interface AlertProps {
    rowData?: any;
    index?: any;
    showCells?: any;
    reloadData?: any;
}

const ConferenceRow = ({
    rowData,
    index,
    showCells,
    reloadData,
}: AlertProps): JSX.Element => {

    const DeleteCity = () => {
        confirmAlert({
            title: `Are you sure you want to delete this?`,
            message: `This Item will be deleted immediately. You can't undo this action.`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => DeleteCityAPI(),
                },
                {
                    label: "No",
                    onClick: () => "",
                },
            ],
        });
    };

    const DeleteCityAPI = async () => {
        const APIResponse = await DeleteEmails(rowData._id, rowData.post_id);
        if (APIResponse?.data?.isSuccess === true) {
            toast.success(APIResponse?.data?.messages);
            reloadData(true);
        } else {
            toast.error("Something went wrong, please try again");
        }
    };

    return (
        <tr key={index}>
            <td>{rowData.email_id}</td>
            <td><CustomTooltip
                title={"title"}
                position={"bottom"}
                disabled={false}
                content={rowData?.subject}>
                {trimString(rowData?.subject, 50)}
            </CustomTooltip></td>
            <td>{rowData.type === 'manual_email' ? 'Manual Email' : 'System Email'}</td>
            <td>{rowData?.scount}</td>
            <td>{rowData?.fcount}</td>
            <td>{rowData?.status === 'success' ?
                <span className="material-symbols-outlined">
                    check_circle
                </span>
                : <span className="material-symbols-outlined">
                    error
                </span>}</td>
            <td><CustomTooltip
                title={"title"}
                position={"bottom"}
                disabled={false}
                width="400px"
                content={
                    <div className="overScroll">
                        {Object.keys(rowData?.message).map((key, i) => {
                            return (
                                <p key={i}>
                                    <label><span>{key}</span></label>
                                    <span>{JSON.stringify(rowData?.message[key])}</span>
                                </p>
                            )
                        })}</div>}>
                <Button
                    buttonStyleOutline
                    buttonStyleType="primary"
                >
                    <span className="material-symbols-outlined">
                        info
                    </span>
                </Button>
            </CustomTooltip></td>
            <td>{moment(rowData?.created_on).format('DD/MM/YYYY')}</td>
            <td><Button
                onClick={() => DeleteCity()}
                buttonStyleOutline
                buttonStyleType="danger"
            >
                <span className="material-symbols-outlined">
                    delete
                </span>
            </Button></td>
        </tr>
    );
};

export default ConferenceRow;

/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import { Alert, Card, Input, Button, Select } from "../../../Library/Module";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../../Wrapper";
import {
  CreateSubCategory, CategorySubById, UpdateSubCategory, GetCustomPostType, CategoryByCMSType
} from "../../../servies/services";
import MediaPopup from "../../Media/MediaPopup";

type formDataProps = {
  title: string;
  status: string;
  image?: string;
  description?: string;
  short_description?: string;
  _id?: string;
  document_title?: string;
  meta_tag?: string;
  meta_description?: string;
  type?: string
  category?: string

};

const statusOption = [
  { text: "Active", id: 1, value: "1" },
  { text: "Inactive", id: 2, value: "2" },
];

const CreateTypeSubCategory = () => {
  const navigate = useNavigate();
  let { id, type } = useParams();

  const [isError, setIsError]: any = useState(false);
  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [isLoading, setIsLoading]: any = useState(false);

  const [isValid, setIsValid]: any = useState(true);
  const [formData, setFormData] = useState<formDataProps>();
  const [submitType, setSubmitType] = useState(type);
  const [customPostType, setCustomPostType] = useState([]);
  const [customPostCategory, setCustomPostCategory] = useState([]);


  const [serverError, setServerError]: any = useState(
    "Something went wrong, please try again"
  );

  const onChangeSingleCallback = (data: any) => {
    setFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
    setIsFormSubmitted(false);
  };

  useEffect(() => {
    const getData = async () => {
      const APIResponse = await GetCustomPostType();
      if (APIResponse?.data?.isSuccess === true) {
        let updatedList: any = []
        APIResponse?.data?.data?.map((item: any) => {
          return updatedList.push({ text: item?.title, id: item?._id, value: item?.slug })
        })
        setCustomPostType(updatedList);
      } else {
        toast.error("Something went wrong, please try again");
        setIsError(true);
      }
    };
    getData();
  }, [])


  useEffect(() => {
    if (formData?.type) {
      const getData = async () => {
        const APIResponse = await CategoryByCMSType(formData?.type);
        if (APIResponse?.data?.isSuccess === true) {
          let updatedList: any = []
          APIResponse?.data?.data?.map((item: any) => {
            return updatedList.push({ text: item?.title, id: item?._id, value: item?.slug })
          })
          setCustomPostCategory(updatedList);
        } else {
          toast.error("Something went wrong, please try again");
          setIsError(true);
        }
      };
      getData();
    }
  }, [formData?.type])

  useEffect(() => {
    setIsLoading(false);

    if (type === "edit" || type === "view") {
      // setSubmitType("edit");
      const getData = async () => {
        const APIResponse = await CategorySubById(id);
        if (APIResponse?.data?.isSuccess === true) {
          setFormData(APIResponse?.data?.data);
          setIsLoading(true);
        } else {
          toast.error("Something went wrong, please try again");
          setIsError(true);
          setIsLoading(true);
        }
      };
      getData();
    } else {
      // setSubmitType("edit");
      setFormData({
        title: "",
        status: "",
        image: "",
        description: "",
        short_description: "",
        _id: "",
      });
      setIsLoading(true);
    }
  }, [type]);

  const Submit = async (event: any) => {
    setIsFormSubmitted(true);
    if (
      formData?.title !== undefined &&
      formData?.title.length !== 0 &&
      formData?.status !== undefined &&
      formData?.status.length !== 0
    ) {
      setIsValid(true);
      let postData = {
        title: formData?.title,
        status: formData?.status,
        image: formData?.image,
        type: formData?.type,
        description: formData?.description,
        short_description: formData?.short_description,
        meta_tag: formData?.meta_tag,
        meta_description: formData?.meta_description,
        document_title: formData?.document_title,
        category: formData?.category,
        id: formData?._id,
        created_by: "sdasd",
        organization_id: "sdasd"
      };
      if (submitType === "add") {
        delete postData.id;
        const APIResponse = await CreateSubCategory(postData);
        if (APIResponse?.data?.isSuccess === true) {
          toast.success(APIResponse?.data?.messages);
          redirectToParent();
        } else {
          if (APIResponse?.data?.validataion_error) {
            toast.error(APIResponse?.data?.messages);
            setServerError(APIResponse?.data?.messages);
            setIsError(true);
          } else {
            toast.error("Something went wrong, please try again");
            setIsError(true);
          }
        }
      } else {
        postData.id = formData?._id;
        console.log("postData", postData);
        const APIResponse = await UpdateSubCategory(postData);
        if (APIResponse?.data?.isSuccess === true) {
          toast.success(APIResponse?.data?.messages);
          redirectToParent();
        } else {
          if (APIResponse?.data?.validataion_error) {
            toast.error(APIResponse?.data?.messages);
            setServerError(APIResponse?.data?.messages);
            setIsError(true);
          } else {
            toast.error("Something went wrong, please try again");
            setIsError(true);
          }
        }
      }
    } else {
      setIsValid(false);
      toast.error("Please enter required fields");
    }
  };

  const redirectToParent = () => {
    navigate("/custom-post-type/view-post-sub-category");
  };

  const breadcrumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "City Management",
      link: "/pets-management/view-pets",
    },
    {
      title: "Add City",
      link: "/pets-management/view-pets",
    },
  ];

  return (
    <Wrapper pageTitle="CMS Management" breadcrumbList={breadcrumbList}>
      <Card title="Sub Category Management">
        <p className="text-left mb-4">
          You can manage the Sub Category Management data in this module.
        </p>
        <div className="">
          {isError && (
            <Alert AlertStyleType="danger" AlertText={serverError}></Alert>
          )}
          {!isValid && (
            <Alert
              AlertStyleType="danger"
              AlertText={`Please enter required fields`}
            ></Alert>
          )}

          {isLoading && (
            <div className="row">

              <div className="col-md-8">
                <Input
                  inputName="title"
                  inputType="text"
                  labelName={"Title"}
                  placeholder="Enter title"
                  required={true}
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                  disabled={submitType === "view"}
                  value={
                    type === "add" && formData?.title.length === 0
                      ? ""
                      : formData?.title
                  }
                />
                <Input
                  inputName="short_description"
                  inputType="text"
                  labelName={"Short Description"}
                  placeholder="Enter Short Description"
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                  value={formData?.short_description}
                  disabled={submitType === "view"}
                />
                <Input
                  inputName="description"
                  inputType="text"
                  labelName={"Description"}
                  placeholder="Description"
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                  value={formData?.description}
                  disabled={submitType === "view"}
                />
                <Select
                  inputName="type"
                  labelName="type"
                  options={customPostType?.length > 0 ? customPostType : []}
                  onChangeSingleCallback={onChangeSingleCallback}
                  selectedItem={customPostType && customPostType?.find(
                    (item: any) => item?.value === formData?.type
                  )}
                  required={true}
                  placeholder="select type"
                  search_option={false}
                  disabled={submitType === "view"}
                ></Select>

                <Select
                  inputName="category"
                  labelName="Category"
                  options={customPostCategory?.length > 0 ? customPostCategory : []}
                  onChangeSingleCallback={onChangeSingleCallback}
                  selectedItem={customPostCategory && customPostCategory?.find(
                    (item: any) => item?.value === formData?.category
                  )}
                  required={true}
                  placeholder="select type"
                  search_option={false}
                  disabled={submitType === "view"}
                ></Select>

                <Select
                  inputName="status"
                  labelName="Status"
                  options={statusOption}
                  onChangeSingleCallback={onChangeSingleCallback}
                  selectedItem={statusOption.find(
                    (item) => item.value === formData?.status
                  )}
                  required={true}
                  placeholder="Select Status"
                  search_option={false}
                  disabled={submitType === "view"}
                ></Select>



                <Input
                  inputName="meta_tag"
                  inputType="text"
                  labelName={"Meta Tag"}
                  placeholder="Enter Meta Tag"
                  required={true}
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                  disabled={submitType === "view"}
                  value={
                    type === "add" && formData?.meta_tag?.length === 0
                      ? ""
                      : formData?.meta_tag
                  }
                />

                <Input
                  inputName="meta_description"
                  inputType="text"
                  labelName={"Meta Description"}
                  placeholder="Enter Meta Description"
                  required={true}
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                  disabled={submitType === "view"}
                  value={
                    type === "add" && formData?.meta_description?.length === 0
                      ? ""
                      : formData?.meta_description
                  }
                />


                <Input
                  inputName="document_title"
                  inputType="text"
                  labelName={"Document Title"}
                  placeholder="Enter Document Title"
                  required={true}
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                  disabled={submitType === "view"}
                  value={
                    type === "add" && formData?.document_title?.length === 0
                      ? ""
                      : formData?.document_title
                  }
                />

              </div>
              <div className="col-md-4">
                <div className="row align-content-end justify-content-end">
                <div className="col-sm-12 text-left">
                    <MediaPopup
                      onSelectChange={onChangeSingleCallback}
                      disabled={submitType === "view"}
                      title={
                        formData?.image !== null && formData?.image !== "null"
                          ? "Change Image"
                          : "Select Image"
                      }
                      inputName="image"
                    />

                    {formData?.image && formData?.image.length > 0 && <img src={process.env.react_app_base_url + `/${formData?.image}`} alt="" title="" />}
                  </div>
                  <div className="col-sm-6 text-right">
                    <Button
                      onClick={() => redirectToParent()}
                      buttonText={"Cancel"}
                      buttonStyleType={"primary"}
                    />
                  </div>
                  <div className="col-sm-6 text-right">
                    <Button
                      onClick={() => Submit("")}
                      disabled={submitType === "view"}
                      buttonText={
                        submitType === "add" || submitType === "view"
                          ? "Submit"
                          : "Update"
                      }
                      buttonStyleType={"primary"}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
    </Wrapper>
  );
};
export default CreateTypeSubCategory;

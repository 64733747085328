import react, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { Button, Alert } from "../../../Library/Module";
import { DeleteCategory } from "../../../servies/services";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { replaceWithDash } from "../../../Library/Utility/Utility";

interface AlertProps {
  rowData?: any;
  index?: any;
  showCells?: any;
  reloadData?: any;
}

const TableRow = ({
  rowData,
  index,
  showCells,
  reloadData,
}: AlertProps): JSX.Element => {
  const navigate = useNavigate();

  const editPage = () => {
    navigate(`/custom-post-type/edit-custom-category/edit/${rowData._id}`);
  };

  const viewPage = () => {
    navigate(`/custom-post-type/edit-custom-category/view/${rowData._id}`);
  };

  const DeleteCity = () => {
    confirmAlert({
      title: `Are you sure you want to delete this ${rowData.title}?`,
      message: `This Item will be deleted immediately. You can't undo this action.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteCityAPI(),
        },
        {
          label: "No",
          onClick: () => "",
        },
      ],
    });
  };

  const DeleteCityAPI = async () => {
    const APIResponse = await DeleteCategory(rowData._id);
    if (APIResponse?.data?.isSuccess === true) {
      toast.success(APIResponse?.data?.messages);
      reloadData(true);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  return (
    <tr key={index}>
      <td>{rowData.title}</td>
      <td className="changeCase">{replaceWithDash(rowData.type)}</td>
      <td>{rowData.status === '1' ? 'Active' : 'Inactive'}</td>
      <td style={{ width: "250px" }}>
        <Button
          onClick={() => DeleteCity()}
          buttonStyleOutline
          buttonStyleType="danger"
        >
          <span className="material-symbols-outlined">
                            delete
                        </span>
        </Button>

        <Button
          onClick={() => editPage()}
          buttonStyleOutline
          buttonStyleType="primary"
        >
         <span className="material-symbols-outlined">
            edit
          </span>
        </Button>

        <Button
          onClick={() => viewPage()}
          buttonStyleOutline
          buttonStyleType="primary"
        >
      <span className="material-symbols-outlined">
            visibility
          </span>
        </Button>
      </td>

    </tr>
  );
};

export default TableRow;

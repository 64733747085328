/* eslint-disable no-empty-pattern */
import { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import { Alert, Card, CustomTable } from "../../Library/Module";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Wrapper from "../Wrapper";
import { GetUsers } from "../../servies/services";
import RowUser from "./RowUser";
import moment from "moment";
import { UserDownloadKey } from "../../Functions/Common";
import { teamOptions, userOption } from "./AddUser";
import { objectLenght, objectToQueryString } from "../../Library/Utility/Utility";

const tableConfig = {
  search: {
    name: "id",
    isAsc: false,
  },
  sortDefault: {
    name: "id",
    isAsc: false,
  },
  tableHeader: [
    {
      name: "first_name",
      display: "Name",
      sort: true,
    },
    {
      name: "email_id",
      display: "Email ID",
      sort: true,
    },
    {
      name: "mobile",
      display: "mobile",
      sort: true,
    },
    {
      name: "user_type",
      display: "user_type",
      sort: true,
    },
    {
      name: "designation",
      display: "Designation",
      sort: true,
    },
    {
      name: "created_on",
      display: "Registered Date",
      sort: true,
    },
    {
      name: "Action",
      display: "Action",
      sort: false,
    },
  ],
};

const ViewUsers = () => {
  const navigate = useNavigate();
  const [isError, setIsError]: any = useState(false);
  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [isValid, setIsValid]: any = useState(true);
  const [tableData, setTableData] = useState<any>([]);
  const [showCells, setShowCells] = useState(null);
  const [sortState, setSortState] = useState(tableConfig.sortDefault);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadAPI, setReloadAPI] = useState(false);
  const [filterParam, setFilterParam] = useState<any>()
  const [designations, setDesignations] = useState<any>([])
  const [userRoles, setUserRoles] = useState<any>([])

  const showCellCallback = (data: any) => {
    setShowCells(data);
  };

  useEffect(() => {
    let designationsNew: any = [{ text: "All", id: 1, value: "All" },]
    designationsNew.push(...teamOptions)
    console.log("designationsNew", designationsNew)
    setDesignations(designationsNew)

    let userRolesNew: any = [{ text: "All", id: 1, value: "All" },]
    userRolesNew.push(...userOption)
    console.log("designationsNew", designationsNew)
    setUserRoles(userRolesNew)
  }, [])


  const useFilter = useMemo(() => {
    return [
      {
        "type": "dropdown",
        "label": "User Designation",
        "name": 'designation',
        "placeholder": "Select User Designation",
        "default": designations[0],
        "options": designations
      },
      {
        "type": "dropdown",
        "label": "User Type",
        "name": 'user_type',
        "placeholder": "Select User Type",
        "default": userRoles[0],
        "options": userRoles
      }
    ]
  }, [designations, userRoles])

  useEffect(() => {
    console.log("filterParam", filterParam)
    setIsLoading(false);
    setTableData([]);
    const getData = async () => {
      const query = {
        ...filterParam
      }
      const apidata = await GetUsers(objectToQueryString(query));
      const APIResponse = apidata?.data?.data;
      setTableData(APIResponse);
      setIsLoading(true);
      setReloadAPI(false);
    };
    getData();
  }, [filterParam, reloadAPI]);

  const breadcrumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "User Management",
      link: "/dashboard",
    },
    {
      title: "View User",
      link: "/dashboard",
    },
  ];
  const reloadData = (data: any) => {
    console.log("reloadData", data);
    setReloadAPI(true);
  };

  const filterData = useCallback((data) => {
    console.log("filterData useCallback", data)
    let newObj = {}
    for (const [key, value] of Object.entries(data)) {
      if (value !== 'All') {
        const source = { [key]: value }
        Object.assign(newObj, source)
      }
    }
    setFilterParam(newObj)
  }, [])


  return (
    <Wrapper pageTitle="Dashboard" breadcrumbList={breadcrumbList}>
      <Card>
        <div className="">
          {!isValid && (
            <Alert
              AlertStyleType="danger"
              AlertText={`Please enter required fields`}
            ></Alert>
          )}
          <div className="row">
            <div className="col-md-12">
              <CustomTable
                header={tableConfig.tableHeader}
                sortDefault={tableConfig.sortDefault}
                data={tableData}
                tableTitle={"User Management"}
                tableSubTitle='You can manage the User data in this module.'
                dropdown={""}
                search_bar={true}
                showCellCallback={showCellCallback}
                sortState={sortState}
                isLoading={isLoading}
                downloadKeys={UserDownloadKey}
                filter={useFilter}
                sidebarFilterData={filterData}
              >
                {({ rowData, index }: { rowData: any; index: any }) => (
                  <RowUser
                    rowData={rowData}
                    index={index}
                    showCells={showCells}
                    reloadData={reloadData}
                  ></RowUser>
                )}
              </CustomTable>
            </div>
          </div>
        </div>
      </Card>
    </Wrapper>
  );
};
export default ViewUsers;

/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import { Alert, Card, Input, Button } from "../../Library/Module";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import { GetSetting, AddSetting, UpdateSetting } from "../../servies/services";
import { objectToQueryString } from "../../Library/Utility/Utility";

type formDataProps = {
    lop_email_address: string
};

const LOPSettings = () => {
    const navigate = useNavigate();
    let { id, type } = useParams();
    const [leaveData, setLeaveData] = useState(0)
    const [isError, setIsError]: any = useState(false);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [isLoading, setIsLoading]: any = useState(false);
    const [tableDataPosts, setTableDataPosts] = useState<any>([]);

    const [isValid, setIsValid]: any = useState(true);
    const [formData, setFormData] = useState<formDataProps>();
    const [isPostType, setIsPostType]: any = useState(true);

    const [serverError, setServerError]: any = useState(
        "Something went wrong, please try again"
    );

    // @ts-ignore
    const profile_details = JSON.parse(localStorage.getItem("profile_details")) || null;

    const onChangeSingleCallback = (data: any) => {
        // console.log("data onChangeSingleCallback", data)
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    const Submit = async (event: any) => {
        setIsFormSubmitted(true);
        if (
            formData?.lop_email_address !== undefined &&
            formData?.lop_email_address.length !== 0
        ) {
            const data = {
                "name": 'lop_email_address',
                "value": formData?.lop_email_address,
                "type": "lop_email_address",
                "status": "1",
                "created_by": profile_details?.first_name + ' ' + profile_details?.last_name,
                "email_id": profile_details?.email_id,
                ...(isPostType ? { "id": tableDataPosts?._id } : {})
            }
            const res = isPostType ? UpdateSetting(data) : AddSetting(data)
            console.log("AddSetting", res)
            toast.info("Form Submitted successfully.");
        } else {
            setIsValid(false);
            toast.error("Please enter required fields");
        }
    };

    useEffect(() => {
        setIsLoading(false);
        setTableDataPosts({});
        const getData = async () => {
            const query = {
                type: 'lop_email_address'
            }
            let apidata = await GetSetting(objectToQueryString(query));
            const APIResponse = apidata?.data?.data?.[0];
            console.log("APIResponse", APIResponse)
            setTableDataPosts(APIResponse);
            setIsLoading(true);
            setIsPostType(!!APIResponse)
            const fData: any = {
                "lop_email_address": APIResponse?.value
            }
            setFormData(fData)
        };
        getData();
    }, []);

    return (
        <Wrapper pageTitle="Dashboard">
            <Card title="LOP Email Setting">
                <p className="text-left mb-4"> You can manage the LOP receiver email Id in this module.</p>
                <div className="">
                    {!isValid && (
                        <Alert
                            AlertStyleType="danger"
                            AlertText={`Please enter required fields`}
                        ></Alert>
                    )}
                    <div className="row">
                        <div className="col-md-8">
                            <Input
                                inputName="lop_email_address"
                                inputType="email"
                                labelName={"Email ID"}
                                placeholder="Enter email id"
                                required={true}
                                onChangeSingleCallback={onChangeSingleCallback}
                                col="12"
                                isFormSubmitted={isFormSubmitted}
                                disabled={type === "view"}
                                value={formData?.lop_email_address}
                                readonly={type === "edit"}
                            />
                            <div className="row">

                                <div className="col-sm-6 text-right"></div>
                                <div className="col-sm-6 text-right">
                                    <Button
                                        onClick={() => Submit("")}
                                        disabled={type === "view"}
                                        buttonText={
                                            !isPostType
                                                ? "Submit"
                                                : "Update"
                                        }
                                        buttonStyleType={"primary"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Card>
        </Wrapper>
    );
};
export default LOPSettings;

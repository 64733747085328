/* eslint-disable no-empty-pattern */
import { useState, useEffect } from "react";
import { Alert, Card, CustomTable } from "../../../Library/Module";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../../Wrapper";
import { GetSubCategory, DeleteSubCategory, CategoryBySubCategory } from "../../../servies/services";
import PetsRow from "./Row";

const tableConfig = {
  search: {
    name: "id",
    isAsc: false,
  },
  sortDefault: {
    name: "id",
    isAsc: false,
  },
  tableHeader: [
    {
      name: "_id",
      display: "Id",
      sort: true,
      sortType: "number",
    },
    {
      name: "title",
      display: "Title",
      sort: true,
    },
    {
      name: "type",
      display: "Type",
      sort: true,
    },

    {
      name: "category",
      display: "Category",
      sort: true,
    },


    {
      name: "status",
      display: "Status",
      sort: true,
    },
    // {
    //   name: "created_on",
    //   display: "Created Date",
    //   sort: true,
    // },
    // {
    //   name: "updated_on",
    //   display: "updated_on",
    //   sort: true,
    // },
  ],
};

const ViewTypeSubCategory = () => {
  const navigate = useNavigate();
  let { CMStype, CMSCategory, AllPages, } = useParams();
  const [isError, setIsError]: any = useState(false);
  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [isValid, setIsValid]: any = useState(true);
  const [tableData, setTableData] = useState<any>([]);
  const [showCells, setShowCells] = useState(null);
  const [sortState, setSortState] = useState(tableConfig.sortDefault);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadAPI, setReloadAPI] = useState(false);

  const showCellCallback = (data: any) => {
    setShowCells(data);
  };

  useEffect(() => {
    if (CMSCategory && CMSCategory.length > 0) {
      setIsLoading(false);
      setTableData([]);
      const getData = async () => {
        const apidata = await CategoryBySubCategory(CMSCategory);
        const APIResponse = apidata?.data?.data;
        setTableData(APIResponse);
        setIsLoading(true);
        setReloadAPI(false);
      };
      getData();
    } else {
      setIsLoading(false);
      setTableData([]);
      const getData = async () => {
        const apidata = await GetSubCategory();
        const APIResponse = apidata?.data?.data;
        setTableData(APIResponse);
        setIsLoading(true);
        setReloadAPI(false);
      };
      getData();
    }
  }, [CMSCategory, reloadAPI]);

  const breadcrumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Pets Management",
      link: "/dashboard",
    },
    {
      title: "View Pets",
      link: "/dashboard",
    },
  ];
  const reloadData = (data: any) => {
    console.log("reloadData", data);
    setReloadAPI(true);
  };

  return (
    <Wrapper pageTitle="CMS Management" breadcrumbList={breadcrumbList}>
      <Card>
        <div className="">
          {!isValid && (
            <Alert
              AlertStyleType="danger"
              AlertText={`Please enter required fields`}
            ></Alert>
          )}
          <div className="row">
            <div className="col-md-12">
              <CustomTable
                header={tableConfig.tableHeader}
                sortDefault={tableConfig.sortDefault}
                data={tableData}
                tableTitle={"Custom Category Management"}
                tableSubTitle='You can manage the Custom Category Management data in this module.'
                dropdown={""}
                search_bar={false}
                showCellCallback={showCellCallback}
                sortState={sortState}
                isLoading={isLoading}
              >
                {({ rowData, index }: { rowData: any; index: any }) => (
                  <PetsRow
                    rowData={rowData}
                    index={index}
                    showCells={showCells}
                    reloadData={reloadData}
                  ></PetsRow>
                )}
              </CustomTable>
            </div>
          </div>
        </div>
      </Card>
    </Wrapper>
  );
};
export default ViewTypeSubCategory;

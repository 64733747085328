import react, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { Button, Alert } from "../../../Library/Module";
import { DeleteCustomPostType } from "../../../servies/services";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

interface AlertProps {
  rowData?: any;
  index?: any;
  showCells?: any;
  reloadData?: any;
}

const TableRow = ({
  rowData,
  index,
  showCells,
  reloadData,
}: AlertProps): JSX.Element => {
  const navigate = useNavigate();

  const editPage = () => {
    navigate(`/custom-post-type/edit-custom-post/edit/${rowData._id}`);
  };

  const viewPage = () => {
    navigate(`/custom-post-type/edit-custom-post/view/${rowData._id}`);
  };

  const DeleteCity = () => {
    confirmAlert({
      title: `Are you sure you want to delete this ${rowData.title}?`,
      message: `This Item will be deleted immediately. You can't undo this action.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteCityAPI(),
        },
        {
          label: "No",
          onClick: () => "",
        },
      ],
    });
  };

  const DeleteCityAPI = async () => {
    const APIResponse = await DeleteCustomPostType(rowData._id);
    if (APIResponse?.data?.isSuccess === true) {
      toast.success(APIResponse?.data?.messages);
      reloadData(true);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  return (
    <tr key={index}>
      {showCells && showCells?._id && <td>{index + 1}</td>}
      {showCells && showCells?.title && <td>{rowData.title}</td>}
      {/* {showCells && showCells?.slug && <td>{rowData.slug}</td>} */}
      {showCells && showCells?.status && <td>{rowData.status}</td>}
      {/* {showCells && showCells?.created_on && (
        <td>{moment(rowData.created_on).format("DD/MM/YYYY")}</td>
      )}
      {showCells && showCells?.updated_on && (
        <td>{moment(rowData.updated_on).format("DD/MM/YYYY")}</td>
      )} */}
      {showCells && showCells?._id && (
        <td style={{ width: "250px" }}>
          <Button
            onClick={() => DeleteCity()}
            buttonStyleOutline
            buttonStyleType="danger"
          >
            Delete
          </Button>

          <Button
            onClick={() => editPage()}
            buttonStyleOutline
            buttonStyleType="primary"
          >
            Edit
          </Button>

          <Button
            onClick={() => viewPage()}
            buttonStyleOutline
            buttonStyleType="primary"
          >
            View
          </Button>
        </td>
      )}
    </tr>
  );
};

export default TableRow;

/* eslint-disable no-empty-pattern */
import { useState, useEffect } from "react";
import { Alert, Button, Card, CustomTable, Input } from "../../Library/Module";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import { CMSByType, CategoryBySubCategory, GetCMS, CMSById, GetCategory } from "../../servies/services";
import Loader from "../../Library/Loader/Loader";
import { replaceWithDash } from "../../Library/Utility/Utility";
import Comments from "./Comments";
import LikePost from "./LikePost";
import ViewedPost from "./ViewedPost";

const tableConfig = {
    search: {
        name: "id",
        isAsc: false,
    },
    sortDefault: {
        name: "id",
        isAsc: false,
    },
    tableHeader: [
        {
            name: "_id",
            display: "Id",
            sort: true,
            sortType: "number",
        },
        {
            name: "title",
            display: "Title",
            sort: true,
        },
        {
            name: "type",
            display: "Type",
            sort: true,
        },

        {
            name: "category",
            display: "Category",
            sort: true,
        },


        {
            name: "status",
            display: "Status",
            sort: true,
        },

    ],
};

const ViewPosts = () => {
    const navigate = useNavigate();
    let { CMStype, CMSCategory, byId, } = useParams();
    const [isError, setIsError]: any = useState(false);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [isValid, setIsValid]: any = useState(true);
    const [tableData, setTableData] = useState<any>({});
    const [showCells, setShowCells] = useState(null);
    const [sortState, setSortState] = useState(tableConfig.sortDefault);
    const [isLoading, setIsLoading] = useState(false);
    const [reloadAPI, setReloadAPI] = useState(false);
    console.log("CMSCategory", CMSCategory)

    const [tableCatData, setTableCatData] = useState<any>([]);
    const [isCatLoading, setIsCatLoading] = useState(false);
    const [commentCount, setCommentCount] = useState(0);


    const showCellCallback = (data: any) => {
        setShowCells(data);
    };

    useEffect(() => {
        if (CMStype && CMStype.length > 0) {
            setIsLoading(false);
            setTableData({});
            const getData = async () => {
                const apidata = await CMSByType(CMStype);
                const APIResponse = apidata?.data?.data;
                setTableData(APIResponse);
                setIsLoading(true);
                setReloadAPI(false);
            };
            getData();
        }
    }, [CMStype, reloadAPI]);

    useEffect(() => {
        if (CMSCategory && CMSCategory.length > 0) {
            setIsLoading(false);
            setTableData([]);
            const getData = async () => {
                const apidata = await CategoryBySubCategory(CMSCategory);
                const APIResponse = apidata?.data?.data;
                setTableData(APIResponse);
                setIsLoading(true);
                setReloadAPI(false);
            };
            getData();
        }
    }, [CMSCategory, reloadAPI]);


    useEffect(() => {

        setIsLoading(false);
        setTableData([]);
        const getData = async () => {
            const apidata = await CMSById(byId);
            const APIResponse = apidata?.data?.data;
            setTableData(APIResponse);
            setIsLoading(true);
            setReloadAPI(false);
        };
        getData();

    }, [byId, reloadAPI]);

    // GetCMS 

    const breadcrumbList = [
        {
            title: "Dashboard",
            link: "/dashboard",
        },
        {
            title: "Pets Management",
            link: "/dashboard",
        },
        {
            title: "View Pets",
            link: "/dashboard",
        },
    ];
    const reloadData = (data: any) => {
        console.log("reloadData", data);
        setReloadAPI(true);
    };

    useEffect(() => {
        setIsCatLoading(false);
        setTableCatData([]);
        const getData = async () => {
            const apidata = await GetCategory();
            const APIResponse = apidata?.data?.data;
            setTableCatData(APIResponse);
            setIsCatLoading(true);
            setReloadAPI(false);
        };
        getData();

    }, []);

    return (
        <Wrapper pageTitle="CMS Management" breadcrumbList={breadcrumbList}>
            <Card>
                <div className="">
                    {!isValid && (
                        <Alert
                            AlertStyleType="danger"
                            AlertText={`Please enter required fields`}
                        ></Alert>
                    )}
                    <div className="row postManagement">

                        <div className="col-md-9">
                            {!isLoading && <Loader loaderType="FullLoader"></Loader>}
                            {isLoading &&
                                <>
                                    <h3>{tableData?.title} </h3>
                                    <div className="listPost">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="imagePlaceHolder">
                                                    <img src={process.env.react_app_base_url + "/" + tableData?.image} title="" alt="" />
                                                </div>
                                                <p className="meta">Date {tableData?.publish_date}  || Category - <NavLink className={'changeCase'} to={`/posts-management/by/category/${tableData?.category}`}>{replaceWithDash(tableData?.category)}</NavLink>  || Author - <NavLink to={`/posts-management/by/author/${tableData?.created_by}/${tableData?.created_by_name}`}>{tableData?.created_by_name}</NavLink></p>
                                                <div dangerouslySetInnerHTML={{ __html: tableData?.description }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {isLoading &&
                                <>
                                    <div className="likeCommentBox mb-3">
                                        <LikePost
                                            postId={tableData?._id}
                                            postName={tableData?.title}
                                            commentCount={commentCount}
                                        ></LikePost>
                                        {/* <ViewedPost
                                            postId={tableData?._id}
                                            postName={tableData?.title}
                                            commentCount={commentCount}
                                        ></ViewedPost> */}
                                    </div>
                                    <Comments
                                        postId={tableData?._id}
                                        postName={tableData?.title}
                                        commentCount={setCommentCount}
                                    ></Comments>
                                </>
                            }
                        </div>

                        <div className="col-md-3">
                            <div className="postsSearch">
                                <Input placeholder="Search blog" inputName="search"></Input>
                                <div className="widget">
                                    <h4>Category</h4>
                                    <ul>
                                        {isCatLoading && tableCatData?.map((item: any) => {
                                            return (
                                                <li> <NavLink to={`/posts-management/by/category/${item?.slug}`}>{item?.title}</NavLink></li>
                                            )
                                        })}
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </Wrapper>
    );
};
export default ViewPosts;

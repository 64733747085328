import { Button } from "../../Library/Module";
import { DeleteCMS } from "../../servies/services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { replaceWithDash, trimString } from "../../Library/Utility/Utility";
import moment from "moment";
import CustomTooltip from "../../Library/Tooltip/Tippy";

interface AlertProps {
    rowData?: any;
    index?: any;
    showCells?: any;
    reloadData?: any;
}

const TableRow = ({
    rowData,
    index,
    showCells,
    reloadData,
}: AlertProps): JSX.Element => {
    const navigate = useNavigate();

    const viewPage = () => {
        navigate(`/posts-management/view-all-posts/${rowData._id}`);

    };

    return (
        <tr key={index}>
            <td className="changeCase"><CustomTooltip
                    title={"title"}
                    position={"bottom"}
                    disabled={false}
                    content={rowData.title}>
                    {trimString(rowData.title, 50)}
                </CustomTooltip>
                <p className="small blue role_row changeCase">{replaceWithDash(rowData.category)}</p>
            </td>
            <td className="changeCase">{rowData?.comments}</td>
            <td className="changeCase">{rowData?.likes}</td>
            <td>{moment(rowData.created_on).format('DD/MM/YYYY')}</td>
        </tr>
    );
};

export default TableRow;

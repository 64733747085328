import React from "react";
import { useEffect, useState } from "react";

// import { NavLink, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import { Button } from "../../Library/Module";

//  FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import { GetMedia, CreateMedia } from "../../servies/services";
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import AddFiles from "./AddFiles";
import { objectToQueryString } from "../../Library/Utility/Utility";

const MediaPopup = ({
    onSelectChange,
    disabled,
    title,
    inputName,
}) => {
    // @ts-ignore
    const user_details = JSON.parse(localStorage.getItem("user_details"))
    const profile_details = JSON.parse(localStorage.getItem("profile_details"))
    const [tabChange, setTabChange] = useState('select_file')
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [tableData, setTableData] = useState([])
    const [files, setFiles] = useState([])
    const [reload, setReload] = useState(false)


    const changeTab = (data) => {
        setTabChange(data)
    }

    const handleInit = () => {
        console.log("FilePond instance has initialised", this.pond);
    }

    const onOpenModal = () => {
        setOpen(true)

    };

    const onCloseModal = () => {
        setOpen(false)
    };




    const getAllCMS = async () => {
        setLoading(false)
        const param = {
            email_id: profile_details?.email_id,
            type: 'profile'
        }
        const APIResponse = await GetMedia(objectToQueryString(param));
        console.log("APIResponse", APIResponse)
        if (APIResponse !== null) {
            const filteredDates = APIResponse.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
            setTableData(filteredDates)
            setLoading(true)
        }
    }

    useEffect(() => {
        if (open) {
            getAllCMS()
        }
    }, [open])

    useEffect(() => {
        if (reload) {
            getAllCMS()
            setReload(false)
            setTabChange('select_file')
        }
    }, [reload])

    const selectFile = async (path) => {
        console.log("path", path)
        const data = {
            [inputName]: path
        }
        onSelectChange(data);
        setOpen(false)
    }


    // const add = async () => {


    //     const formData = new FormData();
    //     this.pond.getFiles()
    //         .map(fileItem => fileItem.file)
    //         .forEach(file => {
    //             formData.append("file", file, file.name)
    //         });

    //     console.log(JSON.stringify(formData));
    //     const APIResponse = await CreateMedia(formData);
    //     console.log("CreateMedia", APIResponse);
    //     if (APIResponse !== null) {

    //         if (APIResponse.data.isSuccess !== false) {

    //             const message = APIResponse.data.messages;
    //             this.props.enqueueSnackbar(message, {
    //                 variant: 'info',
    //                 autoHideDuration: 3000,
    //             });

    //             this.setState({ value: 0, files: [] })
    //             this.getAllCMS();

    //         } else {
    //             const message = APIResponse.data.messages;
    //             this.props.enqueueSnackbar(message, {
    //                 variant: 'error',
    //                 autoHideDuration: 3000,
    //             });
    //         }

    //     } else {
    //         const message = "something went wrong, please try again";
    //         this.props.enqueueSnackbar(message, {
    //             variant: 'error',
    //             autoHideDuration: 3000,
    //         });
    //     }

    // }

    return (
        <div className="MediaPopup">
            <Button variant="outlined" size="size" className="display-block" disabled={disabled} buttonStyleType={"primary"} onClick={() => onOpenModal()}> {title} </Button>
            <Modal open={open} onClose={onCloseModal}>
                <div className="media_popup container-fluid">
                    <h5>Media Management</h5>
                    <div className={''}>
                        <div className="nav mb-3">
                            <Button onClick={() => changeTab('select_file')} buttonStyleType={tabChange === 'select_file' ? 'info' : 'primary'}>
                                Select Files
                            </Button>
                            <Button onClick={() => changeTab('upload_file')} buttonStyleType={tabChange === 'upload_file' ? 'info' : 'primary'}>
                                Upload Files
                            </Button>
                        </div>
                        <div className="imageWrapper">
                            {tabChange === 'select_file' ?
                                <>
                                    <div className="row" id="image_gallery">
                                        <React.Fragment>
                                            {loading && tableData.length !== 0 && tableData?.map((item) => {
                                                return (
                                                    <React.Fragment>
                                                        <div className="col-md-3 mb-3 text-center">
                                                            <div className="image_gallery_item" onClick={() => selectFile(item.path)}>
                                                                <LazyLoadImage
                                                                    alt={item.title}
                                                                    effect="blur"
                                                                    src={process.env.react_app_base_url + "/" + item.path}
                                                                    title={item.title} />
                                                                <p className="title">{item.title}</p>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            })
                                            }
                                        </React.Fragment>
                                    </div>
                                    <Button variant="outlined" size="size" className="float-right" color="secondary" onClick={onCloseModal}> Confirm </Button>
                                </>
                                :
                                <>
                                    <AddFiles type="profile" clickHandler={setReload}></AddFiles>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </Modal >
        </div >
    );
};
export default MediaPopup;
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Select } from "../../Library/Module";
import { objectToQueryString } from "../../Library/Utility/Utility";
import { GetLOPAssignedManagerByUserEmail, GetUsers } from "../../servies/services";

interface DashProps {
    title?: string
    submitType?: string
    dataCallback?: any
    type?: any
    userEmailId?: string
    label?: string
}

const LOPManagers = ({
    title,
    submitType,
    dataCallback,
    userEmailId,
    type,
    label

}: DashProps): JSX.Element => {

    const [formDataManager, setFormDataManager] = useState<any>([]);
    const [tableData, setTableData] = useState<any>([]);
    const [formData, setFormData] = useState<any>();
    const [isLoading, setIsLoading] = useState<any>(false);

    // @ts-ignore
    const profile_details = JSON.parse(localStorage.getItem("profile_details")) || null;

    const onChangeSingleCallback = (data: any) => {
        console.log("data", data)
        setFormDataManager((prevState: any) => ({
            ...prevState,
            ...data,
        }));
    };

    useEffect(() => {
        if (type !== 'add') {
            getManagersByUser(userEmailId)
            setIsLoading(true)
        } else {
            setIsLoading(true)
        }
    }, [type, userEmailId])

    const getManagersByUser = async (userEmailId: any) => {
        setIsLoading(false)
        const APIResponse = await GetLOPAssignedManagerByUserEmail(userEmailId);
        if (APIResponse?.data?.isSuccess === true) {
            const data = {
                managers: APIResponse?.data?.data
            }
            setFormDataManager(data);

            // console.log("APIResponse?.data?.data", APIResponse?.data?.data?.email_id)

        } else {
            toast.error("Something went wrong, please try again");
            setIsLoading(true)
        }
    };

    useEffect(() => {
        if (formDataManager) {
            dataCallback(formDataManager)
        }
    }, [formDataManager])

    useEffect(() => {
        setTableData([]);
        const getData = async () => {
            const apidata = await GetUsers();
            const APIResponse = apidata?.data?.data;
            let newData: any = []
            APIResponse.map((item: any) => {
                // console.log("item", item)
                return newData.push({
                    text: item?.first_name + " " + item?.last_name, id: 1, value: item?.email_id
                })
            })
            setTableData(newData);
        };
        getData();
    }, []);


    return (
        <div className="col-sm-12">
            {isLoading && <>
                <Select
                    inputName="managers"
                    labelName={label ?? 'Leave LOP Managers'}
                    options={tableData}
                    multiple
                    isLoading={isLoading}
                    onChangeSingleCallback={onChangeSingleCallback}
                    selectedItems={formDataManager?.managers}
                    required={true}
                    placeholder="Select LOP Managers"
                    search_option={false}
                    disabled={type === "view" || profile_details?.user_type !== 'admin' || userEmailId?.length === 0}
                ></Select>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Manager Email ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {formDataManager?.managers?.length !== 0 && formDataManager?.managers?.map((item: any) => {
                            return <tr>
                                <td className="changeCase">{item?.value}
                                    <p className="small blue role_row changeCase">{'Leave Approver'}</p>
                                </td>
                            </tr>
                        })}
                        {formDataManager?.managers?.length === 0 && <tr>
                            <td className="" colSpan={3}>
                                <div className="noDataFound">
                                    No Data Found
                                </div>

                            </td>
                        </tr>}
                    </tbody>
                </table>
            </>}

        </div>
    );
};

export default LOPManagers;

/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CustomTable } from "../../Library/Module";
import { GetCMS, GetComment, getMasterLeave, GetSingleBookAppointment, GetUsers } from "../../servies/services";
import Wrapper from "../Wrapper";

import Users from './Users';
import PostsRow from './PostsRow';
import { isFeatureActive } from "../../Library/ToggleFeature";
import { objectToQueryString } from "../../Library/Utility/Utility";

import LeavesRow from './Leaves'

const tableConfig = {
  search: {
    name: "id",
    isAsc: false,
  },
  sortDefault: {
    name: "id",
    isAsc: false,
  },
  tableHeader: [

    {
      name: "first_name",
      display: "Name",
      sort: true,
    },
    {
      name: "email_id",
      display: "email_id",
      sort: true,
    },
    {
      name: "created_on",
      display: "Date",
      sort: true,
    },

  ],
};

const tableConfigPosts = {
  search: {
    name: "id",
    isAsc: false,
  },
  sortDefault: {
    name: "id",
    isAsc: false,
  },
  tableHeader: [
    {
      name: "title",
      display: "Title",
      sort: false,
    },
    {
      name: "comments",
      display: "Comments",
      sort: false,
    },
    {
      name: "likes",
      display: "Likes",
      sort: false,
    },
    {
      name: "status",
      display: "Created On",
      sort: false,
    }
  ],
};

const tableConfigConf = {
  search: {
    name: "id",
    isAsc: false,
  },
  sortDefault: {
    name: "id",
    isAsc: false,
  },
  tableHeader: [
    {
      name: "meeting_title",
      display: "Title",
      sort: true,
    },
    {
      name: "start_date",
      display: "Start Date",
      sort: true,
    },
    {
      name: "end_date",
      display: "End Date",
      sort: true,
    }
  ],
};

const tableConfigComments = {
  search: {
    name: "id",
    isAsc: false,
  },
  sortDefault: {
    name: "id",
    isAsc: false,
  },
  tableHeader: [
    {
      name: "user_comments",
      display: "Comments",
      sort: true,
    },
    {
      name: "post_name",
      display: "Post",
      sort: true,
    },
    {
      name: "created_on",
      display: "Date",
      sort: true,
    }
  ],
};

const tableConfigLeave = {
  search: {
    name: "id",
    isAsc: false,
  },
  sortDefault: {
    name: "id",
    isAsc: false,
  },
  tableHeader: [
    {
      name: "leave_reason",
      display: "Leave Reason",
      sort: true,
    },
    {
      name: "start_date",
      display: "Start Date",
      sort: true,
    },
    {
      name: "end_date",
      display: "End Date",
      sort: true,
    },
    {
      name: "days",
      display: "Days",
      sort: true,
    },
    {
      name: "status",
      display: "status",
      sort: true,
    }
  ],
};

const Dashboard = () => {
  let navigate = useNavigate()
  const [isValid, setIsValid]: any = useState(true);
  const [tableData, setTableData] = useState<any>([]);
  const [showCells, setShowCells] = useState(null);
  const [sortState, setSortState] = useState(tableConfig.sortDefault);
  const [sortStatePosts, setSortStatePosts] = useState(tableConfigPosts.sortDefault);
  const [tableDataPosts, setTableDataPosts] = useState<any>([]);
  const [tableDataConf, setTableDataConf] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadAPI, setReloadAPI] = useState(false);
  const [tableDataComments, setTableDataComments] = useState<any>([]);
  const [tableDataLeaves, setTableDataLeaves] = useState<any>([]);


  // @ts-ignore
  const profile_details = JSON.parse(localStorage.getItem("profile_details")) || null;

  useEffect(() => {
    setIsLoading(false);
    setTableData([]);
    const getData = async () => {
      const query = {
        limit: 5
      }
      const apidata = await GetUsers(objectToQueryString(query));
      const APIResponse = apidata?.data?.data;
      setTableData(APIResponse);
      setIsLoading(true);
      setReloadAPI(false);
    };
    getData();
  }, [reloadAPI]);


  useEffect(() => {
    setIsLoading(false);
    setTableDataPosts([]);
    const getData = async () => {
      const query = {
        limit: 5,
        page: 0,
        status: '1',
        sortBy: 'created_on',
        type: 'blog'
      }
      let apidata = await GetCMS(objectToQueryString(query));
      const APIResponse = apidata?.data?.data;
      setTableDataPosts(APIResponse);
      setIsLoading(true);
      setReloadAPI(false);
    };
    getData();
  }, [profile_details?.email_id, reloadAPI]);

  // My Conference Room Booking
  useEffect(() => {
    setIsLoading(false);
    setTableDataConf([]);
    const getData = async () => {
      const query = {
        limit: 5,
        page: 0,
        status: '1',
        sortBy: 'start_date',
        user_email_id: profile_details?.email_id,
      }
      const apidata = await GetSingleBookAppointment(objectToQueryString(query));
      const APIResponse = apidata?.data?.data;
      setTableDataConf(APIResponse);
      setIsLoading(true);
      setReloadAPI(false);
    };
    getData();
  }, [profile_details?.email_id, reloadAPI]);


  // My Conference Room Booking
  useEffect(() => {
    setIsLoading(false);
    setTableDataComments([]);
    const getData = async () => {
      const query = {
        limit: 5,
        page: 0,
        // status: '1',
        // sortBy: 'start_date',
        user_email: profile_details?.email_id,
      }
      const apidata = await GetComment(objectToQueryString(query));
      const APIResponse = apidata?.data?.data;
      setTableDataComments(APIResponse);
      setIsLoading(true);
      setReloadAPI(false);
    };
    getData();
  }, [profile_details?.email_id, reloadAPI]);

  // My Leave

  useEffect(() => {
    setIsLoading(false);
    const getData = async () => {
      const query = {
        limit: 5,
        email_id: profile_details?.email_id,
      }
      const apidata = await getMasterLeave(objectToQueryString(query));
      const APIResponse = apidata?.data?.data;
      setTableDataLeaves(APIResponse);
      setIsLoading(true);
      setReloadAPI(false);
    };
    getData();
  }, [reloadAPI]);

  const breadcrumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
  ]

  const reloadData = () => {
    setReloadAPI(true);
  };
  const showCellCallback = (data: any) => {
    setShowCells(data);
  };
  return (
    <>
      <Wrapper pageTitle="Dashboard" breadcrumbList={breadcrumbList}>
        <div className="row">
          {isFeatureActive('DashboardLeaveModule') &&
            <div className="col-sm-12 col-md-6 mb-4">
              <Card type="widget"
                headerIcon={<span className="material-symbols-outlined">
                  calendar_month
                </span>}
                title="My Leaves"
                widgetBottom={<>
                  <Button
                    onClick={() => navigate('/leaves-management/view-leaves/users')}
                    buttonStyleType={"primary"}>
                    <span className="material-symbols-outlined">
                      list_alt
                    </span>
                    View All My Leaves
                  </Button>
                  <Button
                    onClick={() => navigate('/leaves-management/add-leaves/add')}
                    buttonStyleType={"primary"} >
                    <span className="material-symbols-outlined">
                      add_box
                    </span>

                    Apply Leave
                  </Button>
                </>}>
                <CustomTable
                  header={tableConfigLeave.tableHeader}
                  sortDefault={tableConfigLeave.sortDefault}
                  data={tableDataLeaves}
                  tableSubTitle={'You can manage the Leave data in this module.'}
                  dropdown={""}
                  search_bar={false}
                  showCellCallback={showCellCallback}
                  sortState={sortState}
                  isLoading={isLoading}
                  showHeader={false}
                >
                  {({ rowData, index }: { rowData: any; index: any }) => (
                    <LeavesRow
                      rowData={rowData}
                      index={index}
                      showCells={showCells}
                      reloadData={reloadData}
                    ></LeavesRow>
                  )}
                </CustomTable>

              </Card>
            </div>
          }

          {isFeatureActive('DashboardPostModule') &&
            <div className="col-sm-12 col-md-6 mb-4">
              <Card type="widget"
                title="Discussion Forum"
                headerIcon={<span className="material-symbols-outlined">
                  forum
                </span>}
                widgetBottom={<>
                  <Button
                    onClick={() => navigate('/posts-management/view-all-posts')}
                    buttonStyleType={"primary"}>
                    <span className="material-symbols-outlined">
                      list_alt
                    </span>
                    View All Posts
                  </Button>
                  <Button
                    onClick={() => navigate('/posts-management/add-posts/add')}
                    buttonStyleType={"primary"} >
                    <span className="material-symbols-outlined">
                      add_box
                    </span>
                    Add New Post
                  </Button>
                </>}>
                <CustomTable
                  header={tableConfigPosts.tableHeader}
                  sortDefault={tableConfigPosts.sortDefault}
                  data={tableDataPosts}
                  tableSubTitle={'You can manage the Post data in this module.'}
                  dropdown={""}
                  search_bar={false}
                  showCellCallback={showCellCallback}
                  sortState={sortStatePosts}
                  isLoading={isLoading}
                  showHeader={false}
                >
                  {({ rowData, index }: { rowData: any; index: any }) => (
                    <PostsRow
                      rowData={rowData}
                      index={index}
                      showCells={showCells}
                      reloadData={reloadData}
                    ></PostsRow>
                  )}
                </CustomTable>
              </Card>
            </div>
          }

          {isFeatureActive('DashboardUserModule') && profile_details?.user_type === 'admin' && <div className="col-sm-12 col-md-6 mb-4">
            <Card type="widget"
              title="Recent Users"
              headerIcon={<span className="material-symbols-outlined">
                person
              </span>}
              widgetBottom={<>
                <Button
                  onClick={() => navigate('/user-management/view-users')}
                  buttonStyleType={"primary"}    >
                  <span className="material-symbols-outlined">
                    list_alt
                  </span>
                  View Users
                </Button>
                <Button
                  onClick={() => navigate('/user-management/add-users/add')}
                  buttonStyleType={"primary"}
                >
                  <span className="material-symbols-outlined">
                    add_box
                  </span>
                  Add User
                </Button>
              </>}>
              <CustomTable
                header={tableConfig.tableHeader}
                sortDefault={tableConfig.sortDefault}
                data={tableData}
                tableSubTitle={'You can manage the Post data in this module.'}
                dropdown={""}
                search_bar={false}
                showCellCallback={showCellCallback}
                sortState={sortState}
                isLoading={isLoading}
                showHeader={false}
              >
                {({ rowData, index }: { rowData: any; index: any }) => (
                  <Users
                    rowData={rowData}
                    index={index}
                    showCells={showCells}
                    reloadData={reloadData}
                  ></Users>
                )}
              </CustomTable>
            </Card>
          </div>}

          {/* {isFeatureActive('DashboardAppointmentModule') &&
            <div className="col-sm-6 mb-4">
              <Card type="widget"
                title="My Conference Room Booking"
                headerIcon={ConfsWIcon}
                widgetBottom={<>
                  <Button
                    onClick={() => navigate('/conference-rooms-management/view-conference-rooms/my-bookings')}
                    buttonStyleType={"primary"}>
                    <img className="iconMain" src={LeaveWIcon} alt="" title="" />

                    My Bookings
                  </Button>
                  <Button
                    onClick={() => navigate('/conference-rooms-management/view-conference-rooms/add-booking/add')}
                    buttonStyleType={"primary"} >
                    <img className="iconMain" src={LeaveWIcon} alt="" title="" />
                    Book A Conference Room
                  </Button>
                </>}>
                <CustomTable
                  header={tableConfigConf.tableHeader}
                  sortDefault={tableConfigConf.sortDefault}
                  data={tableDataConf}
                  tableSubTitle={'You can manage the Post data in this module.'}
                  dropdown={""}
                  search_bar={false}
                  showCellCallback={showCellCallback}
                  sortState={sortState}
                  isLoading={isLoading}
                  showHeader={false}
                >
                  {({ rowData, index }: { rowData: any; index: any }) => (
                    <ConferenceRow
                      rowData={rowData}
                      index={index}
                      showCells={showCells}
                      reloadData={reloadData}
                    ></ConferenceRow>
                  )}
                </CustomTable>

              </Card>
            </div>
          } */}

          {/* {isFeatureActive('DashboardCommentsModule') &&
            <div className="col-sm-6 mb-4">
              <Card type="widget"
                title="My Comments"
                headerIcon={PostsWIcon}
                widgetBottom={<>
                  <Button
                    onClick={() => navigate('/conference-rooms-management/view-conference-rooms/add-booking/add')}
                    buttonStyleType={"primary"} >
                    <img className="iconMain" src={LeaveWIcon} alt="" title="" />
                    View My Comments
                  </Button>
                </>}>
                <CustomTable
                  header={tableConfigComments.tableHeader}
                  sortDefault={tableConfigComments.sortDefault}
                  data={tableDataComments}
                  tableSubTitle={'You can manage the Post data in this module.'}
                  dropdown={""}
                  search_bar={false}
                  showCellCallback={showCellCallback}
                  sortState={sortState}
                  isLoading={isLoading}
                  showHeader={false}
                >
                  {({ rowData, index }: { rowData: any; index: any }) => (
                    <CommentsRow
                      rowData={rowData}
                      index={index}
                      showCells={showCells}
                      reloadData={reloadData}
                    ></CommentsRow>
                  )}
                </CustomTable>
              </Card>
            </div>
          } */}
        </div>
      </Wrapper>
    </>
  );
};
export default Dashboard;

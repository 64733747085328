/* eslint-disable no-empty-pattern */
import { useState, useEffect } from "react";
import { Alert, Card } from "../../Library/Module";

import { useNavigate } from "react-router-dom";
import Wrapper from "../Wrapper";
import { GetMedia } from "../../servies/services";
import AddFiles from "./AddFiles";


const AddMediaFiles = () => {
  const navigate = useNavigate();
  const [isError, setIsError]: any = useState(false);

  const [isValid, setIsValid]: any = useState(true);
  const [tableData, setTableData] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [reloadAPI, setReloadAPI] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    setTableData([]);
    const getData = async () => {
      const apidata = await GetMedia();
      const APIResponse = apidata?.data?.data;
      setTableData(APIResponse);
      setIsLoading(true);
      setReloadAPI(false);
    };
    getData();
  }, [reloadAPI]);

  const breadcrumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "City Management",
      link: "/dashboard",
    },
    {
      title: "View City",
      link: "/dashboard",
    },
  ];
  const clickHandler = (data: any) => {
    console.log("data", data);
    navigate('/media-management/view-media')
  };
  return (
    <Wrapper pageTitle="Dashboard" breadcrumbList={breadcrumbList}>
      <Card title="Firm Document Management">
        <p className="text-left mb-4">
          You can upload the Firm Documents in this module.
        </p>
        <div className="">
          
          {!isValid && (
            <Alert
              AlertStyleType="danger"
              AlertText={`Please enter required fields`}
            ></Alert>
          )}
          <div className="row">
            <div className="col-md-12">
              <AddFiles type="firm_document" clickHandler={clickHandler}></AddFiles>
            </div>
          </div>
        </div>
      </Card>
    </Wrapper>
  );
};
export default AddMediaFiles;

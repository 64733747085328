import react, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { Button, Alert } from "../../Library/Module";
import { DeleteCityAreaService } from "../../servies/services";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

interface AlertProps {
  rowData?: any;
  index?: any;
  showCells?: any;
  reloadData?: any;
}

const LeavesRow = ({
  rowData,
  index,
  showCells,
  reloadData,
}: AlertProps): JSX.Element => {
  const navigate = useNavigate();

  const editPage = () => {

    navigate(`/city-area-management/edit-city-area/edit/${rowData._id}`);
  };

  const viewPage = () => {
    navigate(`/city-area-management/edit-city-area/view/${rowData._id}`);
  };

  const DeleteCity = () => {
    confirmAlert({
      title: `Are you sure you want to delete this ${rowData.title}?`,
      message: `This Item will be deleted immediately. You can't undo this action.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteCityAPI(),
        },
        {
          label: "No",
          onClick: () => "",
        },
      ],
    });
  };

  const DeleteCityAPI = async () => {
    const APIResponse = await DeleteCityAreaService(rowData._id);
    if (APIResponse?.data?.isSuccess === true) {
      toast.success(APIResponse?.data?.messages);
      reloadData(true);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  return (
    <tr key={index}>
      <td>{rowData.leave_reason}
        <p className="small blue role_row changeCase">{rowData.leave_type}</p>
      </td>
      <td>{moment(rowData.start_date).format('DD/MM/YYYY')}</td>
      <td>{moment(rowData.end_date).format('DD/MM/YYYY')}</td>
      <td>{rowData.days}</td>
      <td className="changeCase">{rowData.status}</td>
    </tr>
  );
};

export default LeavesRow;

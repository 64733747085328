/* eslint-disable no-empty-pattern */
import { useState } from "react";
import axios from "axios";
import { Alert, Card, Input, Button } from "../../Library/Module";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";

type formDataProps = {
  confirm_password: string;
  password: string;
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isError, setIsError]: any = useState(false);
  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [isValid, setIsValid]: any = useState(true);
  const [formData, setFormData] = useState<formDataProps>();

  const queryParams = new URLSearchParams(window.location.search);
  const email_token = queryParams.get("email_token");

  const [token, setToken] = useState();

  const onChangeSingleCallback = (data: any) => {
    setFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
    setIsFormSubmitted(false);
  };

  const Submit = async (event: any) => {
    setIsFormSubmitted(true);
    if (
      formData?.confirm_password !== undefined &&
      formData?.confirm_password.length !== 0 &&
      formData?.password !== undefined &&
      formData?.password.length !== 0
    ) {
      setIsValid(true);
      const postData = {
        user_token: email_token,
        password: formData?.password,
      };
      await axios
        .post(`https://tlhintranet.com/nodeapi/api/v1/user/changepassword`, postData)
        .then((response) => {
          console.log("response", response);
          toast.success(response.data.messages);
          ForgotPassword()
        })
        .catch(function (error) {
          toast.error("Something went wrong, please try again");
          setIsError(true);
          console.log("error.response", error.response);
        });
    } else {
      setIsValid(false);
      toast.error("Please enter required fields");
    }
  };

  const ForgotPassword = () => {
    navigate("/");
  };

  return (
    <div className="loginWrapper">
      <div className="loginBoxBg">
        <div className="login_admin">
          <Card>
            <div className="logoPlaceholder">
              <img src={Logo} alt="" title="" />
            </div>
            <h3>Forgot Your Password</h3>
            <p className="text-center mb-4">
              Enter password to continue to Tatva Legal Intranet
            </p>
            <div className="container-fluid">

              {!isValid && (
                <Alert
                  AlertStyleType="danger"
                  AlertText={`Please enter required fields`}
                ></Alert>
              )}
              <div className="row">
                <Input
                  inputName="password"
                  inputType="password"
                  labelName={"Password"}
                  placeholder="Enter password"
                  required={true}
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                />

                <Input
                  inputName="confirm_password"
                  inputType="password"
                  labelName={"Confirm password"}
                  placeholder="Enter confirm  password"
                  required={true}
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                />
              </div>
              <div className="row align-content-end justify-content-end">
                <div className="col-sm-6 text-left"></div>
                <div className="col-sm-6 text-right">
                  <Button
                    onClick={() => Submit("")}
                    buttonText={"Change Password"}
                    buttonStyleType={"primary"}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;

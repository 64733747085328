
export const UserDownloadKey = [
    {
        name: "first_name",
        display: "First name",

    },
    {
        name: "last_name",
        display: "Last Name",

    },
    {
        name: "mobile",
        display: "Mobile",

    },
    {
        name: "email_id",
        display: "Email Id",

    },
    {
        name: "designation",
        display: "Designation",
    },
    {
        name: "leaves",
        display: "Leaves",
    },
    {
        name: "dob",
        display: "DOB",
        type: 'date',
    },
    {
        name: "bio",
        display: "Bio",

    },
    {
        name: "interests",
        display: "Interests",

    },
    {
        name: "user_type",
        display: "User Type",

    },
    {
        name: "created_by",
        display: "Created By",

    }, {
        name: "updated_on",
        display: "Updated on",
        type: 'date',
    },
    {
        name: "created_on",
        display: "Registered Date",
        type: 'date',
    }
]

export const LeaveCalenderDownloadKey = [
    {
        name: "user_name",
        display: "Name",
    },
    {
        name: "email_id",
        display: "Email Id",
    },
    {
        name: "leave_reason",
        display: "Leave Reason",
    },
    {
        name: "leave_type",
        display: "Leave Type",
    },
    {
        name: "isPaidLeave",
        display: "Is Paid Leave",
    },
    {
        name: "isLeaveAppovalReq",
        display: "Is Leave Appoval Req",
    },
    {
        name: "leaveDay",
        display: "Leave Day",
    },
    {
        name: "start_date",
        display: "Start Date",
        type: "date"
    },
    {
        name: "end_date",
        display: "End Date",
        type: "date"
    },
    {
        name: "status",
        display: "Status",
    },
    {
        name: "days",
        display: "Days",
    },
    {
        name: "date",
        display: "Date",
    },
    {
        name: "created_by",
        display: "Created By",
    }, {
        name: "updated_on",
        display: "Updated on",
        type: 'date',
    },
    {
        name: "created_on",
        display: "Registered Date",
        type: 'date',
    }
]

export const LeaveTableDownloadKey = [
    {
        name: "user_name",
        display: "Name",
    },
    {
        name: "email_id",
        display: "Email Id",
    },
    {
        name: "leave_reason",
        display: "Leave Reason",
    },
    {
        name: "leave_type",
        display: "Leave Type",
    },
    {
        name: "isPaidLeave",
        display: "Is Paid Leave",
    },
    {
        name: "isLeaveAppovalReq",
        display: "Is Leave Appoval Req",
    },
    {
        name: "start_date",
        display: "Start Date",
        type: "date"
    },
    {
        name: "end_date",
        display: "End Date",
        type: "date"
    },
    {
        name: "status",
        display: "Status",
    },
    {
        name: "days",
        display: "Days",
    },
    {
        name: "created_by",
        display: "Created By",
    }, {
        name: "updated_on",
        display: "Updated on",
        type: 'date',
    },
    {
        name: "created_on",
        display: "Registered Date",
        type: 'date',
    }
]

export const MeetingTableDownloadKey = [
    {
        name: "user_name",
        display: "User Name",
    },
    {
        name: "meeting_title",
        display: "Meeting Title",
    },
    {
        name: "meeting_room_name",
        display: "Meeting Name",
    },
    {
        name: "meeting_type",
        display: "Meeting Type",
    },
    {
        name: "user_email_id",
        display: "User Email",
    },
    {
        name: "start_date",
        display: "Start Date",
        type: "date"
    },
    {
        name: "end_date",
        display: "End Date",
        type: "date"
    },
    {
        name: "start_time",
        display: "start_time",
        type: "time"
    },

    {
        name: "end_time",
        display: "end_time",
        type: "time"
    },
    {
        name: "created_by",
        display: "Created By",
    }, {
        name: "updated_on",
        display: "Updated on",
        type: 'date',
    },
    {
        name: "created_on",
        display: "Registered Date",
        type: 'date',
    }
]













export const HolidayDownloadKey = [
    {
        name: "created_by",
        display: "created_by",
    },
    {
        name: "created_on",
        display: "created_on",
        type: 'date',
    },
    {
        name: "date",
        display: "date",
        type: 'date',
    },
    {
        name: "description",
        display: "description",
    },
    {
        name: "slug",
        display: "slug",
    },
    {
        name: "status",
        display: "status",
    },
    {
        name: "title",
        display: "title",

    },
    {
        name: "type",
        display: "type",
    },
    {
        name: "updated_on",
        display: "updated_on",
        type: 'date',
    },
    {
        name: "_id",
        display: "_id",
    }
]
import React, { useEffect, useState } from "react"
const LeaveBox = ({
    userDetailsData,
    leaveData,
    title,
    isLoading,
    isOpenParent,
}: any) => {
    const isLOP = userDetailsData - leaveData
    const [isOpen, setIsOpen] = useState(isOpenParent)
    const onCliking = () => {
        setIsOpen(!isOpen)
    }
    useEffect(() => {
        setIsOpen(isOpenParent)
    }, [isOpenParent])

    return (
        <>
            <div className="box">
                <div className="header" onClick={() => onCliking()}>
                    <p><strong>{title}</strong></p>
                    <div className="icon"> <span className="material-symbols-outlined">{!isOpen ? 'add' : 'remove'}  </span></div>
                </div>
                {isOpen &&
                    <div className="innerBox">
                        
                        <div className="row align-items-center">
                            <div className="col-md-6"><p>Total Leaves</p></div>
                            <div className="col-md-6"><h4>{!isLoading ? '-' : userDetailsData ?? 0}</h4></div>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-md-6"><p>Approved Leaves</p></div>
                            <div className="col-md-6"><h4>{!isLoading ? '-' : leaveData}</h4></div>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-md-6"><p>Remaining Leaves</p></div>
                            <div className="col-md-6"><h4>{!isLoading ? '-' : isLOP > 0 ? isLOP : '0'}</h4></div>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-md-6"><p>LOP</p></div>
                            <div className="col-md-6"><h4>{!isLoading ? '-' : isLOP > 0 ? 0 : Math.abs(isLOP)}</h4></div>
                        </div>

                    </div>
                }
            </div>
        </>
    )
}
export default LeaveBox
import react, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { Button, Alert } from "../../Library/Module";
import { DeleteMedia, DeletePetsService } from "../../servies/services";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

interface AlertProps {
  rowData?: any;
  index?: any;
  showCells?: any;
  reloadData?: any;
  callbackId?: any;
}

const TableRow = ({
  rowData,
  index,
  showCells,
  reloadData,
  callbackId
}: AlertProps): JSX.Element => {
  const navigate = useNavigate();

  // @ts-ignore
  const profile_details = JSON.parse(localStorage.getItem("profile_details")) || null;

  const editPage = () => {
    navigate(`/pets-management/edit-pets/edit/${rowData._id}`);
  };

  const viewPage = () => {
    navigate(`/pets-management/edit-pets/view/${rowData._id}`);
  };

  const DeleteCity = () => {
    confirmAlert({
      title: `Are you sure you want to delete this ${rowData.title}?`,
      message: `This Item will be deleted immediately. You can't undo this action.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteCityAPI(),
        },
        {
          label: "No",
          onClick: () => "",
        },
      ],
    });
  };

  const DeleteCityAPI = async () => {
    const APIResponse = await DeleteMedia(rowData._id, rowData.path);
    if (APIResponse?.data?.isSuccess === true) {
      toast.success(APIResponse?.data?.messages);
      reloadData(true);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const filesExtention = ["jpg", "jpeg", "jfif", "pjpeg", "pjp", "png", "gif"]
  const FileNameExtention = rowData.path?.split('.').pop();

  const showSchedule = () => {
    let dataOrigin = {
      type: "add",
      id: 'id',
      mainType: 'schedule',
      data: rowData
    }
    callbackId(dataOrigin)
  }

  const download = (fileURL: any) => {

    fetch(fileURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${rowData.title}`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
      });

  }

  return (
    <tr key={index}>
      <td>
        <div className="imgPlaceholder">
          <span className="img" style={{ marginRight: '10px' }}>

            {filesExtention.includes(FileNameExtention) ?
              <img
                style={{ maxWidth: "45px", maxHeight: "45px" }}
                src={`https://tlhintranet.com/nodeapi/${rowData.path}`}
                title=""
                alt=""
              />
              : FileNameExtention}
          </span>
          {rowData.title}
        </div>

      </td>
      <td>{moment(rowData.created_on).format("DD/MM/YYYY")}</td>
      {showCells && showCells?._id && (
        <td style={{ width: "250px" }}>
          {profile_details?.user_type === 'admin' &&
            <Button
              onClick={() => DeleteCity()}
              buttonStyleOutline
              buttonStyleType="danger"
            >
              <span className="material-symbols-outlined">
                delete
              </span>
            </Button>
          }

          <Button onClick={() => download(`https://tlhintranet.com/nodeapi/${rowData.path}`)} buttonStyleOutline buttonStyleType="primary">
            <span className="material-symbols-outlined">
              download_for_offline
            </span>
          </Button>

          {(filesExtention.includes(FileNameExtention) || FileNameExtention === 'pdf') &&
            <Button onClick={() => showSchedule()} buttonStyleOutline buttonStyleType="primary">
              <span className="material-symbols-outlined">
                visibility
              </span>
            </Button>
          }
        </td>
      )}
    </tr>
  );
};

export default TableRow;

import react, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { Button, Alert } from "../../Library/Module";
import { DeleteUser } from "../../servies/services";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { getUserRole, replaceWithDash } from "../../Library/Utility/Utility";


interface AlertProps {
  rowData?: any;
  index?: any;
  showCells?: any;
  reloadData?: any;
}

const TableRow = ({
  rowData,
  index,
  showCells,
  reloadData,
}: AlertProps): JSX.Element => {
  const navigate = useNavigate();

  const editPage = () => {
    navigate(`/user-management/edit-user/edit/${rowData._id}`);
  };

  const viewPage = () => {
    navigate(`/user-management/edit-user/view/${rowData._id}`);
  };

  const DeleteCity = () => {
    confirmAlert({
      title: `Are you sure you want to delete ${rowData.first_name} ${rowData.last_name}?`,
      message: `This Item will be deleted immediately. You can't undo this action.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteCityAPI(),
        },
        {
          label: "No",
          onClick: () => "",
        },
      ],
    });
  };

  const DeleteCityAPI = async () => {
    const APIResponse = await DeleteUser(rowData._id);
    if (APIResponse?.data?.isSuccess === true) {
      toast.success(APIResponse?.data?.messages);
      reloadData(true);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const AddLeave = () => {
    navigate(`/leaves-management/add-leaves/add?email_id=${rowData.email_id}&first_name=${rowData.first_name}&last_name=${rowData.last_name}`);
  }

  return (
    <tr key={index}>
      <td className="changeCase">{rowData.first_name} {rowData.last_name}</td>
      <td>{rowData.email_id} </td>
      <td>{rowData.mobile} </td>
      <td className="changeCase"> {getUserRole(rowData.user_type)} </td>
      <td className="changeCase"> {rowData.designation} </td>
      <td>{moment(rowData.created_on).format("DD/MM/YYYY")}</td>
      <td style={{ width: "250px" }}>
        <Button
          onClick={() => DeleteCity()}
          buttonStyleOutline
          buttonStyleType="danger"
        >
          <span className="material-symbols-outlined">
            delete
          </span>
        </Button>
        <Button
          onClick={() => editPage()}
          buttonStyleOutline
          buttonStyleType="primary"
        >
          <span className="material-symbols-outlined">
            edit
          </span>
        </Button>
        <Button
          onClick={() => viewPage()}
          buttonStyleOutline
          buttonStyleType="primary"
        >
          <span className="material-symbols-outlined">
            visibility
          </span>
        </Button>
        <Button
          onClick={() => AddLeave()}
          buttonStyleOutline
          buttonStyleType="primary"
        >
          <span className="material-symbols-outlined">
            calendar_month
          </span>
        </Button>
      </td>

    </tr>
  );
};

export default TableRow;

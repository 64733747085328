import React from "react";

interface CardProps {
    title?: string | null;
    color?: string;
}

const Tag = ({ title, color }: CardProps): JSX.Element => {
    return (
        <div style={{ background: color }} className="tags">
            {title}
        </div>

    );
};

export default Tag;

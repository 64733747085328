/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import axios from "axios";
import { Alert, Card, Input, Button } from "../../Library/Module";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";
import { IsLOPAssignedManagerByUserEmail, UserById, authenticate } from "../../servies/services";
import Checkboxes from "../../Library/Checkbox/Checkboxes";
import CustomTooltip from "../../Library/Tooltip/Tippy";

type formDataProps = {
  email_id: string;
  password: string;
  remeber_me: string;
};

const Login = () => {
  const navigate = useNavigate();
  const [isError, setIsError]: any = useState(false);
  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [isValid, setIsValid]: any = useState(true);
  const [formData, setFormData] = useState<formDataProps>();

  const onChangeSingleCallback = (data: any) => {
    setFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
    setIsFormSubmitted(false);
  };

  const loginDetails = localStorage.getItem("loginDetails")
  useEffect(() => {
    console.log("loginDetails", loginDetails)
    if (loginDetails) {
      setFormData(JSON.parse(loginDetails))
    }
  }, [loginDetails])

  const Submit = async (event: any) => {
    if (formData?.remeber_me === 'true') {
      const details = {
        "email_id": formData?.email_id,
        "password": formData?.password,
      }
      localStorage.setItem("loginDetails", JSON.stringify(details))
    }

    setIsFormSubmitted(true);
    if (
      formData?.email_id !== undefined &&
      formData?.email_id.length !== 0 &&
      formData?.password !== undefined &&
      formData?.password.length !== 0
    ) {
      setIsValid(true);
      const postData = {
        email_id: formData?.email_id,
        password: formData?.password
      };
      const response = await authenticate(postData)
      if (response?.status === 200) {
        console.log("response", response);
        localStorage.setItem("user_details", JSON.stringify(response.data));
        toast.success(response.data.messages);
        getProfileDetails(response?.data);
      } else {
        toast.error(response.data.messages);
      }
    } else {
      setIsValid(false);
      toast.error("Please enter required fields");
    }
  };

  const getProfileDetails = async (data: any) => {
    const APIResponse = await UserById(data?.id);
    console.log("getProfileDetails", APIResponse?.data?.data);
    if (APIResponse !== null) {
      localStorage.setItem(
        "profile_details",
        JSON.stringify(APIResponse?.data?.data)
      );
      // toast.success(APIResponse.data.messages);
      isLOPProfileDetails(data)
      navigate("/dashboard");
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const isLOPProfileDetails = async (data: any) => {
    const APIResponse = await IsLOPAssignedManagerByUserEmail(data?.email_id);
    console.log("getProfileDetails", APIResponse?.data?.data);
    if (APIResponse !== null) {
      localStorage.setItem(
        "is_lopAssigned",
        JSON.stringify(APIResponse?.data?.data)
      );
      // toast.success(APIResponse.data.messages);
      navigate("/dashboard");
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const ForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <div className="loginWrapper">
      <div className="loginBoxBg">
        <div className="login_admin">
          <Card>
            <div className="logoPlaceholder">
              <img src={Logo} alt="" title="" />
            </div>
            <h3>Welcome Back !</h3>
            <p className="text-center mb-4">
              Sign in to continue to Tatva Legal Intranet
            </p>
            <div className="container-fluid">
              {!isValid && (
                <Alert
                  AlertStyleType="danger"
                  AlertText={`Please enter required fields`}
                ></Alert>
              )}
              <div className="row">
                <Input
                  inputName="email_id"
                  inputType="text"
                  labelName={"Email ID"}
                  placeholder="Enter email id"
                  required={true}
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                  value={formData?.email_id}
                />
                <Input
                  inputName="password"
                  inputType="password"
                  labelName={"Password"}
                  placeholder="Enter Password"
                  required={true}
                  onChangeSingleCallback={onChangeSingleCallback}
                  col="12"
                  isFormSubmitted={isFormSubmitted}
                  value={formData?.password}
                />
                <div className="float-left width-auto">
                  <CustomTooltip
                    title={"Remember Me"}
                    position={"bottom"}
                    disabled={false}
                    content={`Don't turn on this on public computer`}>
                    <Checkboxes
                      inputName={"remeber_me"}
                      onChangeSingleCallback={onChangeSingleCallback}
                      options={[{ text: "Remember Me", id: 1, value: "true" }]}
                      selectedItem={{ text: "Remember Me", id: 1, value: "true" }}
                    >
                    </Checkboxes>
                  </CustomTooltip>
                </div>
              </div>
              <div className="row align-content-end justify-content-end">
                <div className="col-sm-6 text-left">
                  <NavLink to="/forgot-password">Forgot Password</NavLink>
                </div>
                <div className="col-sm-6 text-right">
                  <Button
                    onClick={() => Submit("")}
                    buttonText={"Login"}
                    buttonStyleType={"primary"}
                  >
                    <span className="material-symbols-outlined">
                      login
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default Login;

import react, { useState, useMemo, useEffect } from "react";
import moment from "moment";

interface AlertProps {
    rowData?: any;
    index?: any;
    showCells?: any;
    reloadData?: any;
}

const TableRow = ({
    rowData,
    index,
    showCells,
    reloadData,
}: AlertProps): JSX.Element => {

    return (
        <tr key={index}>
            <td className="changeCase">{rowData.first_name} {rowData.last_name}
                <p className="small blue role_row changeCase">{rowData.user_type}</p>
            </td>
            <td>{rowData.email_id} </td>
            <td>{moment(rowData.created_on).format('DD/MM/YYYY')} </td>
        </tr>
    );
};

export default TableRow;

/* eslint-disable no-empty-pattern */
import { useEffect, useMemo, useState } from "react";
import { Alert, Card, Input, Button, Select, DatePickerComp } from "../../Library/Module";

import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import {
  BookAppointment,
  GetConfRoom,
  GetEvents,
  GetMasterBookAppointment,
  GetSingleBookAppointment,
  UpdateBookAppointment,
  UpdateCityDetaildByIdService,
} from "../../servies/services";
import { ArrayTimeOption, GetDay, GetTimeFrame, objectToQueryString, timeFrame, TimeFrameWithOption, WeekOfTheDay } from "../../Library/Utility/Utility";
import moment from "moment";
type formDataProps = {
  _id?: string;
  internal_id?: string;
  parent_id?: string;
  meeting_type?: string;
  meeting_title?: string;
  meeting_room_name?: string;
  meeting_room_slug?: string;
  start_date?: string;
  end_date?: string;
  start_time?: string;
  end_time?: string;
  user_email_id?: string;
  user_name?: string;
  created_by?: string;
  status?: string;

};

const MeetingType = [
  {
    "text": "One Day Meeting",
    "id": 1,
    "value": "One Day Meeting"
  },
  {
    "text": "Recurring Meeting",
    "id": 1,
    "value": "Recurring Meeting"
  }
]

const AddConferenceRoom = () => {
  const navigate = useNavigate();
  let { id, type } = useParams();
  const [isError, setIsError]: any = useState(false);
  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [isLoading, setIsLoading]: any = useState(false);
  const [isValid, setIsValid]: any = useState(true);
  const [formData, setFormData] = useState<formDataProps>();
  const [submitType] = useState(type);
  const [serverError, setServerError]: any = useState(
    "Something went wrong, please try again"
  );
  const [tableData, setTableData] = useState<any>()
  const [dateList, setDateList] = useState([])
  const [holidayList, setHolidayList] = useState<any>([])
  // @ts-ignore
  const profile_details = JSON.parse(localStorage.getItem("profile_details"));
  const [repeatDay, setRepeatDay] = useState<any>([])
  const onChangeSingleCallback = (data: any) => {
    setFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
    setIsFormSubmitted(false);
  };

  const commonParams = useMemo(() => {

    let min = 100000;
    let max = 1000000000;
    let random = Math.floor(Math.random() * (+max + 1 - +min)) + +min;

    return {
      parent_id: formData?.parent_id || random.toString(),
      meeting_type: formData?.meeting_type,
      meeting_title: formData?.meeting_title,
      meeting_room_name: formData?.meeting_room_name,
      meeting_room_slug: formData?.meeting_room_slug || 'meeting_room_slug',
      start_date: formData?.start_date,
      end_date: formData?.meeting_type === 'One Day Meeting' ? formData?.start_date : formData?.end_date,
      start_time: formData?.start_time,
      end_time: formData?.end_time,
      user_email_id: profile_details?.email_id,
      user_name: profile_details?.first_name + " " + profile_details?.last_name,
      created_by: profile_details?.email_id,
      status: "1",
    }
  }, [formData, profile_details?.email_id, profile_details?.first_name, profile_details?.last_name])

  useEffect(() => {
    if (formData?.start_date) {
      const sDate = moment(formData?.start_date).format('MM/DD/YYYY')
      const eDate = formData?.meeting_type === 'One Day Meeting' ? moment(formData?.start_date).format('MM/DD/YYYY') : moment(formData?.end_date).format('MM/DD/YYYY')
      const startDate = moment(sDate).format('YYYY-MM-DD');
      const endDate = moment(eDate).format('YYYY-MM-DD');
      const mstartDate = moment(startDate);
      const mendDate = moment(endDate);
      const getDaysBetweenDates2 = (mstartDate: any, mendDate: any) => {
        var now = mstartDate.clone(),
          dates: any = [];
        while (now.isSameOrBefore(mendDate)) {
          console.log("getDaysBetweenDates2 now DAY", moment(now).day())
          console.log("getDaysBetweenDates2 holidayList", holidayList)
          if (holidayList.includes(moment(now).format('DD/MM/YYYY').toString())) {
            console.log("Holiday", moment(now).format('DD/MM/YYYY'))
          } else {
            const day = moment(now).format('d')
            console.log("getDaysBetweenDates2 day asdasd", day)
            if (formData?.meeting_type === 'One Day Meeting') {
              console.log("day now", now.format('DD/MM/YYYY'))
              dates.push({
                "date": now.format('DD/MM/YYYY'),
                ...commonParams
              })
            } else {
              console.log("repeatDay", repeatDay)
              console.log("day now", now.format('DD/MM/YYYY'))
              repeatDay.map((item: any) => {
                console.log("item?.id === day", item?.id, day, dates)
                return item?.id === Number(day) && dates.push({
                  "date": now.format('DD/MM/YYYY'),
                  ...commonParams
                })
              })
            }
          }
          now.add(1, 'days')
        }
        return dates;
      };
      const dateList2: any = getDaysBetweenDates2(mstartDate, mendDate);
      console.log("dateList outise", dateList2)
      setDateList(dateList2)
    }
  }, [formData?.start_date, commonParams, holidayList, formData?.end_date, formData?.meeting_type, repeatDay])

  useEffect(() => {
    const getDataByid = async () => {
      const query = {
        type: "Holiday"
      }
      const APIResponse = await GetEvents(objectToQueryString(query));
      if (APIResponse?.data?.isSuccess === true) {
        setHolidayList(APIResponse?.data?.data);
      } else {
        toast.error("Something went wrong, please try again");
        setIsError(true);
      }
    };
    getDataByid();
  }, []);


  const Submit = async (event: any) => {
    setIsFormSubmitted(true);
    if (
      formData?.meeting_title !== undefined &&
      formData?.meeting_title.length !== 0
    ) {
      setIsValid(true);
      let postData = {
        id: formData?._id,
        internal_id: new Date(),
        ...commonParams,
        meeting_dates: dateList
      };
      if (submitType === "add") {
        delete postData.id;
        const APIResponse = await BookAppointment(postData);
        if (APIResponse?.data?.isBooked) {
          toast.info('Room not available for selected time frame');
        } else {
          if (APIResponse?.data?.isSuccess === true) {
            toast.success(APIResponse?.data?.messages);
            redirectToParent();
          } else {
            if (APIResponse?.data?.validataion_error) {
              toast.error(APIResponse?.data?.messages);
              setServerError(APIResponse?.data?.messages);
              setIsError(true);
            } else {
              if (APIResponse?.data?.isSuccess === false) {
                toast.error(APIResponse?.data?.messages);
                setIsError(true);
              }

            }
          }
        }
      } else {
        postData.id = formData?._id;
        const APIResponse = await UpdateBookAppointment(postData);
        if (APIResponse?.data?.isSuccess === true) {
          toast.success(APIResponse?.data?.messages);
          redirectToParent();
        } else {
          if (APIResponse?.data?.validataion_error) {
            toast.error(APIResponse?.data?.messages);
            setServerError(APIResponse?.data?.messages);
            setIsError(true);
          } else {
            if (APIResponse?.data?.isSuccess === false) {
              toast.error(APIResponse?.data?.messages);
              setIsError(true);
            } else {
              toast.error("Something went wrong, please try again");
              setIsError(true);
            }
          }
        }
      }
    } else {
      setIsValid(false);
      toast.error("Please enter required fields");
    }
  };

  const redirectToParent = () => {
    navigate("/conference-rooms-management/view-conference-rooms/my-bookings");
  };

  const breadcrumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "City Management",
      link: "/dashboard",
    },
    {
      title: "Add City",
      link: "/dashboard",
    },
  ];

  useEffect(() => {
    setIsLoading(false);
    setTableData([]);
    const getData = async () => {
      const apidata = await GetConfRoom();
      const meetingRooms: any = []
      apidata?.data?.data?.map((item: any, index: number) => {
        return meetingRooms.push({ text: item?.title, id: index, value: item?.slug },)
      })
      setTableData(meetingRooms);
      setIsLoading(true);
    };
    getData();
  }, []);

  useEffect(() => {
    setIsLoading(false);
    if (type === "edit" || type === "view") {
      const getData = async () => {
        const query = {
          parent_id: id
        }
        const APIResponse = await GetMasterBookAppointment(objectToQueryString(query));
        if (APIResponse?.data?.isSuccess === true) {
          setFormData(APIResponse?.data?.data?.[0]);
          setIsLoading(true);
        } else {
          toast.error("Something went wrong, please try again");
          setIsError(true);
          setIsLoading(true);
        }
      };
      getData();
    } else {
      setFormData({
        _id: "",
        internal_id: "",
        parent_id: "",
        meeting_type: "",
        meeting_title: "",
        meeting_room_name: "",
        meeting_room_slug: "",
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
        user_email_id: "",
        user_name: "",
        created_by: "",
        status: "",
      });
      setIsLoading(true);
    }
  }, [id, type]);

  const [fromTime, setFromTime] = useState<any>([])

  const [hasStartTime, setHasStartTime] = useState(false)

  useEffect(() => {
    setFromTime([])
    if (formData?.start_time) {
      setFromTime([])
      console.log("formData?.start_time", formData?.start_time)
      setHasStartTime(true)
      const timeOption = TimeFrameWithOption(formData?.start_time)
      console.log("TimeFrameWithOption", timeOption)
      setFromTime(timeOption)
    }
  }, [formData?.start_time])

  const RepeatOnDays = (day: any) => {
    setRepeatDay((repeatDay: any) => [...repeatDay, day])
  }

  const DeleteRepeatOnDays = (day: any) => {
    const updatedArray = repeatDay?.filter((item: any) => {
      return item?.id !== day?.id
    })
    setRepeatDay(updatedArray)
  }

  const [singleData, setSingleData] = useState([])

  useEffect(() => {

    const getData2 = async () => {
      const query = {
        sortBy: "meeting_room_name",
        meeting_room_name: formData?.meeting_room_name,
        date: moment(formData?.start_date).format('DD/MM/YYYY').toString()
      }
      const apidata = await GetSingleBookAppointment(objectToQueryString(query));
      const APIResponse = apidata?.data?.data;
      setSingleData(APIResponse);
    }


    if (formData?.meeting_room_name && formData?.start_date) {
      getData2();
    };

  }, [formData?.meeting_room_name, formData?.start_date])


  useEffect(() => {

    const element = {
      start_time: "915",
      end_time: "1200"
    }

    const data = [{
      "_id": "6458d6e23a80cb3ad4fec01f",
      "parent_id": "543316389",
      "meeting_type": "One Day Meeting",
      "meeting_title": "Interview - One time",
      "meeting_room_name": "test",
      "meeting_room_slug": "meeting_room_slug",
      "start_date": "2023-05-07T18:30:00.000Z",
      "end_date": "2023-05-07T18:30:00.000Z",
      "start_time": "1200",
      "end_time": "1300",
      "user_email_id": "btlakal25@gmail.com",
      "user_name": "btlakal 25",
      "created_by": "btlakal25@gmail.com",
      "status": "1",
      "date": "08/05/2023",
      "created_on": "2023-05-08T11:02:58.884Z",
      "updated_on": "2023-05-08T11:02:58.884Z",
      "__v": 0
    }]

    const newdata = data.filter((person) => {
      console.log("newdata end_time", (Number(person.end_time) > Number(formData?.end_time)))
      console.log("newdata end_time", (Number(person.start_time) < Number(formData?.end_time)))

      console.log("newdata start_time", (Number(formData?.start_time) > Number(person.start_time)))
      console.log("newdata start_time", (Number(person.start_time) < Number(formData?.end_time)))
      const abc = ((Number(formData?.start_time) > Number(person.start_time) && Number(person.start_time) < Number(formData?.end_time))
        && (Number(person.end_time) > Number(formData?.end_time) && Number(person.start_time) < Number(formData?.end_time))
      )
      return abc
    })
    console.log("newdata", newdata)

    // const newdata2 = data.filter((person) => {
    //   return (Number(element.end_time) < Number(person.end_time) && Number(element.end_time) < Number(person.end_time)) || Number(element.end_time) === Number(person.end_time)
    // })
    // console.log("newdata 2", newdata2)
  }, [formData?.end_time, formData?.start_time]
  )
  return (
    <Wrapper pageTitle="Dashboard" breadcrumbList={breadcrumbList}>
      <Card title="Book Conference Rooms">
        <p className="text-left mb-4">
          You can manage the Conference Rooms data in this module.
        </p>
        <div className="">
          {!isValid && (
            <Alert
              AlertStyleType="danger"
              AlertText={`Please enter required fields`}
            ></Alert>
          )}
          {isLoading && (
            <div className="row">
              <div className="col-md-8">
                <div className="row">
                  <Input
                    inputName="meeting_title"
                    inputType="text"
                    labelName={"Meeting Title"}
                    placeholder="Enter meeting title"
                    required={true}
                    onChangeSingleCallback={onChangeSingleCallback}
                    col="6"
                    isFormSubmitted={isFormSubmitted}
                    disabled={submitType === "view"}
                    value={
                      type === "add" && formData?.meeting_title?.length === 0
                        ? ""
                        : formData?.meeting_title
                    }
                  />

                  <Select
                    inputName="meeting_room_name"
                    labelName="Meeting Room Name"
                    options={tableData}
                    onChangeSingleCallback={onChangeSingleCallback}
                    selectedItem={tableData.find(
                      (item: any) => item.value === formData?.meeting_room_name
                    )}
                    required={true}
                    placeholder="Select meeting room name"
                    search_option={false}
                    disabled={submitType === "view"}
                    isLoading={true}
                    col="6"
                  ></Select>

                  <Select
                    inputName="meeting_type"
                    labelName="Meeting Type"
                    options={MeetingType}
                    onChangeSingleCallback={onChangeSingleCallback}
                    selectedItem={MeetingType.find(
                      (item: any) => item.value === formData?.meeting_type
                    )}
                    required={true}
                    placeholder="Select meeting type"
                    search_option={false}
                    disabled={submitType === "view"}
                    isLoading={true}
                    col="6"
                  ></Select>


                  <DatePickerComp
                    inputName="start_date"
                    labelName={formData?.meeting_type === 'Recurring Meeting' ? "Start Date" : 'Date'}
                    placeholder="Booking Date"
                    required={true}
                    minDate={formData?.start_date ? new Date(formData?.start_date) : new Date()}
                    onChangeSingleCallback={onChangeSingleCallback}
                    value={formData?.start_date}
                    disabledWeekend={false}
                    col="6"></DatePickerComp>

                  {formData?.meeting_type === 'Recurring Meeting' &&
                    <>
                      <DatePickerComp
                        inputName="end_date"
                        labelName={"End date"}
                        placeholder="Select booking end date"
                        required={true}
                        minDate={formData?.end_date ? new Date(formData?.end_date) : new Date()}
                        onChangeSingleCallback={onChangeSingleCallback}
                        disabled={formData?.end_date && formData?.end_date?.length === 0 ? true : false}
                        value={formData?.end_date}
                        disabledWeekend={false}
                        col="12"></DatePickerComp>

                      <p><label>Repeat on </label></p>

                      <ul className="DayList">
                        {WeekOfTheDay?.map((item) => {
                          const SelectedDay = repeatDay?.find((item2: any) => {
                            return item2?.id === item?.id
                          })
                          return <li
                            onClick={() =>
                              SelectedDay?.id === item?.id ?
                                DeleteRepeatOnDays(item) : RepeatOnDays(item)}
                            className={`${SelectedDay?.id === item?.id ? 'Selected' : 'Deselected'}`}
                          >{item?.day.slice(0, 1)}

                          </li>
                        })}
                      </ul>
                    </>
                  }

                  <Select
                    inputName="start_time"
                    labelName="Booking Start Time"
                    options={ArrayTimeOption}
                    onChangeSingleCallback={onChangeSingleCallback}
                    selectedItem={ArrayTimeOption.find(
                      (item) => item.value === formData?.start_time
                    )}
                    required={true}
                    placeholder="Select booking start time"
                    search_option={false}
                    disabled={submitType === "view"}
                    isLoading={true}
                    col="6"
                  ></Select>

                  <Select
                    inputName="end_time"
                    labelName="Booking End Time"
                    options={fromTime}
                    onChangeSingleCallback={onChangeSingleCallback}
                    selectedItem={fromTime.find(
                      (item: any) => item.value === formData?.end_time
                    )}
                    required={true}
                    placeholder="Select booking end time"
                    search_option={false}
                    disabled={!hasStartTime}
                    isLoading={true}
                    col="6"
                  ></Select>

                  <div className="row align-content-end justify-content-end">
                    <div className="col-sm-2 text-right">
                      <Button
                        onClick={() => redirectToParent()}
                        buttonText={"Cancel"}
                        buttonStyleType={"primary"}
                      />
                    </div>
                    <div className="col-sm-2 text-right">
                      <Button
                        onClick={() => Submit("")}
                        disabled={submitType === "view" || dateList?.length === 0}
                        buttonText={
                          submitType === "add" || submitType === "view"
                            ? "Submit"
                            : "Update"
                        }
                        buttonStyleType={"primary"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">

                {singleData && singleData?.length > 0 && formData?.meeting_type === 'One Day Meeting' &&
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th> Meeting </th>
                        <th> Date & Time </th>
                      </tr>
                    </thead>
                    <tbody>
                      {singleData?.map((item: any) => {
                        return (<tr>
                          <td> {item?.meeting_title}  <br></br> <span className="small blue role_row changeCase">{item?.meeting_room_name} </span> </td>
                          <td>  {GetTimeFrame(item?.start_time)} - {GetTimeFrame(item?.end_time)} <br></br>
                            <span className="small blue role_row changeCase"> {item?.date}</span> </td>
                        </tr>)
                      })}
                    </tbody>
                  </table>}


                {formData?.meeting_type === 'Recurring Meeting' && dateList.length > 0 &&
                  <>
                    <div className="scrollBar">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Day</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dateList?.map((day: any) => {
                            const date = moment(day?.date, 'DD-MM-YYYY')
                            console.log("asdasd", GetDay(moment(date).day()))
                            const days = GetDay(moment(date).day())
                            return <tr>
                              <td>
                                {day?.date}
                              </td>
                              <td>
                                {days}
                              </td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                }
              </div>
            </div>
          )}
        </div>
      </Card>
    </Wrapper>
  );
};
export default AddConferenceRoom;

/* eslint-disable no-empty-pattern */
import { useState, useEffect } from "react";
import axios from "axios";
import { Alert, Card, CustomTable } from "../../Library/Module";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import { GetComment, GetEmails, GetFakeAPI } from "../../servies/services";
import CommentsRow from "./CommentsRow";
import moment from "moment";
import { objectToQueryString } from "../../Library/Utility/Utility";

const tableConfig = {
    search: {
        name: "id",
        isAsc: false,
    },
    sortDefault: {
        name: "id",
        isAsc: false,
    },
    tableHeader: [
        {
            name: "email_id",
            display: "email_id",
            sort: true,
        },
        {
            name: "subject",
            display: "subject",
            sort: false,
        },
        {
            name: "type",
            display: "Type",
            sort: false,
        },
        {
            name: "scount",
            display: "Success Count",
            sort: false,
        }, {
            name: "fcount",
            display: "Failed Count",
            sort: false,
        },
        {
            name: "status",
            display: "status",
            sort: true,
        },
        {
            name: "message",
            display: "message",
            sort: false,
        },
        {
            name: "created_on",
            display: "Created On",
            sort: true,
        },
        {
            name: "Action",
            display: "Action",
            sort: false,
        },
    ],
};

const ViewEmail = () => {
    const navigate = useNavigate();
    let { Type } = useParams();
    const [isError, setIsError]: any = useState(false);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [isValid, setIsValid]: any = useState(true);
    const [tableData, setTableData] = useState<any>([]);
    const [showCells, setShowCells] = useState(null);
    const [sortState, setSortState] = useState(tableConfig.sortDefault);
    const [isLoading, setIsLoading] = useState(false);
    const [reloadAPI, setReloadAPI] = useState(false);

    const showCellCallback = (data: any) => {
        setShowCells(data);
    };

    useEffect(() => {
        setIsLoading(false);
        const getData = async () => {
            const apidata = await GetEmails();
            const APIResponse = apidata?.data?.data;
            setTableData(APIResponse);
            setIsLoading(true);
            setReloadAPI(false);
        };
        getData();
    }, [Type, reloadAPI]);

    const breadcrumbList = [
        {
            title: "Dashboard",
            link: "/dashboard",
        },
        {
            title: "User Management",
            link: "/dashboard",
        },
        {
            title: "View User",
            link: "/dashboard",
        },
    ];
    const reloadData = (data: any) => {
        console.log("reloadData", data);
        setReloadAPI(true);
    };

    return (
        <Wrapper pageTitle="Dashboard" breadcrumbList={breadcrumbList}>
            <Card>
                <div className="">
                    {!isValid && (
                        <Alert
                            AlertStyleType="danger"
                            AlertText={`Please enter required fields`}
                        ></Alert>
                    )}
                    <div className="row">
                        <div className="col-md-12">
                            <CustomTable
                                header={tableConfig.tableHeader}
                                sortDefault={tableConfig.sortDefault}
                                data={tableData}
                                tableTitle={"Email Management"}
                                tableSubTitle='You can manage the Email data in this module.'
                                dropdown={""}
                                search_bar={true}
                                showCellCallback={showCellCallback}
                                sortState={sortState}
                                isLoading={isLoading}
                            >
                                {({ rowData, index }: { rowData: any; index: any }) => (
                                    <CommentsRow
                                        rowData={rowData}
                                        index={index}
                                        showCells={showCells}
                                        reloadData={reloadData}
                                    ></CommentsRow>
                                )}
                            </CustomTable>
                        </div>
                    </div>
                </div>
            </Card>
        </Wrapper>
    );
};
export default ViewEmail;

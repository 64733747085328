/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import { Alert, Card, Input, Button, Select } from "../../Library/Module";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import {
    CreateCMS, CMSById, UpdateCMS, GetCustomPostType, CategoryByCMSType, FieldsByCMSType, CategoryBySubCategory,
} from "../../servies/services";

import { CKEditor } from 'ckeditor4-react';
import MediaPopup from '../Media/MediaPopup';
import Editor from '../../Library/Editor/Editor';
import TextFieldComp from './TextFieldComp';
import moment from 'moment';
import Tags from './tags';
import CKEditorComp from "../../Library/CKEditorComp/CKEditorComp";

type formDataProps = {
    title: string;
    status: string;
    image?: string;
    description?: string;
    short_description?: string;
    _id?: string;
    document_title?: string;
    meta_tag?: string;
    meta_description?: string;
    type?: string;
    field_type?: string;

    publish_type?: string;
    publish_date?: string;
    thumbnail?: string;
    resource_file?: string;
    additional_fields?: string;
    category?: string;
    order_id?: string;
    tag?: string;
    sub_category?: string;
    created_by?: string;

};

const statusOption = [
    { text: "Active", id: 1, value: "1" },
    { text: "Inactive", id: 2, value: "2" },
];

const fieldTypeOption = [
    { text: "Input", id: 1, value: "Input" },
    { text: "Textarea", id: 2, value: "Textarea" },
    { text: "CKEditor", id: 2, value: "CKEditor" },
    { text: "Date Picker", id: 2, value: "DatePicker" },
];

const publishOption = [
    { text: "Draft", id: 1, value: "Draft" },
    { text: "Publish", id: 2, value: "Publish" }
];

const CreateCMSPage = () => {
    const navigate = useNavigate();
    let { id, type, action } = useParams();
    console.log("id", id, type, action)
    // @ts-ignore
    const profile_details = JSON.parse(localStorage.getItem("profile_details")) || null;
    const [isError, setIsError]: any = useState(false);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [isLoading, setIsLoading]: any = useState(false);

    const [isValid, setIsValid]: any = useState(true);
    const [formData, setFormData] = useState<formDataProps>();
    const [submitType, setSubmitType] = useState(type);
    const [customPostType, setCustomPostType] = useState([{ text: "None", id: 0, value: "none" }]);
    const [customPostCategory, setCustomPostCategory] = useState([{ text: "None", id: 0, value: "none" }]);
    const [customPostSubCategory, setCustomPostSubCategory] = useState([{ text: "None", id: 0, value: "none" }]);



    const [serverError, setServerError]: any = useState(
        "Something went wrong, please try again"
    );

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    useEffect(() => {
        const getData = async () => {
            const APIResponse = await GetCustomPostType();
            if (APIResponse?.data?.isSuccess === true) {
                let updatedList: any = []
                APIResponse?.data?.data?.map((item: any) => {
                    return updatedList.push({ text: item?.title, id: item?._id, value: item?.slug })
                })
                setCustomPostType(updatedList);
            } else {
                toast.error("Something went wrong, please try again");
                setIsError(true);
            }
        };
        getData();
    }, [])

    useEffect(() => {
        const getData = async () => {
            const APIResponse = await CategoryByCMSType('blog');
            if (APIResponse?.data?.isSuccess === true) {
                let updatedList: any = [
                    { text: "None", id: 0, value: "none" }
                ]
                APIResponse?.data?.data?.map((item: any) => {
                    return updatedList.push({ text: item?.title, id: item?._id, value: item?.slug })
                })
                setCustomPostCategory(updatedList);
            } else {
                toast.error("Something went wrong, please try again");
                setIsError(true);
            }
        };
        getData();
    }, [formData?.type])

    useEffect(() => {
        if (formData?.category) {
            const getData = async () => {
                const APIResponse = await CategoryBySubCategory(formData?.category);
                if (APIResponse?.data?.isSuccess === true) {
                    let updatedList: any = []
                    APIResponse?.data?.data?.map((item: any) => {
                        return updatedList.push({ text: item?.title, id: item?._id, value: item?.slug })
                    })
                    setCustomPostSubCategory(updatedList);
                } else {
                    toast.error("Something went wrong, please try again");
                    setIsError(true);
                }
            };
            getData();
        }
    }, [formData?.category])

    useEffect(() => {
        setIsLoading(false);

        if (type === "edit" || type === "view") {
            // setSubmitType("edit");
            const getData = async () => {
                const APIResponse = await CMSById(id);
                if (APIResponse?.data?.isSuccess === true) {
                    setFormData(APIResponse?.data?.data);
                    setIsLoading(true);
                } else {
                    toast.error("Something went wrong, please try again");
                    setIsError(true);
                    setIsLoading(true);
                }
            };
            getData();
        } else {
            // setSubmitType("edit");
            setFormData({
                title: "",
                status: "",
                image: "",
                description: "",
                short_description: "",
                _id: "",
            });
            setIsLoading(true);
        }
    }, [type]);

    const Submit = async (event: any) => {
        setIsFormSubmitted(true);
        console.log("formData", JSON.stringify(formData))
        if (
            formData?.title !== undefined &&
            formData?.title.length !== 0
        ) {
            setIsValid(true);
            let postData = {
                "created_by": profile_details?.email_id,
                "created_by_name": profile_details?.first_name + ' ' + profile_details?.last_name,
                "title": formData?.title,
                "short_description": formData?.short_description,
                "type": formData?.type || 'blog',
                "meta_tag": formData?.meta_tag,
                "meta_description": formData?.meta_description,
                "document_title": formData?.document_title,
                "publish_type": "Publish",
                "status": "1",
                "publish_date": new Date(),
                "thumbnail": formData?.thumbnail,
                "image": formData?.image,
                "resource_file": formData?.resource_file,
                "description": formData?.description,
                "additional_fields": JSON.stringify(formData?.additional_fields),
                "category": formData?.category || 'blog',
                "order_id": formData?.order_id,
                "tag": formData?.tag,
                "sub_category": formData?.sub_category,
                "comments": 0,
                "visits": 0,
                "likes": 0,
                "id": formData?._id,
            };
            if (submitType === "add") {
                delete postData.id;
                const APIResponse = await CreateCMS(postData);
                if (APIResponse?.data?.isSuccess === true) {
                    toast.success(APIResponse?.data?.messages);
                    redirectToParent();
                } else {
                    if (APIResponse?.data?.validataion_error) {
                        // toast.error(APIResponse?.data?.messages);
                        // setServerError(APIResponse?.data?.messages);
                        // setIsError(true);
                    } else {
                        // toast.error("Something went wrong, please try again");
                        // setIsError(true);
                    }
                }
            } else {
                postData.id = formData?._id;
                console.log("postData", postData);
                const APIResponse = await UpdateCMS(postData);
                if (APIResponse?.data?.isSuccess === true) {
                    toast.success(APIResponse?.data?.messages);
                    redirectToParent();
                } else {
                    if (APIResponse?.data?.validataion_error) {
                        // toast.error(APIResponse?.data?.messages);
                        // setServerError(APIResponse?.data?.messages);
                        // setIsError(true);
                    } else {
                        // toast.error("Something went wrong, please try again");
                        // setIsError(true);
                    }
                }
            }
        } else {
            setIsValid(false);
            toast.error("Please enter required fields");
        }
    };

    const redirectToParent = () => {
        navigate("/posts-management/my-posts/my-posts");
    };

    const breadcrumbList = [
        {
            title: "Dashboard",
            link: "/dashboard",
        },
        {
            title: "City Management",
            link: "/pets-management/view-pets",
        },
        {
            title: "Add City",
            link: "/pets-management/view-pets",
        },
    ];

    return (
        <Wrapper pageTitle="Dashboard" breadcrumbList={breadcrumbList}>
            <Card title="Discussion Forum">
                <p className="text-left mb-4">
                    You can manage the Blog data in this module.
                </p>
                <div className="">
                    {isError && (
                        <Alert AlertStyleType="danger" AlertText={serverError}></Alert>
                    )}
                    {!isValid && (
                        <Alert
                            AlertStyleType="danger"
                            AlertText={`Please enter required fields`}
                        ></Alert>
                    )}

                    {isLoading && (
                        <div className="row">
                            <div className="col-md-8">
                                <Input
                                    inputName="title"
                                    inputType="text"
                                    labelName={"Title"}
                                    placeholder="Enter Title"
                                    required={true}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    isFormSubmitted={isFormSubmitted}
                                    disabled={submitType === "view"}
                                    value={type === "add" && formData?.title.length === 0 ? "" : formData?.title}
                                />

                                <Input
                                    inputName="short_description"
                                    inputType="textarea"
                                    labelName={"Short Description"}
                                    placeholder="Enter Short Description"
                                    required={true}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    isFormSubmitted={isFormSubmitted}
                                    disabled={submitType === "view"}
                                    value={type === "add" && formData?.short_description?.length === 0 ? "" : formData?.short_description}
                                />
                                {/* <Editor
                                    inputName="short_description"
                                    labelName={"Short Description"}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    value={formData?.short_description}
                                    disabled={submitType === "view"}>

                                </Editor> */}
                                {/* <CKEditorComp
                                    inputName="short_description"
                                    labelName={"Short Description"}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    value={formData?.short_description}
                                    disabled={submitType === "view"}
                                /> */}

                                <Editor
                                    inputName="description"
                                    labelName={"Description"}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    value={formData?.short_description}
                                    disabled={submitType === "view"}>

                                </Editor>

                                {/* <CKEditorComp
                                    inputName="description"
                                    labelName={"Description"}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    value={formData?.description}
                                    disabled={submitType === "view"}
                                /> */}

                            </div>
                            <div className="col-md-4">
                                <div className="row align-content-end justify-content-end">


                                    <Input
                                        inputName="created_by"
                                        inputType="text"
                                        labelName={"Created By"}
                                        placeholder="Enter Created By"
                                        required={true}
                                        onChangeSingleCallback={onChangeSingleCallback}
                                        col="12"
                                        isFormSubmitted={isFormSubmitted}
                                        disabled={true}
                                        value={profile_details?.email_id}
                                    />

                                    {/* <Select
                                        inputName="publish_type"
                                        labelName="Publish Type"
                                        options={publishOption?.length > 0 ? publishOption : []}
                                        onChangeSingleCallback={onChangeSingleCallback}
                                        selectedItem={publishOption && publishOption?.find(
                                            (item: any) => item?.value === formData?.publish_type
                                        )}
                                        required={true}
                                        placeholder="Select Publish Type"
                                        search_option={false}
                                        disabled={submitType === "view"}
                                        isLoading={true}
                                    ></Select> */}

                                    <Select
                                        inputName="category"
                                        labelName="Category"
                                        options={customPostCategory?.length > 0 ? customPostCategory : []}
                                        onChangeSingleCallback={onChangeSingleCallback}
                                        selectedItem={customPostCategory && customPostCategory?.find(
                                            (item: any) => item?.value === formData?.category
                                        )}

                                        placeholder="Select category"
                                        search_option={false}
                                        disabled={submitType === "view"}
                                        isLoading={true}
                                    ></Select>

                                    {/* <Select
                                        inputName="type"
                                        labelName="Type"
                                        options={customPostType?.length > 0 ? customPostType : []}
                                        onChangeSingleCallback={onChangeSingleCallback}
                                        selectedItem={customPostType && customPostType?.find(
                                            (item: any) => item?.value === formData?.type
                                        )}
                                        required={true}
                                        placeholder="Select Type"
                                        search_option={false}
                                        disabled={submitType === "view"}
                                    ></Select>

                                    

                                    <Select
                                        inputName="sub_category"
                                        labelName="Sub Category"
                                        options={customPostSubCategory?.length > 0 ? customPostSubCategory : []}
                                        onChangeSingleCallback={onChangeSingleCallback}
                                        selectedItem={customPostSubCategory && customPostSubCategory?.find(
                                            (item: any) => item?.value === formData?.sub_category
                                        )}

                                        placeholder="Select Sub Category"
                                        search_option={false}
                                        disabled={submitType === "view"}
                                    ></Select> */}


                                    {/* <Select
                                        inputName="status"
                                        labelName="Status"
                                        options={statusOption}
                                        onChangeSingleCallback={onChangeSingleCallback}
                                        selectedItem={statusOption.find(
                                            (item) => item.value === formData?.status
                                        )}
                                        required={true}
                                        placeholder="Select Status"
                                        search_option={false}
                                        disabled={submitType === "view"}
                                        isLoading={true}
                                    ></Select> */}
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p><strong>Profile Image/Logo</strong></p>
                                        <MediaPopup
                                            onSelectChange={onChangeSingleCallback}
                                            disabled={submitType === "view"}
                                            title={
                                                formData?.image !== null && formData?.image !== "null"
                                                    ? "Change Image"
                                                    : "Select Image"
                                            }
                                            inputName="image"
                                        />
                                        {formData?.image && formData?.image.length > 0 &&
                                            <div className="imagePlaceholder"><img src={process.env.react_app_base_url + `/${formData?.image}`} alt="" title="" /></div>}
                                    </div>
                                </div>
                                {/* <div className="col-md-12">
                                    <Input
                                        inputName="publish_date"
                                        inputType="text"
                                        labelName={"Publish Date"}
                                        placeholder="Enter Publish Date"
                                        required={true}
                                        onChangeSingleCallback={onChangeSingleCallback}
                                        col="12"
                                        isFormSubmitted={isFormSubmitted}
                                        disabled={submitType === "view"}
                                        value={type === "add" && formData?.publish_date?.length === 0 ? "" : formData?.publish_date}
                                    />
                                </div> */}
                                <div className="row">
                                    <div className="col-sm-6 text-right">
                                        <Button
                                            onClick={() => redirectToParent()}
                                            buttonText={"Cancel"}
                                            buttonStyleType={"primary"}
                                        />
                                    </div>
                                    <div className="col-sm-6 text-right">
                                        <Button
                                            onClick={() => Submit("")}
                                            disabled={submitType === "view"}
                                            buttonText={
                                                submitType === "add" || submitType === "view"
                                                    ? "Submit"
                                                    : "Update"
                                            }
                                            buttonStyleType={"primary"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Card>
        </Wrapper >
    );
};
export default CreateCMSPage;
